import React, { useEffect, useState } from "react";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import GeneralCardLayout from "layouts/GeneralCardLayout";
import { Row, Col, Image, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OrganizationSetup from "./organization";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Flex from "components/common/Flex";
import setupBg from 'assets/img/team/org.jpg';
import NewWorkspaceInOrganization from "./workspace";
import { RoutePaths, WorkSpaceContentApprovalWorkFlow } from "../../../constants";
import WorkSpaceApprovalWorkFlow from "../new-workspace/workspace_approval_setup";
import { useSelector, useDispatch } from 'react-redux';
import OrganizationAndWorkspaceSetupReview from "./review";
import SubmitButton from "components/common/SubmitButton";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { setActiveWorkspace } from "redux/slices/workspaceslice";
import { getLoggedInUser, setLoggedInUser } from "redux/slices/user_slice";
import { useNavigate } from "react-router-dom";
import { cachePosts } from "redux/slices/postslice";
import { UpgradeToPremiumAccountCard } from "../new-workspace";
import useOrganizationStore from "state/organization_store";

export default function OrganizationAndWorkspaceSetup() {

    const { t } = useTranslation();
    const authenticatedUser = useSelector(getLoggedInUser);
    const [activeStep, setActiveStep] = React.useState(0);
    const [attributes, setAttributes] = useState();
    const workspace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const setActiveOrganization = useOrganizationStore(state => state.setActiveOrganization);
    const setWorkspaceForOrganizationBeingCreated = useOrganizationStore(state => state.setWorkspaceForOrganizationBeingCreated);
    const workspacesManagedByActiveOrganization = useOrganizationStore(state => state.workspacesManagedByActiveOrganization);
    const setWorkspacesManagedByActiveOrganization = useOrganizationStore(state => state.setWorkspacesManagedByActiveOrganization);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [finalizingCreation, setFinalizingCreation] = useState(false);
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedAttributes = params.get("attributes");
        if (receivedAttributes) {
            setAttributes(JSON.parse(decodeURIComponent(receivedAttributes)));
        }
    }, []);

    const finalizeCreation = async () => {
        setFinalizingCreation(true);
        let formData = JSON.parse(JSON.stringify({ workspace, organization }));
        if (attributes) {
            formData.attributes = attributes;
        }
        if (organization?.avatar && typeof organization?.avatar !== 'string') {
            let organizationLogoUploadForm = new FormData();
            organizationLogoUploadForm.append("file", organization?.avatar[0].file);
            let response = await APIService.uploadNewFile(null, organizationLogoUploadForm, 0, null, 0);
            let result = response.data;
            formData.organization.avatar = result;
        }
        if (workspace?.avatar && typeof workspace?.avatar !== 'string') {
            let workspaceLogoUploadForm = new FormData();
            workspaceLogoUploadForm.append("file", workspace?.avatar[0].file);
            let response = await APIService.uploadNewFile(null, workspaceLogoUploadForm, 0, null, 0);
            let result = response.data;
            formData.workspace.avatar = result;
        }
        APIService.upsertOrganizationWithWorkspace(formData, (response, error) => {
            if (error) {
                setFinalizingCreation(true);
                if (error.toLowerCase().includes('Pay For Extra Workspace'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                    return;
                }
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let { data } = response;
            let workspace = data.workspace;
            let organization = data.organization;
            dispatch(setActiveWorkspace(workspace));
            setActiveOrganization(organization);
            setWorkspacesManagedByActiveOrganization([...(workspacesManagedByActiveOrganization ?? []), workspace]);
            dispatch(setLoggedInUser({
                ...authenticatedUser,
                has_workspaces: true
            }));
            dispatch(cachePosts([]));
            setWorkspaceForOrganizationBeingCreated(null);
            setTimeout(() => {
                setFinalizingCreation(false);
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
            }, 1000);
        });
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const steps = [
        {
            stepLabel: t('organization'),
            content: <OrganizationSetup attributes={attributes} handleNext={handleNext} handleBack={handleBack} />
        },
        {
            stepLabel: t('workspace'),
            content: <NewWorkspaceInOrganization attributes={attributes} handleOnClose={handleNext} />
        },
        {
            stepLabel: t('workspace_approval_workflow'),
            content: <Flex direction={'column'} className={'gap-3'}>
                <WorkSpaceApprovalWorkFlow
                    workSpace={workspace}
                    approvalOption={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]}
                    passOwnersPostsThroughApproval={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]}
                    passAdminsPostsThroughApproval={(workspace?.settings ?? {})[WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL]}
                    handleApprovalOptionSelectionHandler={({ selection, approvalCountValue, allowOwnersToGoThroughApproval }) => {
                        let settings = {
                            [WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]: selection,
                            [WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]: allowOwnersToGoThroughApproval
                        };
                        if (approvalCountValue) {
                            settings[WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE] = approvalCountValue;
                        }
                        setWorkspaceForOrganizationBeingCreated({ ...workspace, settings });
                    }}
                />
                <Button onClick={handleNext} size="sm" className="rounded-pill">{t('next')}</Button>
            </Flex>
        },
        {
            stepLabel: t('review'),
            content: <OrganizationAndWorkspaceSetupReview handleNext={handleNext} />
        },
        {
            stepLabel: t('done'),
            content: <Flex><SubmitButton className="rounded-pill" onClick={finalizeCreation} label={t('create')} processing={finalizingCreation} /></Flex>
        }
    ];

    return (
        <GeneralCardLayout
            leftSideContent={
                <div>
                    <ProfileDropdown />
                </div>
            }
        >
            <h4 className="mb-4">{t('set_up_your_organization')}</h4>
            <Row className="mb-3 g-3">
                <Col lg={6}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {
                            steps.map((step, index) => (
                                <Step key={step.stepLabel}>
                                    <StepLabel
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (index < activeStep) {
                                                setActiveStep(index);
                                            }
                                        }}>
                                        {step.stepLabel}
                                    </StepLabel>
                                    <StepContent>
                                        {step.content}
                                    </StepContent>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Col>
                <Col lg={6} className="d-none d-lg-block">
                    <Flex direction={'column'} alignItems={'center'} justifyContent={'center'} className={'h-100'}>
                        <Image src={setupBg} className="img-fluid" />
                    </Flex>
                </Col>
            </Row>
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard />
                </Modal.Body>
            </Modal>
        </GeneralCardLayout>
    )
}