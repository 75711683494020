import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import WorkSpacesTableHeader from './WorkSpacesTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { RoutePaths } from '../../../constants';
import StringUtils from 'utils/string';
import moment from 'moment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useDispatch } from 'react-redux';
import { getCachedWorkSpaces, setReloadPage } from '../../../redux/slices/workspaceslice';
import { getLoggedInUserFromLocalStorage } from 'utils/user_utils';
import { getActiveWorkspaceFromLocalStorage } from 'utils/workspace_utils';
import { useSelector } from 'react-redux';
import useOrganizationStore from 'state/organization_store';

const loggedInUser = getLoggedInUserFromLocalStorage();
const activeWorkSpace = getActiveWorkspaceFromLocalStorage();

const selectedState = (id) => {
    if (id === activeWorkSpace?._id) {
        return 'status-online';
    }
    return ''
}

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { _id, name, archived, avatar } = rowData.row.original;
            return (
                <Link style={{
                    pointerEvents: archived ? 'none' : 'all'
                }} to={`${RoutePaths.SWITCH_WORKSPACE}?to=${_id}`}>
                    <Flex alignItems="center">
                        {avatar ? (
                            <Avatar src={avatar} size="xl" className={`me-2 ${selectedState(_id)}`} />
                        ) : (
                            <Avatar size="xl" name={name} className={`me-2 ${selectedState(_id)}`} />
                        )}
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{name}</h5>
                        </div>
                    </Flex>
                </Link>
            );
        }
    },
    {
        accessor: 'role',
        Header: 'Role',
        Cell: rowData => {
            const { role } = rowData.row.original;
            return <span>{StringUtils.capitalizeString(role)}</span>;
        }
    },
    {
        accessor: 'owner_id',
        Header: 'Ownership',
        Cell: rowData => {
            const { owner_id } = rowData.row.original;
            return <span>{owner_id === loggedInUser?._id ? "Created by You" : "Invited"}</span>;
        }
    },
    {
        accessor: 'archived',
        Header: 'State',
        Cell: rowData => {
            const { archived } = rowData.row.original;
            return <span>{archived ? "Archived" : "Active"}</span>;
        }
    },
    {
        accessor: 'created_at',
        Header: 'Created At',
        Cell: rowData => {
            const { created_at } = rowData.row.original;
            return <span>{moment(new Date(created_at)).format("LLLL")}</span>;
        }
    }
];

const WorkSpaceSearch = ({ cardClassName = '' }) => {

    const cachedWorkSpaces = useSelector(getCachedWorkSpaces);
    const workspacesManagedByCurrentOrganization = useOrganizationStore(state => state.workspacesManagedByActiveOrganization);
    const [workSpaces, setWorkSpaces] = useState((workspacesManagedByCurrentOrganization.length > 0 ? workspacesManagedByCurrentOrganization : cachedWorkSpaces ?? []).map(workSpace => {
        return {
            ...workSpace,
            disabled: workSpace?.owner_id !== loggedInUser?._id
        }
    }).sort(x => x._id === activeWorkSpace?._id ? -1 : 1));

    const dispatch = useDispatch();

    const workSpaceUpdateHandler = (updatedWorkSpaces) => {
        setWorkSpaces([...updatedWorkSpaces]);
    }

    const bulkWorkspacesUpdateHandler = (updatedData) => {
        setWorkSpaces((prevWorkspaces) => {
            for (let workspace of updatedData) {
                let existingWorkspace = prevWorkspaces.find(x => x._id === workspace._id);
                if (existingWorkspace) {
                    let index = prevWorkspaces.indexOf(existingWorkspace);
                    existingWorkspace.name = workspace.name;
                    prevWorkspaces[index] = existingWorkspace;
                }
            }
            return [...prevWorkspaces];
        });
        dispatch(setReloadPage(true));
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={workSpaces}
            selection
            sortable
            pagination
            perPage={10}
        >
            <Card className={cardClassName}>
                <Card.Header>
                    <WorkSpacesTableHeader
                        table
                        workSpaces={workSpaces}
                        workSpaceUpdateHandler={workSpaceUpdateHandler}
                        bulkWorkspacesUpdateHandler={bulkWorkspacesUpdateHandler}
                        onWorkspacesToBeRemoved={(workspacesToBeRemoved) => {
                            setWorkSpaces(prevWorkSpaces =>
                                prevWorkSpaces.filter(workspace =>
                                    !workspacesToBeRemoved.some(workspaceToRemove =>
                                        workspaceToRemove._id === workspace._id
                                    )
                                )
                            );
                        }}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={workSpaces.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default WorkSpaceSearch;
