import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
    getGMBCTAActionType,
    getGMBCTAActionURL,
    setGMBCTAActionType,
    setGMBCTAActionURL
} from '../../../../../redux/slices/postslice';
import EditableSection from '../debounceable_edit_box';

function CTAPostPanel(props) {

    const {
        rowUpdateHandler,
        bulkContentMode = false,
        bulkContentRow = {}
    } = props;
    const dispatch = useDispatch();
    const ctaActionType = useSelector(getGMBCTAActionType);
    const [bulkContentActionType, setBulkContentActionType] = useState(props?.callToActionButton?.replace(" ", "_"));
    const [bulkContentCallToActionUrl, setBulkContentCallToActionUrl] = useState(props.callToActionUrl);
    const ctaActionURL = useSelector(getGMBCTAActionURL);
    const actionTypes = [
        {
            label: 'BOOK', value: 'BOOK'
        },
        {
            label: 'ORDER', value: 'ORDER'
        },
        {
            label: 'LEARN MORE', value: 'LEARN_MORE'
        },
        {
            label: 'SIGN UP', value: 'SIGN_UP'
        },
        {
            label: 'CALL', value: 'CALL'
        }
    ];

    useEffect(() => {
        if (bulkContentMode) {
            setBulkContentActionType(props?.bulkContentRow?.google_my_business_call_to_action_button);
            setBulkContentCallToActionUrl(props?.bulkContentRow?.google_my_business_call_to_action_url);
        }
    }, [props.bulkContentRow]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10
            }}>
            <Form.Group>
                <Form.Label>Add a button (Optional)</Form.Label>
                <Form.Select
                    value={bulkContentMode ? bulkContentActionType?.replace(" ", "_") : ctaActionType}
                    placeholder={'Add an optional button users can click'}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (bulkContentMode) {
                            setBulkContentActionType(value);
                            let updatedRow = { ...bulkContentRow }
                            if (!value) {
                                value = " ";
                            }
                            updatedRow.google_my_business_call_to_action_button = value;
                            rowUpdateHandler(updatedRow);
                        } else {
                            dispatch(setGMBCTAActionType(value));
                        }
                    }}
                >
                    {
                        actionTypes.map((x) => {
                            return (<option value={x.value}>{x.label}</option>)
                        })
                    }
                </Form.Select>
            </Form.Group>
            <EditableSection
                value={bulkContentMode ? bulkContentCallToActionUrl : ctaActionURL}
                title="Action URL"
                placeholder={"Where users should be taken to when the button above is clicked"}
                onChangeHandler={value => {
                    if (bulkContentMode) {
                        setBulkContentCallToActionUrl(value);
                        let updatedRow = { ...bulkContentRow }
                        if (!value) {
                            value = " ";
                        }
                        updatedRow.google_my_business_call_to_action_url = value;
                        rowUpdateHandler(updatedRow);
                    } else {
                        dispatch(setGMBCTAActionURL(value));
                    }
                }}
            />
        </div>
    );
}
export default React.forwardRef(CTAPostPanel);