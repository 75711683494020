/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext from "context/Context";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
// import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { getMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import MediaValidator from "utils/mediaValidator";
// import StringUtils from "utils/string";
// import { darkTheme, lightTheme } from "utils/theme";
import { PlatformIdentifier, RoutePaths } from "../../../../../constants";
import {
  getSelectedPlatformForPreview, setSelectedPlatformForPreview
} from "../../../../../redux/slices/platformSlice";
import {
  getAltSaveTrigger, getCanFixAllImagesStore,
  getManuallyEditedPlatforms,
  getNotifyPreviewsOfLiveMediaChanges,
  getPostReadyForPreviews,
  getSelectedPlatforms, getTriggerComponentRemount,
  setTriggerComponentRemount
} from "../../../../../redux/slices/postslice";
import { findPlatformByIdentifier, platforms } from "../../../../app/platforms";
import Facebook from "./facebook.js";
// import FixAllImages from "./fix_all_images";
import Google from "./google.js";
import Instagram from "./instagram.js";
import LinkedIn from "./linkedIn.js";
import Pinterest from "./pinterest.js";
import Reddit from "./reddit.js";
import { FixAllContainer, MainArena, PlatformList, PlatformTabItem } from "./styles";
import Telegram from "./telegram.js";
import TikTok from "./tikTok.js";
import Twitter from "./twitter.js";
import Threads from "./threads.js";
import Wordpress from "./wordpress";
import YouTube from "./youtube.js";
import Blogger from "./blogger.js";
import { faImage, faInfoCircle, faVideo } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
import Flex from "components/common/Flex";
// import { BiMove } from "react-icons/bi";
// import { displayPlatformIcon } from "utils/platform_utils";
// import { Info } from "./styles/facebook";
import PreviewSkeleton from './PreviewSkeleton'
import ChangeLog from "./ChangeLog";

const PostPreviewer = () => {
  const dispatch = useDispatch();
  const postReadyForPreviews = useSelector(getPostReadyForPreviews);
  const activePlatformTab = useSelector(getSelectedPlatformForPreview);
  const [displayPlatform, setDisplayPlatform] = useState();
  const altSaveTrigger = useSelector(getAltSaveTrigger);
  const selectedPlatforms = useSelector(getSelectedPlatforms) ?? [];
  const [platformsToPreview, setPlatformsToPreview] = useState([]);
  const triggerComponentRemount = useSelector(getTriggerComponentRemount);
  // const canFixAllImagesStore = useSelector(getCanFixAllImagesStore);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [faultyImageFound, setFaultyImageFound] = useState(false);
  const { config } = useContext(AppContext);
  const [currentDisplayingPlatform, setCurrentDisplayingPlatform] = useState('all');
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [triggerReRender, setTriggerReRender] = useState(false);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);

  useEffect(() => {
    setTriggerReRender(!triggerReRender);
  }, [notifyPreviewsOfLiveMediaChanges])


  const previewSkeletonFiller = (identifier, initialMarginTop = false) => {
    return <PreviewSkeleton />
  }

  const allAvaliablePlatforms = [
    PlatformIdentifier.FACEBOOK,
    PlatformIdentifier.LINKED_IN,
    PlatformIdentifier.THREADS,
    PlatformIdentifier.TWITTER,
    PlatformIdentifier.INSTAGRAM,
    PlatformIdentifier.TELEGRAM,
    PlatformIdentifier.YOUTUBE,
    PlatformIdentifier.TIKTOK,
    PlatformIdentifier.PINTEREST,
    PlatformIdentifier.GOOGLE_MY_BUSINESS,
    PlatformIdentifier.REDDIT,
    PlatformIdentifier.WORDPRESS,
    PlatformIdentifier.BLOGGER

  ]

  const dataToValidate = [
    {
      identifier: PlatformIdentifier.FACEBOOK,
      media: media_items_arrangement?.facebook?.media ?? []
    },
    {
      identifier: PlatformIdentifier.INSTAGRAM,
      media: media_items_arrangement?.instagram?.media ?? []
    },
    {
      identifier: PlatformIdentifier.LINKED_IN,
      media: media_items_arrangement?.linked_in?.media ?? []
    },
    {
      identifier: PlatformIdentifier.TWITTER,
      media: media_items_arrangement?.twitter?.media ?? []
    },
    {
      identifier: PlatformIdentifier.THREADS,
      media: media_items_arrangement?.threads?.media ?? []
    },
    {
      identifier: PlatformIdentifier.TELEGRAM,
      media: media_items_arrangement?.telegram?.media ?? []
    },
    {
      identifier: PlatformIdentifier.TIKTOK,
      media: media_items_arrangement?.tiktok?.media ?? []
    }
  ]

  useEffect(() => {
    if (!activePlatformTab && selectedPlatforms?.length) {
      switchActivePlatform(selectedPlatforms[0].identifier);
      dispatch(setSelectedPlatformForPreview(selectedPlatforms[0].identifier));
      return
    }
    switchActivePlatform(activePlatformTab);
  }, []);

  const triggerRefresh = () => {
    triggerComponentRemount && switchActivePlatform(triggerComponentRemount);
    setTimeout(() => {
      dispatch(setTriggerComponentRemount(null))
    }, 1000);
  }

  useEffect(() => {
    triggerComponentRemount != null && triggerRefresh()
  }, [triggerComponentRemount]);
  useEffect(() => {
    if (activePlatformTab != 'refresh') {
      return;
    }
    const presentTab = displayPlatform;
    activePlatformTab == 'refresh' && setDisplayPlatform(null);
    setTimeout(() => {
      setDisplayPlatform(presentTab)
    }, 1000);
  }, [activePlatformTab]);

  const switchActivePlatform = (identifier) => {
    dispatch(setSelectedPlatformForPreview(identifier));
    let newDisplayablePlatform;
    switch (identifier) {
      case PlatformIdentifier.FACEBOOK:
        newDisplayablePlatform = <Facebook />;
        break;
      case PlatformIdentifier.TWITTER:
        newDisplayablePlatform = <Twitter key={altSaveTrigger} />;
        break;
      case PlatformIdentifier.THREADS:
        newDisplayablePlatform = <Threads key={altSaveTrigger} />;
        break;
      case PlatformIdentifier.LINKED_IN:
        newDisplayablePlatform = <LinkedIn />;
        break;
      case PlatformIdentifier.REDDIT:
        newDisplayablePlatform = <Reddit />;
        break;
      case PlatformIdentifier.INSTAGRAM:
        newDisplayablePlatform = <Instagram />;
        break;
      case PlatformIdentifier.YOUTUBE:
        newDisplayablePlatform = <YouTube />;
        break;
      case PlatformIdentifier.TIKTOK:
        newDisplayablePlatform = <TikTok />;
        break;
      case PlatformIdentifier.TELEGRAM:
        newDisplayablePlatform = <Telegram />;
        break;
      case PlatformIdentifier.PINTEREST:
        newDisplayablePlatform = <Pinterest />;
        break;
      case PlatformIdentifier.GOOGLE_MY_BUSINESS:
        newDisplayablePlatform = <Google />;
        break;
      case PlatformIdentifier.WORDPRESS:
        newDisplayablePlatform = <Wordpress />;
        break; //! WORDPRESS regains support.
      case PlatformIdentifier.BLOGGER:
        newDisplayablePlatform = <Blogger />;
        break;
      case 'refresh':
        newDisplayablePlatform = null;
        break;
      default:
        newDisplayablePlatform = <Facebook />;
        break;
    }
    setDisplayPlatform(newDisplayablePlatform);
  };

  const isThereContentOrMediaToProceedWithPreviewing = () => {
    let dataEntryAvailable = false;
    try {
      for (const platform of allAvaliablePlatforms) {
        const platformText = media_items_arrangement[platform]?.text;
        const platformMedia = media_items_arrangement[platform]?.media;
        let platformWasEdited = !manuallyEditedPlatforms ? false : manuallyEditedPlatforms[platform] || false;
        if (platform === PlatformIdentifier.TWITTER) {
          const platformText = media_items_arrangement[platform]?.length ? media_items_arrangement[platform][0]?.text : [];
          const platformMedia = media_items_arrangement[platform]?.length ? media_items_arrangement[platform][0]?.media : []
          if (!platformMedia?.length && !platformText?.length && !platformWasEdited) {
            continue
          }
        } else if (!platformMedia?.length && !platformText?.length && !platformWasEdited) {
          continue;
        }
        dataEntryAvailable = true;
      }
      return dataEntryAvailable

    } catch (e) {
      console.log("🚀 ~ isThereContentOrMediaToProceedWithPreviewing ~ e:", e)
      return false;
    }
  }
  const displayALlAvailablePlatformsPreviews = () => {
    let dataEntryAvailable = isThereContentOrMediaToProceedWithPreviewing()


    if (!selectedPlatforms?.length) {
      return previewSkeletonFiller()
    }

    if (!dataEntryAvailable) {
      return previewSkeletonFiller()
    }

    let isPlatformSelected = selectedPlatforms?.find(p => p.selected);
    if (!isPlatformSelected) {
      return previewSkeletonFiller()
    }

    return allAvaliablePlatforms?.map((platform, index) => {
      const platformData = selectedPlatforms?.find(p => (p?.identifier === platform && p.selected));
      if (platformData) {
        return <Flex
          direction={'column'}
          style={{
            marginBottom: 40
          }}
        >
          <Card
            className={`mb-3 p-0 ${index === 0 ? 'mt-2' : ''}`}
          >
            <Card.Body
              className="p-2"
            >
              {renderActivePreview(platform)}
            </Card.Body>
          </Card>
        </Flex>;
      } else {
        return <></>
      }
    });
  }

  const renderActivePreview = (identifier) => {
    switch (identifier) {
      case 'all':
        return displayALlAvailablePlatformsPreviews();
      case PlatformIdentifier.FACEBOOK:
        return <Facebook />;
      case PlatformIdentifier.LINKED_IN:
        return <LinkedIn />;
      case PlatformIdentifier.GOOGLE_MY_BUSINESS:
        return <Google />;
      case PlatformIdentifier.TWITTER:
        return <Twitter />;
      case PlatformIdentifier.THREADS:
        return <Threads />;
      case PlatformIdentifier.REDDIT:
        return <Reddit />;
      case PlatformIdentifier.INSTAGRAM:
        return <Instagram />;
      case PlatformIdentifier.YOUTUBE:
        return <YouTube />;
      case PlatformIdentifier.TIKTOK:
        return <TikTok />;
      case PlatformIdentifier.TELEGRAM:
        return <Telegram />;
      case PlatformIdentifier.PINTEREST:
        return <Pinterest />;
      case PlatformIdentifier.WORDPRESS:
        return <Wordpress />;
      case PlatformIdentifier.BLOGGER:
        return <Blogger />;
      default:
        return <Facebook />;
    }
  }

  const updatePlatformToPreview = () => {
    let tempPlatforms = selectedPlatforms;
    if (!tempPlatforms?.length) {
      tempPlatforms = platforms;
    }

    const mapped = tempPlatforms?.map((item) => {
      return item?.identifier;
    });

    setPlatformsToPreview(mapped);
  };

  useEffect(() => {
    updatePlatformToPreview();
  }, [notifyPreviewsOfLiveMediaChanges]);

  function convertToCamelCase(inputString) {
    const words = inputString?.split('_');
    const capitalizedWords = words?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1));
    const camelCaseString = capitalizedWords?.join('');
    return camelCaseString;
  }


  const checkForFaultyImages = async () => {
    const { success } = await MediaValidator(dataToValidate);
    if (success !== true) {
      setFaultyImageFound(true);
      return
    }
    setFaultyImageFound(false);
    return
  }

  useEffect(() => {
    checkForFaultyImages()
  }, [media_items_arrangement])

  if (!postReadyForPreviews) return null;

  try {
    return (
      <Card
        className='mb-3'
        style={{
          height: '87vh',
          overflowY: 'auto',
        }}
      >
        <Card.Body className="p-0" id="preview-post">
          {/* <MetaTags>
            <meta name="referrer" content="no-referrer" />
            <meta name="description" content="Some description." />
            <meta property="og:title" content="Postly" />
          </MetaTags> */}
          <MainArena>
            <div
              className="d-flex justify-content-start gap-2 align-items-center mb-3"
            >
              <h6 style={{ fontSize: 14 }} className="m-0">Preview post</h6>
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{
                  width: 12,
                  height: 12,
                }}
              />
            </div>
            <div
              style={{
                fontSize: 11,
                color: config.isDark ? "whitesmoke" : "black",
                marginBottom: 10,
              }}
            >
              {
                !isThereContentOrMediaToProceedWithPreviewing() ? currentDisplayingPlatform === 'all' ? 'Select a social account and a post to preview' : `Edit your post to boost your campaign's performance on ${convertToCamelCase(currentDisplayingPlatform)}.` :
                  currentDisplayingPlatform === 'all' ? `Edit your post to boost your campaign's performance.` : `Edit your post to boost your campaign's performance on ${convertToCamelCase(currentDisplayingPlatform)}.`
              }

            </div>
            {
              // isThereContentOrMediaToProceedWithPreviewing() &&
              <Form.Group className="mb-3">
                <Form.Select
                  value={currentDisplayingPlatform}
                  onChange={(e) => {
                    setCurrentDisplayingPlatform(e.target.value);
                  }}>
                  <option value={'all'}>All selected platforms</option>
                  {
                    allAvaliablePlatforms?.map(x => {
                      return <option value={x}>{convertToCamelCase(x)}</option>
                    })
                  }
                </Form.Select>
              </Form.Group>
            }
            {renderActivePreview(currentDisplayingPlatform)}
            {/* <ChangeLog changes={} /> */}
          </MainArena>
        </Card.Body>
      </Card>
    );
  } catch (error) {
    console.log("🚀 ~ PostPreviewer ~ error:", error)

  }
};
export default PostPreviewer;
