import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';

export const defaultOrganization = {
    _id: "558eba947c7b4a40e10e468fd9bff343076bc29572cef666574f23608bee28ea",
    name: "Default Organization",
    tooltip: "All your workspaces are currently within the Default Organization. For better workspace management, consider creating a new organization.",
    created_at: new Date('2024-08-30T10:42:00.000Z'),
    __v: 0
};

const initialState = {
    activeOrganization: defaultOrganization,
    organizationBeingCreated: null,
    workspaceForOrganizationBeingCreated: null,
    allWorkspaces: [],
    workspacesManagedByActiveOrganization: [],
    organizationList: []
};

const storage = {
    getItem: async (name) => {
        return (await get(name)) || null;
    },
    setItem: async (name, value) => {
        await set(name, value);
    },
    removeItem: async (name) => {
        await del(name);
    },
};

const useOrganizationStore = create(
    persist((set) => ({
        ...initialState,
        reset: () => set(() => ({ ...initialState })),
        setActiveOrganization: (organization) => set(() => ({ activeOrganization: organization })),
        setOrganizationBeingCreated: (organization) => set(() => ({ organizationBeingCreated: organization })),
        setWorkspaceForOrganizationBeingCreated: (workspace) => set(() => ({ workspaceForOrganizationBeingCreated: workspace })),
        setAllWorkspaces: (allWorkspaces) => set(() => ({ allWorkspaces })),
        setWorkspacesManagedByActiveOrganization: (workspacesManagedByActiveOrganization) => set(() => ({ workspacesManagedByActiveOrganization })),
        setOrganizationList: (organizations) => set(() => ({ organizationList: organizations })),
    }),
        {
            name: 'postly-organizations-storage',
            storage: createJSONStorage(() => storage),
        }
    )
);

export default useOrganizationStore;
