import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import GeneralCardLayout from 'layouts/GeneralCardLayout';
import WorkSpaceApprovalWorkFlow from './workspace_approval_setup';
import {
    RoutePaths,
    WorkSpaceContentApprovalWorkFlow
} from '../../../constants';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { useNavigate } from 'react-router-dom';
import UpgradeAccountAnimation from 'assets/animations/update-to-premium.json';
import Lottie from "lottie-react";
import TooltipBadge from 'components/common/TooltipBadge';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { cachePosts } from 'redux/slices/postslice';
import { getActiveWorkSpace, setActiveWorkspace } from 'redux/slices/workspaceslice';
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { useTranslation } from 'react-i18next';

export const UpgradeToPremiumAccountCard = React.forwardRef((props, ref) => {

    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 50,
                    paddingTop: 10,
                    gap: 10
                }}>
                <Lottie
                    animationData={UpgradeAccountAnimation}
                    loop={false}
                    style={{
                        width: 140,
                        maxWidth: 140,
                        height: 140
                    }}
                />
                <h5
                    style={{
                        textAlign: 'center'
                    }}>This feature requires account upgrade or you need to buy Extra Workspaces
                </h5>
            </div>
            <Button
                onClick={() => {
                    navigate(`${RoutePaths.BILLING_STRIPE}`)
                }}
                variant={'primary'}>
                See Plans and Pricing
            </Button>
        </div>
    );
});

const NewWorkspace = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInUser = useSelector(getLoggedInUser);
    const [attributes, setAttributes] = useState();
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
    const [creatingWorkspace, setCreatingWorkspace] = useState(false);
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        first_name: attributes?.first_name ?? loggedInUser?.first_name ?? "",
        last_name: attributes?.last_name ?? loggedInUser?.last_name ?? "",
        workspace_name: ""
    });

    const workSpace = useSelector(getActiveWorkSpace);

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let receivedAttributes = params.get("attributes");
        if (receivedAttributes) {
            setAttributes(JSON.parse(decodeURIComponent(receivedAttributes)));
        }
    }, []);

    useEffect(() => {
        if (attributes) {
            setFormData({
                ...formData,
                first_name: attributes?.first_name,
                last_name: attributes?.last_name
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes]);

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        let workSpaceApprovalOption = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION) ?? 0;
        let thirdApprovalOptionValue = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE)
        let lockPostsOnApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
        let allowOwnersPostToGoThroughApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL) ?? false;
        let scheduleContentImmediatelyAfterApproval = localStorage.getItem(WorkSpaceContentApprovalWorkFlow.SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
        let workSpaceSettings = {
            [WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE]: lockPostsOnApproval,
            [WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION]: parseInt(workSpaceApprovalOption),
            [WorkSpaceContentApprovalWorkFlow.SCHEDULE_POSTS_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE]: scheduleContentImmediatelyAfterApproval,
            [WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL]: allowOwnersPostToGoThroughApproval
        }
        if (thirdApprovalOptionValue) {
            workSpaceSettings[WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE] = parseInt(thirdApprovalOptionValue)
        }
        formData.settings = workSpaceSettings;
        attemptNewWorkSpaceCreation(formData);
    };

    const attemptNewWorkSpaceCreation = (values) => {
        setCreatingWorkspace(true);
        let settings = values.settings;
        APIService.createNewWorkSpace(values.workspace_name, values.first_name, values.last_name, settings, attributes, (response, error) => {
            if (error) {
                setCreatingWorkspace(false);
                if (error.toLowerCase().includes('Pay For Extra Workspace'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                    return;
                }
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            let createdWorkSpace = response['data'];
            dispatch(setActiveWorkspace({ ...createdWorkSpace }));
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.LOCK_CONTENT_AFTER_APPROVAL_ON_ACTIVE_WORKSPACE);
            localStorage.removeItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL);
            dispatch(setLoggedInUser({
                ...loggedInUser,
                has_workspaces: true
            }));
            dispatch(cachePosts([]));
            setTimeout(() => {
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
            }, 1000);
        });
    }

    return (
        <GeneralCardLayout
            leftSideContent={
                <div>
                    <ProfileDropdown />
                </div>
            }
        >
            <h4 className="mb-0">{`Create a New Workspace ${attributes ? `for ${attributes?.first_name} ${attributes?.last_name}` : ``}`}</h4>
            <p className="mb-4">{t('workspace_use_case')}</p>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="first_name">
                        <Form.Label>{t('first_name')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('first_name')}
                            value={formData.first_name}
                            name="first_name"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="last_name">
                        <Form.Label>{t('last_name')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('last_name')}
                            value={formData.last_name}
                            name="last_name"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="workspace_name">
                    <Form.Label className='text-capitalize'>{t('workspace_name')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('enter_name_of_new_workspace')}
                        value={formData.workspace_name}
                        name="workspace_name"
                        onChange={handleChange}
                    />
                </Form.Group>
                <h5 className="mb-0 mt-3">{t('approval_workflow')}
                    <TooltipBadge
                        tooltip={t('approval_workflow_use_case')}
                        icon="question-circle"
                    />
                </h5>
                <WorkSpaceApprovalWorkFlow
                    workSpace={workSpace}
                    handleApprovalOptionSelectionHandler={({selection, approvalCountValue, allowOwnersToGoThroughApproval}) => {
                        localStorage.setItem(WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION, selection);
                        localStorage.setItem(WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL, allowOwnersToGoThroughApproval);
                        if (approvalCountValue) {
                            localStorage.setItem(WorkSpaceContentApprovalWorkFlow.THIRD_APPROVAL_OPTION_VALUE, approvalCountValue);
                        }
                    }}
                />
                <div className="text-end">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={creatingWorkspace || !formData.workspace_name || !formData.first_name || !formData.last_name}>
                        {creatingWorkspace ? "Please wait..." : "Create"}
                    </Button>
                </div>
            </Form>
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard />
                </Modal.Body>
            </Modal>
        </GeneralCardLayout>
    );
};
export default NewWorkspace;