import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import TooltipBadge from 'components/common/TooltipBadge';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLoggedInUser,
    getLoggedInUserFullName,
    setLoggedInUser
} from 'redux/slices/user_slice';
import ProfileBanner from './ProfileBanner';
import coverSrc from 'assets/img/icons/spot-illustrations/authentication-corner.png';

const ProfileSettings = () => {

    const dispatch = useDispatch();
    const fullName = useSelector(getLoggedInUserFullName);
    const [first, last] = fullName.split(" ");
    const loggedInUser = useSelector(getLoggedInUser);
    const [updating, setUpdating] = useState(false);
    const [endElementMessage, setEndElementMessage] = useState();

    const [formData, setFormData] = useState({
        firstName: first,
        lastName: last,
        email: loggedInUser?.email,
        occupation: loggedInUser?.occupation
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        let requestBody = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            occupation: formData.occupation,
            email: formData.email
        }
        setUpdating(true);
        APIService.updatePersonalInfo(requestBody, (response, error) => {
            setUpdating(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            setEndElementMessage(message);
            dispatch(setLoggedInUser({
                ...loggedInUser,
                first_name: formData.firstName,
                last_name: formData.lastName,
                occupation: formData.occupation
            }));
            toast.success(message, { theme: 'colored' }); 
        });
    };

    return (
        <Card className='mb-3'>
            <FalconCardHeader title="Profile Settings" endEl={<span style={{ color: '#7CB342' }}>{endElementMessage}</span>} />
            <Card.Body className="bg-light">
            <ProfileBanner>
                <ProfileBanner.Header
                coverSrc={coverSrc}
                avatar={loggedInUser?.avatar}
                className="mb-8"
                />
            </ProfileBanner>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                value={formData.firstName}
                                name="firstName"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                value={formData.lastName}
                                name="lastName"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="email">
                            <Form.Label>Email
                                <TooltipBadge
                                    tooltip="Changes to email address will require verification"
                                    icon="question-circle"
                                />
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="occupation">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Job Title"
                                value={formData.occupation}
                                name="occupation"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>
                    <div className="text-end">
                        <Button
                            disabled={updating
                                || !formData.firstName
                                || !formData.lastName
                                || !formData.email}
                            variant="primary"
                            type="submit">
                            {updating ? "Updating..." : "Update"}
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ProfileSettings;
