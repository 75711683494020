import React, { useContext, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import AppContext from "context/Context";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import routes from "routes/routes";
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import bgNavbar from "assets/img/generic/bg-navbar.png";
import { useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";
import { canLoggedInUserBeDemo } from "utils/user_utils";
import PurchaseCard from './PurchaseCard';
import { useBreakpoints } from "hooks/useBreakpoints";
import { isCustomDomain } from "utils/domain";

const NavbarVertical = () => {

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
  } = useContext(AppContext);

  const currentURL = window.location.href;
  const loggedInUser = useSelector(getLoggedInUser);
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const { breakpoints } = useBreakpoints();
  const offDesktopMode = breakpoints.down('xl');

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      style={{ marginLeft: -15, marginBottom: 30, marginTop: offDesktopMode ? 45 : 100 }}
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="card"
    >
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div
          className="navbar-vertical-content scrollbar"
          style={{ position: "relative" }}
        >
          <Nav className="flex-column" as="ul">
            {routes
              .filter((x) => {
                let keep = true;
                if (
                  x.visibleToOnlyDemoAccount &&
                  !canLoggedInUserBeDemo(loggedInUser)
                ) {
                  keep = false;
                }
                // Check if the child route is not visible to white-label workspaces
                if (x.isCustomDomain && isCustomDomain) {
                  keep = false;
                }
                return keep;
              })
              .map((route) => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu
                    canViewMore={route.canViewMore}
                    routes={route.children.filter((x) => {
                      let keep = true;
                      if (
                        x.visibleToOnlyDemoAccount &&
                        !canLoggedInUserBeDemo(loggedInUser)
                      ) {
                        keep = false;
                      }
                      if (x.isCustomDomain && isCustomDomain) {
                        keep = false;
                      }
                      return keep;
                    })}
                  />
                </Fragment>
              ))}
          </Nav>
          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
            {currentURL.includes("app.postly.ai") &&
              <PurchaseCard />
            }
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
