import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlatformIdentifier } from 'constants';
import AppContext from 'context/Context';
import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { darkTheme, lightTheme } from 'utils/theme';
import ImageEditButton from './image_edit_button';
import PlayMediaInstagram from './playMediaInstagram';
import { EditPopUpBtnDiv } from './styles/facebook';
import { EditPopUp, ImageDelete, PostImage } from './styles/instagram';
import { toast } from 'react-toastify';
import { rescaleImage } from 'utils/media';
import { platformSpecs } from 'utils/platform_utils';

const InstagramMediaDisplay = ({
  item,
  handleOpeningEditArea,
  handleDeleteMedia,
  max_min_imageSize,
  index,
  openVideoEditArea,
  setOpenVideoEditArea,
  useableMedia,
  viewOnly,
  setUseableMedia
}) => {
  let editInfoText = `Instagram images should be at least 1080 x 566`;
  let videoInfoText = `Instagram videos should be at least 1280 x 720`;
  let editVideoFormat = `Instagram videos supports MP4 and MOV format`;

  const [notValid, setNotValid] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const { config } = useContext(AppContext);

  function getImageDimension(url) {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener("load", function () {
        const height = this.height;
        const width = this.width;
        resolve({ height, width });
      }, false);
      image.src = url;
    });
  }
  const replaceFaultyMediaWithFixed = async (media, oldMedia) => {
    const _useableMedias = [...useableMedia];
    const newMedias = _useableMedias.map((_media) => {
      if (_media?.url === oldMedia?.url) return media;
      return _media
    })
    setUseableMedia(newMedias)
    toast.info(`Your recently added Image have been auto resized to the minimum required size for ${PlatformIdentifier.INSTAGRAM}`, {
      theme: 'colored'
    })
  }


  const checkImageValidation = async () => {
    const { width, height } = await getImageDimension(item?.url);
    if (!item?.external && (width < platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minWidth || height < platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minHeigh)) {
      // setNotValid(true)
      const dataURL = await rescaleImage(item?.url, width, height, platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minWidth, platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minHeight, false)
      let _item = { ...item, url: dataURL, optimized: true }
      if (_item?.file && _item?.file?.type) {
        const blob = await fetch(dataURL).then(res => res.blob());
        const file = new File([blob], _item?.file?.name, { type: _item?.file?.type });
        _item = { ..._item, file: file }
      }
      await replaceFaultyMediaWithFixed(_item, item)
    }
    setWidth(width);
    setHeight(height);
  }

  useEffect(() => {
    checkImageValidation()
  }, [item])


  return (
    <div style={{ height: "auto", width: "auto", position: "relative" }}>
      {!viewOnly && item?.is_video === false && notValid && !item?.uploaded && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "#949ca4AA",
            zIndex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "65%",
                backgroundColor: `${config.isDark
                  ? darkTheme.workSpaceSettingsHeaderBar
                  : lightTheme.emptyButtonActive
                  }`,
                padding: 10,
                borderRadius: 10,
              }}
            >
              <EditPopUp
                style={{
                  color: `${!config.isDark && "black"}`,
                  fontSize: 15,
                }}
              >
                {editInfoText}
                <EditPopUpBtnDiv>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleOpeningEditArea(item?.url, width, height)
                    }
                    style={{ width: "70px" }}
                  >
                    Fix
                  </Button>
                </EditPopUpBtnDiv>
              </EditPopUp>
            </div>
          }
        </div>
      )}
      {!viewOnly && !notValid && !item?.external && (
        <ImageEditButton
          variant="primary"
          onClick={() => handleOpeningEditArea(item?.url, width, height)}
          style={{
            position: "absolute",
            margin: 10,
          }}
        />
      )}
      {
        !viewOnly &&
        <ImageDelete
          editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
          onClick={(e) => handleDeleteMedia(item)}
        >
          <FontAwesomeIcon icon={faRemove} />
        </ImageDelete>
      }
      {item?.is_video ? (
        <PlayMediaInstagram
          url={item?.url}
          item={item}
          platform={PlatformIdentifier.INSTAGRAM}
          maximunHeight={max_min_imageSize > 0 && max_min_imageSize}
          indexer={index}
          editVideoFormat={editVideoFormat}
          videoInfoText={videoInfoText}
          checkingVideo={true}
          videoHeightLimit={719}
          videoWidthLimit={1279}
          file={item?.file}
          fullFile={item}
          external={item?.external}
          openVideoEditArea={openVideoEditArea}
          setOpenVideoEditArea={setOpenVideoEditArea}
        />
      ) : (
        <PostImage
          class="firstImage"
          style={{
            maxHeight: `${500}px`,
            minHeight: "unset",
            objectFit: "fill",
          }}
          src={item?.url}
          alt="post preview"
        ></PostImage>
      )}
    </div>
  );

}

export default InstagramMediaDisplay