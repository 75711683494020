import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import {
    getUploadableBulkContent,
    setUploadableBulkContent
} from "../../../../../redux/slices/postslice";

export default function BulkContentDatePreview(props) {

    let {
        rowIndex = 0,
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const dateVal = new Date() >= new Date(bulkContentRow.due_date) ? new Date() : new Date(bulkContentRow.due_date);
    const [date, setDate] = useState(isNaN(parseInt(dateVal.getTime())) ? new Date() : dateVal);

    return (
        <div
            style={{
                width: 200
            }}>
            <DatePicker
                minDate={new Date()}
                onChange={(e) => {
                    let newDate = new Date(e);
                    setDate(newDate);
                    let uploadableBulkContentCopy = [...uploadableBulkContent];
                    let updatedRow = { ...bulkContentRow };
                    updatedRow.due_date = newDate;
                    uploadableBulkContentCopy[rowIndex] = updatedRow;
                    dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
                }}
                selected={date}
                className={'form-control'}
            />
        </div>
    );
}
