import { DateTime } from 'luxon';

export default class RecurrenceMapper {

    static checkAndRepeatPostOnlyOnCalendar(post, _postIndex) {
        let postData = post.data;
        let repeatOption = postData?.repeat_option;
        if (!repeatOption) {
            return null;
        }
        let currentDate = new Date(postData.time_to_post ?? post.scheduled_for);
        if (isNaN(currentDate.getTime())) {
            currentDate = new Date();
        }
        let nextDate = RecurrenceMapper.getNextRecurringDate(currentDate, repeatOption);
        if (nextDate === null) {
            return nextDate;
        }
        let setToEndThisDay = false;
        if (repeatOption?.data?.end?.type === 'on') {
            const endDate = repeatOption?.data?.end?.date
            if (endDate) {
                const endDateMidnight = new Date(endDate);
                endDateMidnight.setHours(0, 0, 0, 0);
                const todayMidnight = new Date(nextDate);
                todayMidnight.setHours(0, 0, 0, 0);
                if (endDateMidnight.getTime() < todayMidnight.getTime()) {
                    setToEndThisDay = true;
                }
            }
        }
        if (setToEndThisDay) {
            return null
        }
        if (repeatOption?.data?.end?.ended) {
            return null;
        }
        return {
            ...post,
            data: {
                ...postData,
                time_to_post: nextDate
            },
            scheduled_for: nextDate,
            repeated: true,
            posted: new Date() >= nextDate
        };
    }

    static getNextDay(currentDate) {
        const currentDateTime = DateTime.fromJSDate(currentDate);
        const nextDateTime = currentDateTime.plus({ days: 1 });
        const nextDate = nextDateTime.toJSDate();
        return nextDate;
    }

    static getNextWeekday(currentDate, data) {
        const weekday = data.weekday;
        const currentDateTime = DateTime.fromJSDate(currentDate);
        const currentWeekday = currentDateTime.weekday === 7 ? 1 : currentDateTime.weekday + 1;
        const weekdayToSet = weekday === 7 ? 1 : weekday + 1;
        const daysToAdd = weekdayToSet > currentWeekday ? weekdayToSet - currentWeekday : 7 - (currentWeekday - weekdayToSet);
        const nextDateTime = currentDateTime.plus({ days: daysToAdd });
        const nextDate = nextDateTime.toJSDate();
        return nextDate;
    }

    static getTheOrdinalWeekdayOfTheNextMonth(currentDate, data) {
        const weekday = data.weekday;
        const ordinal = data.ordinal;
        const currentDateTime = DateTime.fromJSDate(currentDate);
        const nextMonthDateTime = currentDateTime.plus({ months: 1 }).startOf('month');
        const desiredWeekday = (weekday + 6) % 7 + 1;
        let ordinalWeekdayDateTime = nextMonthDateTime;
        if (nextMonthDateTime.weekday !== desiredWeekday) {
            const diff = desiredWeekday - nextMonthDateTime.weekday;
            if (diff > 0) {
                ordinalWeekdayDateTime = nextMonthDateTime.plus({ days: diff });
            } else {
                ordinalWeekdayDateTime = nextMonthDateTime.plus({ days: 7 + diff });
            }
        }
        const ordinalWeekday = ordinalWeekdayDateTime.set({ weekday: desiredWeekday });
        const nextDate = ordinalWeekday.plus({ weeks: ordinal - 1 }).toJSDate();
        return nextDate;
    }

    static getNextAnnuallyRecurringDate(currentDate, data) {
        const date_of_month = data.date_of_month;
        const month = data.month;
        if (!date_of_month) {
            return null;
        }
        if (month === undefined || month == null) {
            return null;
        }
        const currentYear = currentDate.getFullYear();
        let nextYear = currentYear + 1;
        const nextDate = new Date(nextYear, month, date_of_month);
        return nextDate;
    }

    static getNextRecurringWeekday(currentDate, data) {
        const week_days = data.week_days;
        const currentWeekday = currentDate.getDay();
        const nextWeekday = week_days.find((weekday) => weekday > currentWeekday) || week_days[0];
        let daysToAdd = nextWeekday - currentWeekday;
        if (daysToAdd <= 0) {
            daysToAdd += 7;
        }
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + daysToAdd);
        return nextDate;
    }

    static getNextCustomRecurringDate(currentDate, data) {
        let type = data.type;
        let end = data.end;
        let start = data.start;
        let nextDate;
        switch (type) {
            case "(interval)-days":
                nextDate = RecurrenceMapper.getNextCustomRecurringDay(currentDate, data, start, end);
                break;
            case "(interval)-weeks":
                nextDate = RecurrenceMapper.getNextCustomRecurringWeekday(currentDate, data, start, end);
                break;
            case "(interval)-months":
                nextDate = RecurrenceMapper.getNextCustomRecurringMonthDate(currentDate, data, start, end);
                break;
            case "yearly":
                nextDate = RecurrenceMapper.getNextCustomRecurringYearlyDate(currentDate, data, start, end);
                break;
            default:
                break;
        }
        return nextDate;
    }

    static getNextCustomRecurringDay(currentDate, data, start, end) {
        const interval = data.interval;
        let nextDate = null;
        switch (end.type) {
            case 'never':
                nextDate = RecurrenceMapper.getNextCustomDay(currentDate, interval);
                break;
            case 'on':
                const endDate = DateTime.fromJSDate(new Date(end.date));
                if (currentDate > endDate.toJSDate()) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = "The end date has been reached.";
                } else {
                    nextDate = RecurrenceMapper.getNextCustomDay(currentDate, interval);
                }
                break;
            case 'after':
                const occurrences = end.occurrences ?? 13;
                const now = DateTime.fromJSDate(new Date());
                const startDate = DateTime.fromJSDate(start);
                const diff = now.diff(startDate, 'days').toObject();
                const daysPassed = diff.days;
                if (daysPassed > occurrences) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = `The post has been published ${occurrences} times as specified.`;
                    end.ended_date = new Date();
                } else {
                    nextDate = RecurrenceMapper.getNextCustomDay(currentDate, interval);
                }
                break;
            default:
                break;
        }
        return nextDate;
    }

    static getNextCustomDay(currentDate, interval) {
        const currentDateTime = DateTime.fromJSDate(currentDate);
        const nextDateTime = currentDateTime.plus({ days: interval });
        return nextDateTime.toJSDate();
    }

    static getNextCustomRecurringWeekday(currentDate, data, start, end) {
        const interval = data.interval;
        const week_days = data.week_days;
        let nextDate = null;
        switch (end.type) {
            case 'never':
                nextDate = RecurrenceMapper.getNextCustomWeekday(currentDate, interval, week_days);
                break;
            case 'on':
                const endDate = DateTime.fromJSDate(new Date(end.date));
                if (currentDate > endDate.toJSDate()) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = "The end date has been reached.";
                } else {
                    nextDate = RecurrenceMapper.getNextCustomWeekday(currentDate, interval, week_days);
                }
                break;
            case 'after':
                const occurrences = end.occurrences ?? 13;
                const now = DateTime.fromJSDate(new Date());
                const startDate = DateTime.fromJSDate(start);
                const diff = now.diff(startDate, 'weeks').toObject();
                const weeksPassed = diff.weeks;
                if (weeksPassed > occurrences) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = `The post has been published ${occurrences} times as specified.`;
                    end.ended_date = new Date();
                } else {
                    nextDate = RecurrenceMapper.getNextCustomWeekday(currentDate, interval, week_days);
                }
                break;
            default:
                break;
        }
        return nextDate;
    }

    static getNextCustomWeekday(currentDate, interval, week_days) {
        const futureDate = DateTime.fromJSDate(currentDate).plus({ weeks: interval });
        const currentWeekday = futureDate.weekday;
        const nextWeekday = week_days.find((weekday) => weekday > currentWeekday) || week_days[0];
        let daysToAdd = nextWeekday - currentWeekday;
        if (daysToAdd <= 0) {
            daysToAdd += 7;
        }
        const nextDate = futureDate.plus({ days: daysToAdd }).toJSDate();
        return nextDate;
    }

    static getNextCustomRecurringMonthDate(currentDate, data, start, end) {
        const ordinal = data.ordinal;
        const date = data.date;
        const weekday = data.weekday;
        const interval = data.interval;
        let nextDate = null;
        switch (end.type) {
            case 'never':
                nextDate = DateTime.fromJSDate(currentDate).plus({ months: interval });
                nextDate = RecurrenceMapper.considerOrdinalsAndWeekdayInNextCustomRecurringMonthDate(date, ordinal, weekday, nextDate);
                nextDate = nextDate.toJSDate();
                break;
            case 'on':
                const endDate = DateTime.fromJSDate(new Date(end.date));
                if (currentDate > endDate.toJSDate()) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = "The end date has been reached.";
                } else {
                    nextDate = DateTime.fromJSDate(currentDate).plus({ months: interval });
                    nextDate = RecurrenceMapper.considerOrdinalsAndWeekdayInNextCustomRecurringMonthDate(date, ordinal, weekday, nextDate);
                    nextDate = nextDate.toJSDate();
                }
                break;
            case 'after':
                const occurrences = end.occurrences ?? 13;
                const now = DateTime.now();
                const startDate = DateTime.fromJSDate(start);
                const diff = now.diff(startDate, 'months').toObject();
                const monthsPassed = diff.months;
                if (monthsPassed > occurrences) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = `The post has been published ${occurrences} times as specified.`;
                    end.ended_date = DateTime.now().toJSDate();
                } else {
                    nextDate = DateTime.fromJSDate(currentDate).plus({ months: interval });
                    nextDate = RecurrenceMapper.considerOrdinalsAndWeekdayInNextCustomRecurringMonthDate(date, ordinal, weekday, nextDate);
                    nextDate = nextDate.toJSDate();
                }
                break;
            default:
                break;
        }
        return nextDate;
    }

    static considerOrdinalsAndWeekdayInNextCustomRecurringMonthDate(date, ordinal, weekday, nextDate) {
        if (date) {
            nextDate = nextDate.set({ day: date });
        }
        if (ordinal !== undefined && weekday !== undefined) {
            nextDate = nextDate.set({ day: 1 });
            const currentWeekday = nextDate.weekday;
            let daysToAdd = (7 - currentWeekday + weekday) % 7;
            if (daysToAdd === 0) {
                daysToAdd = 7;
            }
            nextDate = nextDate.plus({ days: daysToAdd });
            const currentOrdinal = Math.floor((nextDate.day - 1) / 7) + 1;
            const diff = ordinal - currentOrdinal;
            nextDate = nextDate.plus({ weeks: diff });
        }
        return nextDate;
    }

    static getNextCustomRecurringYearlyDate(currentDate, data, start, end) {
        const interval = data.interval;
        let nextDate = null;
        switch (end.type) {
            case 'never':
                nextDate = DateTime.fromJSDate(currentDate).plus({ years: interval });
                nextDate = nextDate.toJSDate();
                break;
            case 'on':
                const endDate = DateTime.fromJSDate(new Date(end.date));
                if (currentDate > endDate.toJSDate()) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = "The end date has been reached.";
                } else {
                    nextDate = DateTime.fromJSDate(currentDate).plus({ years: interval });
                    nextDate = nextDate.toJSDate();
                }
                break;
            case 'after':
                const occurrences = end.occurrences ?? 5;
                const now = DateTime.now();
                const startDate = DateTime.fromJSDate(start);
                const diff = now.diff(startDate, 'years').toObject();
                const yearsPassed = diff.years;
                if (yearsPassed > occurrences) {
                    nextDate = null;
                    end.ended = true;
                    end.end_reason = `The post has been published ${occurrences} times as specified.`;
                    end.ended_date = DateTime.now().toJSDate();
                } else {
                    nextDate = DateTime.fromJSDate(currentDate).plus({ years: interval });
                    nextDate = nextDate.toJSDate();
                }
                break;
            default:
                break;
        }
        return nextDate;
    }

    static getNextRecurringDate(currentDate, repeatOptions) {
        let nextDate;
        const type = repeatOptions.type;
        const data = repeatOptions.data;
        switch (type) {
            case "daily":
                nextDate = RecurrenceMapper.getNextDay(currentDate);
                break;
            case "weekly_on_(weekday)":
                nextDate = RecurrenceMapper.getNextWeekday(currentDate, data);
                break;
            case "monthly_on_the_(ordinal)_(weekday)":
                nextDate = RecurrenceMapper.getTheOrdinalWeekdayOfTheNextMonth(currentDate, data);
                break;
            case "annually_on_(month)_(date_of_month)":
                nextDate = RecurrenceMapper.getNextAnnuallyRecurringDate(currentDate, data);
                break;
            case "every_weekday_(week_days)":
                nextDate = RecurrenceMapper.getNextRecurringWeekday(currentDate, data);
                break;
            case "custom":
                nextDate = RecurrenceMapper.getNextCustomRecurringDate(currentDate, data);
                break;
            default:
                break;
        }
        return nextDate;
    }

}