import React, { useContext } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import AppContext from 'context/Context';
import SearchBox from './SearchBox';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import Flex from 'components/common/Flex';

const NavbarTop = () => {

  const {
    config: { navbarPosition, navbarCollapsed }
  } = useContext(AppContext);

  return (
    <Navbar
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
      className='w-100'
    >
      <Flex alignItems={'center'} style={{ width: '100%' }}>
        {navbarPosition === 'top' || navbarPosition === 'combo' ? (
          <Navbar.Collapse
            in={navbarCollapsed}
            className="scrollbar pb-3 pb-lg-0"
          >
            <Nav navbar>
              <NavbarTopDropDownMenus />
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Nav
            navbar
            className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
            as="ul"
          >
            <Nav.Item as="li">
              <SearchBox />
            </Nav.Item>
          </Nav>
        )}
        <div className='flex-grow-1' />
        <TopNavRightSideNavItem />
      </Flex>
    </Navbar>
  );
};

export default NavbarTop;
