import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import { BiBarChart } from 'react-icons/bi';
import { numberWithCommasFormat } from 'utils/analytics_utils';

const Overview = ({ title, value, children }) => {
    let profileValue = numberWithCommasFormat(parseInt(value));
    return (
        <Card className="mb-3 bg-white">
            <Card.Body>
                <Flex justifyContent={"between"} alignItems={"center"} gap={2}>
                    <div className='d-flex flex-column fw-bold'>
                        <span style={{ fontSize: '0.85rem' }}>{title}</span>
                        <span style={{ fontSize: '1.75rem' }}>{profileValue}</span>
                    </div>
                    <BiBarChart fontSize={"2.5rem"} />
                    {children}
                </Flex>
            </Card.Body>
        </Card>
    )
}

export default Overview;