import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import Divider from "components/common/Divider";
import Flex from "components/common/Flex";
import FreePageHeader from "components/common/FreePageHeader";
import CopyLinkButton from "components/common/copylinkbutton";
import { RoutePaths } from "constants";
import APIService from "http/api_service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { MdLock } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAutoShortenerStatus,
  getLinksFolders,
  setAutoShortenerStatus,
  setLinksFolders,
} from "redux/slices/links_shortener_slice";
import { getActiveWorkSpace, setSearchHint } from "redux/slices/workspaceslice";
import { copyToClipboard } from "utils/browser";
import { getPreviewFromUrl } from "utils/url";
import LinkMetadata from "./LinkMetadata";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";

const Shortener = ({ tabRef, setTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector(getAutoShortenerStatus);

  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [shortLink, setShortLink] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [displayNewFolderInput, setDisplayNewFolderInput] = useState(false);
  const [folderActionhandler, setFolderActionhandler] = useState("Create");
  const [allowLinksToExpire, setAllowLinksToExpire] = useState(false);
  const [showLinkPreview, setShowLinkPreview] = useState(true);
  const [saveLinkData, setSaveLinkData] = useState({
    link_name: "",
    save_folder: "",
    link_url: "",
  });
  const [whenToExpire, setWhenToExpire] = useState(
    moment(new Date()).add(7, "days").toDate()
  );
  const [formData, setFormData] = useState({
    full_link: "",
  });
  const [generatingShortLink, setGeneratingShortLink] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const linksFolders = useSelector(getLinksFolders);
  const [workSpaceOwner, setWorkspaceOwner] = useState();
  const [saveButtonState, setSaveButtonState] = useState("Save");
  const [linkMetadata, setLinkMetadata] = useState();
  const [fetchingLinkMetadata, setFetchingLinkMetadata] = useState(false);

  const getLinkMetaData = async (fullLink) => {
    try {
      setFetchingLinkMetadata(true)
      const result = await getPreviewFromUrl(fullLink ?? "");
      setLinkMetadata(result?.data);
      setFetchingLinkMetadata(false)
    } catch (error) {
      setFetchingLinkMetadata(false)
      setLinkMetadata(null);
    }
  }

  useEffect(() => {
    getLinkMetaData(formData?.full_link)
  }, [formData?.full_link])

  const fetchWorkspaceOwner = () => {
    APIService.fetchWorkSpaceOwner(workSpace._id, (response, error) => {
      if (response) {
        let { data } = response;
        setWorkspaceOwner(data);
      }
    });
  };

  const getStatusHandler = async () => {
    APIService.getAutoShortenedLinkStatus((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { data } = response;
      //   toast.success(message);
      dispatch(setAutoShortenerStatus(data));
    });
  };

  useEffect(() => {
    dispatch(setSearchHint("Search..."));
    fetchWorkspaceOwner();
    getStatusHandler();
  }, []);

  const userOnFreePlan = () => {
    let activePlan = workSpaceOwner?.active_plan;
    if (
      activePlan?.Name?.toLowerCase() === "free" ||
      activePlan?.Key?.toLowerCase() === "free"
    ) {
      return true;
    }
    return false;
  };

  const handleInputChanges = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (allowLinksToExpire) {
      if (!formData.fall_back_url_after_expiration) {
        toast.error(
          "Kindly provide a fallback link to be used after expiration",
          { theme: "colored" }
        );
        return;
      }
    }

    if (formData?.custom_domain) {
      if (userOnFreePlan() === true) {
        let metricInitializer = {
          action: MetricsProps.LINK_SHORTENER,
          detailedAction: `Upgrade notice: Shorten link with custom domain`,
          timestamp: new Date().toISOString(),
          route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
        }
        dispatch(setCollectMetric(metricInitializer))
        dispatch(setPopupUpgradeAlert(true))
        return;
      }
      if (!formData.custom_domain.includes(".")) {
        toast.error("Kindly provide subdomain or domain.", {
          theme: "colored",
        });
        return;
      }
    }

    if (formData.back_half && userOnFreePlan() === true) {
      let metricInitializer = {
        action:  MetricsProps.LINK_SHORTENER,
        detailedAction: `Upgrade notice: Shorten link with back-half`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    formData.linkMetadata = linkMetadata;
    formData.showLinkPreview = showLinkPreview;
    setGeneratingShortLink(true);
    APIService.shortenLink(formData, (response, error) => {
      setGeneratingShortLink(false);
      if (error) {
        toast.error(error, { theme: "colored" });
        if (error === "Domain not setup.") {
          setTab(4);
        }
        return;
      }
      let { data } = response;
      setShortLink(data);
      setSaveLinkData({ ...saveLinkData, link_url: data });
      document.getElementById("link-shortener-footer")?.scrollIntoView();
    });
  };

  const saveLinkHandler = async (e) => {
    e.preventDefault();
    if (!saveLinkData.link_name) {
      toast.error("Input link name", { theme: "colored" });
      return;
    }
    setSaveButtonState("Saving...");
    APIService.saveLink(saveLinkData, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setSaveButtonState("Save");
        return;
      }
      let { message } = response;
      toast.success(message);
      setSaveButtonState("Save");
      setSaveLinkData({
        ...saveLinkData,
        link_name: "",
        save_folder: "",
        link_url: "",
      });
      setShortLink("");
      setShowSaveModal(false);
    });
  };

  //get folders
  const fetchFoldersHandler = async (e) => {
    APIService.fetchFolders((response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      //   toast.success(message);
      dispatch(setLinksFolders(data));
    });
  };

  //create folder
  const createFolderHandler = async (e) => {
    e.preventDefault();
    if (!newFolderName) {
      toast.error("Input folder name", { theme: "colored" });
      return;
    }
    setFolderActionhandler("Creating...");
    let requestBody = {
      folder_name: newFolderName,
    };
    APIService.createLinkFolder(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        setFolderActionhandler("Create Folder");

        return;
      }
      let { message } = response;
      toast.success(message);
      fetchFoldersHandler();
      setFolderActionhandler("Create");
      setDisplayNewFolderInput(false);
      setNewFolderName("");
    });
  };

  const handleNewFolderClick = () => {
    setDisplayNewFolderInput(true);
  };

  const handleToggle = async (e) => {
    e.preventDefault();
    if (userOnFreePlan() === true) {
      let metricInitializer = {
        action: MetricsProps.LINK_SHORTENER,
        detailedAction: `Upgrade notice: Toggle auto-shorten links`,
        timestamp: new Date().toISOString(),
        route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
      }
      dispatch(setCollectMetric(metricInitializer))
      dispatch(setPopupUpgradeAlert(true))
      return;
    }
    let requestBody = {
      status: e.target.checked,
    };
    await APIService.toggleAutoShortenLinks(requestBody, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      let { message, data } = response;
      dispatch(setAutoShortenerStatus(data));
      toast.success(message);
      getStatusHandler();
    });
  };

  return (
    <>
      <Card.Body className="text-left py-50">
        <Card style={{ marginBottom: "20px", padding: "10px 10px" }}>
          <div
            style={{
              width: "100%",
              paddingBottom: "10px",
              // borderBottom: "1px solid ",
            }}
          >
            <>
              <h3 className="fw-normal fs-2 fs-md-2">Settings</h3>
              <Flex alignItems={"center"} className={"gap-3"}>
                <IoMdSettings size={20} />
                <Form.Label
                  style={{
                    paddingTop: 8,
                  }}
                  htmlFor="auto-shorten-link-settings"
                >
                  Auto-shorten links in your posts
                </Form.Label>
                <Form.Check
                  //   style={{
                  //     cursor: "pointer",
                  //   }}
                  id="auto-shorten-link-settings"
                  type="switch"
                  onChange={handleToggle}
                  checked={status}
                // label="Auto Shorten Links in Post"
                />
              </Flex>
            </>
          </div>
        </Card>

        <Card style={{ padding: "10px 10px" }}>
          <h3 className="fw-normal fs-2 fs-md-2">Shorten your links</h3>
          <Row className="justify-content-center">
            {
              // userOnFreePlan() ?
              //     <div>
              //         <p>Upgrade your account to use this service</p>
              //         <Button as={Link} to={`${RoutePaths.ACCOUNT}?t=billing`}>Upgrade</Button>
              //     </div>
              //     :
              <Col md={12}>
                <Form onSubmit={handleSubmit} className="g-2">
                  <Row>
                    <Col sm style={{ marginBottom: "12px" }}>
                      <Form.Label>Paste a long URL</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter long link here"
                        aria-label="Long Link Text box"
                        value={formData.full_link}
                        name={"full_link"}
                        onChange={handleInputChanges}
                        style={{ width: "100%", margin: "0px" }}
                      />
                    </Col>
                    {
                      showLinkPreview &&
                      <Col sm="auto">
                        <LinkMetadata
                          linkMetadata={linkMetadata}
                          fetchingLinkMetadata={fetchingLinkMetadata}
                        />
                      </Col>
                    }
                  </Row>
                  <Row className="g-3">
                    <Col sm md={12}>
                      <Form.Label>Domain</Form.Label>{" "}
                      <span
                        style={{ cursor: "pointer", fontSize: "12px" }}
                        data-tooltip="Add your domain"
                        onClick={() => {
                          if (userOnFreePlan() === true) {
                            let metricInitializer = {
                              action: MetricsProps.LINK_SHORTENER,
                              detailedAction: `Upgrade notice: Shorten link with custom domain`,
                              timestamp: new Date().toISOString(),
                              route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                            }
                            dispatch(setCollectMetric(metricInitializer))
                            dispatch(setPopupUpgradeAlert(true))
                            return;
                          }
                          navigate(`${RoutePaths.CUSTOM_DOMAIN}`);
                        }}
                      >
                        {" "}
                        (Set up a domain){" "}
                      </span>
                      <div style={{ width: "100%", position: "relative" }}>
                        <Form.Control
                          type="text"
                          placeholder="Enter the domain you set up e.g. link.remotelab.co"
                          aria-label="Long Link Text box"
                          value={formData.custom_domain}
                          name={"custom_domain"}
                          onChange={handleInputChanges}
                        // style={{width: "100%"}}
                        />
                      </div>
                    </Col>
                    <Col sm md={12} style={{ marginBottom: "12px" }}>
                      <Form.Label>Back-half (optional)</Form.Label>
                      <div style={{ width: "100%", position: "relative" }}>
                        <Form.Control
                          type="text"
                          placeholder="example: favourite-link"
                          aria-label="Long Link Text box"
                          value={formData.back_half}
                          name={"back_half"}
                          onChange={handleInputChanges}
                        />
                      </div>
                    </Col>
                  </Row>

                  {formData.full_link && (
                    <Flex
                      alignItems={"center"}
                      className={"gap-3"}
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <Form.Label
                        style={{
                          paddingTop: 8,
                        }}
                        htmlFor="allow-short-links-to-expire-check"
                      >
                        Allow this Link to expire?
                      </Form.Label>

                      <Form.Check
                        id="allow-short-links-to-expire-check"
                        type="switch"
                        checked={allowLinksToExpire}
                        onChange={(e) => {
                          setAllowLinksToExpire(e.target.checked);
                          if (!e.target.checked) {
                            setFormData((prevData) => {
                              prevData = { ...prevData };
                              delete prevData.expireAt;
                              delete prevData.fall_back_url_after_expiration;
                              return { ...prevData };
                            });
                          } else {
                            setFormData({
                              ...formData,
                              expireAt: new Date(),
                            });
                          }
                        }}
                      />

                      <Form.Label
                        style={{
                          paddingTop: 8,
                        }}
                        htmlFor="show-link-preview-check"
                      >
                        Show link preview?
                      </Form.Label>
                      <Form.Check
                        id="show-link-preview-check"
                        type="switch"
                        checked={showLinkPreview}
                        onChange={(e) => {
                          setShowLinkPreview(e.target.checked);
                        }}
                      />
                    </Flex>
                  )}
                  {allowLinksToExpire && (
                    <Flex direction={"column"}>
                      <Divider />
                      <Row className="gap-3">
                        <Col sm={12}>
                          <Form.Group className="text-start">
                            <Form.Label>When to Expire</Form.Label>
                            <ReactDatePicker
                              className="form-control"
                              selected={whenToExpire}
                              minDate={new Date()}
                              onChange={(newDate) => {
                                setWhenToExpire(newDate);
                                setFormData({
                                  ...formData,
                                  expireAt: newDate,
                                });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group className="text-start">
                            <Form.Label>Fallback Link</Form.Label>
                            <Form.Control
                              type={"text"}
                              placeholder={
                                "Specify a fallback link when your link expires"
                              }
                              value={formData?.fall_back_url_after_expiration}
                              name={"fall_back_url_after_expiration"}
                              onChange={handleInputChanges}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Flex>
                  )}
                  <Row>
                    <Col sm="auto" style={{ marginTop: "4" }}>
                      <Button
                        onClick={handleSubmit}
                        variant="primary"
                        disabled={!formData.full_link || generatingShortLink || fetchingLinkMetadata}
                        className="d-block w-100"
                      >
                        {generatingShortLink ? "Please wait..." : "Get your link"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            }
          </Row>
          {shortLink && (
            <Card.Footer id="link-shortener-footer" className="p-0">
              <Card.Header>
                <Card.Title>
                  <span>Link Shortened</span>
                </Card.Title>
              </Card.Header>
              <Card.Body className="bg-light">
                <Row>
                  <Col lg={6}>
                    <Flex alignItems={"center"} className={"pt-2"}>
                      <span
                        style={{
                          maxWidth: 300,
                        }}
                      >
                        {formData?.full_link}
                      </span>
                    </Flex>
                  </Col>
                  <Col lg={6}>
                    <Flex alignItems={"center"} className={"gap-4"}>
                      <a
                        style={{
                          maxWidth: 300,
                        }}
                        href={shortLink}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        {shortLink}
                      </a>
                      <CopyLinkButton
                        defaultText={"Copy"}
                        variant={"outline-info"}
                        handleCopy={() => {
                          copyToClipboard(shortLink);
                        }}
                      />
                      {userOnFreePlan() !== true && (
                        <Button onClick={() => setShowSaveModal(true)}>Save</Button>
                      )}
                    </Flex>
                  </Col>
                </Row>
              </Card.Body>
            </Card.Footer>
          )}
        </Card>
      </Card.Body>


      <Modal
        size="lg"
        centered
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        onExit={() => setShowSaveModal(false)}
      >
        <div style={{ padding: "0px" }}>
          <div style={{ border: "1px solid #bfc9d7" }}>
            <Modal.Header>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Modal.Title>Save In</Modal.Title>
                </div>
                <AiOutlineClose
                  cursor={"pointer"}
                  onClick={() => setShowSaveModal(false)}
                />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h5>Name</h5>
                <Form.Control
                  type="text"
                  placeholder="Enter link name"
                  aria-label="Long Link Text box"
                  value={saveLinkData.link_name}
                  name={"link_name"}
                  onChange={(e) =>
                    setSaveLinkData({
                      ...saveLinkData,
                      link_name: e.target.value,
                    })
                  }
                // style={{width: "100%"}}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <h5>Folder</h5>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  aria-label="Default select example"
                  onChange={(e) =>
                    setSaveLinkData({
                      ...saveLinkData,
                      save_folder: e.target.value,
                    })
                  }
                  value={saveLinkData.save_folder}
                  name={"save_folder"}
                >
                  <option style={{ cursor: "pointer" }} value="">
                    Open this select menu
                  </option>
                  {linksFolders?.map((folder, index) => (
                    <option
                      key={index}
                      style={{ cursor: "pointer" }}
                      value={folder?._id}
                    >
                      {folder?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  {displayNewFolderInput && (
                    <Form.Control
                      type="text"
                      placeholder="Folder name"
                      aria-label="Long Link Text box"
                      value={newFolderName}
                      name={"newFolderName"}
                      onChange={(e) => {
                        setNewFolderName(e.target.value);
                        setFolderActionhandler("Create Folder");
                        if (!e.target.value) {
                          setFolderActionhandler("Create");
                          setDisplayNewFolderInput(false);
                        }
                      }}
                    // style={{width: "100%"}}
                    />
                  )}
                  {
                    <Button
                      style={{
                        alignSelf: "flex-end",
                        backgroundColor: "#1e63fe",
                        minHeight: 35,
                      }}
                      onClick={
                        folderActionhandler === "Create"
                          ? handleNewFolderClick
                          : createFolderHandler
                      }
                      size="sm"
                      className={`px-3 px-sm-2`}
                    //   disabled={folderToMoveId ? false : true}
                    >
                      {folderActionhandler}
                    </Button>
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: "#fafbfd",
                      minHeight: 35,
                      color: "#7f8894",
                    }}
                    onClick={() => setShowSaveModal(false)}
                    size="sm"
                    className={`px-3 px-sm-5`}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      alignSelf: "flex-end",
                      backgroundColor: "#1e63fe",
                      minHeight: 35,
                    }}
                    onClick={saveLinkHandler}
                    size="sm"
                    className={`px-3 px-sm-5`}
                    disabled={saveLinkData.link_name ? false : true}
                  >
                    {saveButtonState}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={"This feature requires that you are on a premium account"}
        confirmText={"UPGRADE"}
        cancelText={"CANCEL"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(`${RoutePaths.BILLING_STRIPE}`);
        }}
      />
    </>
  );
};

export default Shortener;
