import {
  faCheck, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import { RoutePaths, WorkSpaceContentApprovalWorkFlow } from "constants";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApprovalOptionItem from "./approval_item";
import { workspaceIsOfAnyOfThePlans } from "utils/workspace_utils";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";

export default function WorkSpaceApprovalWorkFlow(props) {
  const {
    workSpace,
    editable = true,
    postsApprovalHandler,
    passOwnersPostsThroughApproval,
    passAdminsPostsThroughApproval,
    userRoleInWorkspace,
    desc = "Choose a suitable content approval workflow for this workspace. You can change this later under workspace settings.",
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workSpaceApprovalOption = parseInt(props.approvalOption ?? 0);
  const [selectedApprovalOption, setSelectedApprovalOption] = useState(
    isNaN(workSpaceApprovalOption) ? 0 : Math.max(workSpaceApprovalOption, 0)
  );
  const handleApprovalOptionSelectionHandler = props.handleApprovalOptionSelectionHandler;
  const [allowOwnersToGoThroughApproval, setAllowOwnersToGoThroughApproval] = useState(passOwnersPostsThroughApproval ?? false);
  const [allowAdminToGoThroughApproval, setAllowAdminToGoThroughApproval] =
    useState(passAdminsPostsThroughApproval ?? false);
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);

  const handleApprovalOptionSelection = (index, approvalCountValue) => {
    if (editable) {
      setSelectedApprovalOption(index);
      if (userRoleInWorkspace === "ADMIN") {
        console.log("admin")
        if (handleApprovalOptionSelectionHandler) {
          handleApprovalOptionSelectionHandler({
            index: index,
            approvalCountValue,
            variable: WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL,
            allowAdminToGoThroughApproval
          });
        }
      } else {
        if (handleApprovalOptionSelectionHandler) {
          console.log("owner")
          handleApprovalOptionSelectionHandler({
            index: index,
            approvalCountValue,
            variable: WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL,
            allowToGoThroughApproval: allowOwnersToGoThroughApproval
          });
        }
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          paddingBottom: 15,
          paddingTop: 15,
        }}
      >
        {editable
          ? desc
          : "Chosen approval workflow for this workspace. Cannot be edited"}
      </p>
      <Flex wrap={"flex-wrap"} direction={"column"} className={"gap-2"}>
        <ApprovalOptionItem
          editable={editable}
          index={0}
          selected={selectedApprovalOption === 0}
          selectedApprovalOptionHandler={handleApprovalOptionSelection}
          icon={
            <FontAwesomeIcon
              icon={faCheck}
              style={{
                color: "inherit",
                width: 20,
                height: 20,
              }}
            />
          }
          title={"None"}
          description={"Approvals are not needed for publishing"}
        />
        <ApprovalOptionItem
          index={1}
          editable={editable}
          selected={selectedApprovalOption === 1}
          selectedApprovalOptionHandler={handleApprovalOptionSelection}
          icon={
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: "inherit",
                width: 20,
                height: 20,
              }}
            />
          }
          title={"Optional"}
          description={"Approvals are enabled but not required for publishing"}
        />
        <ApprovalOptionItem
          index={2}
          editable={editable}
          selected={selectedApprovalOption === 2}
          selectedApprovalOptionHandler={handleApprovalOptionSelection}
          icon={
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: "inherit",
                width: 20,
                height: 20,
              }}
            />
          }
          title={"Required"}
          description={"A member has to approve the content before publishing"}
        />
        {/* <ApprovalOptionItem
          index={3}
          editable={editable}
          selected={selectedApprovalOption === 3}
          selectedApprovalOptionHandler={handleApprovalOptionSelection}
          icon={
            <FontAwesomeIcon
              icon={faCheckDouble}
              style={{
                color: "inherit",
                width: 20,
                height: 20,
              }}
            />
          }
          title={"Multi-level"}
          description={
            "2+ members need to approve the content before publishing"
          }
        /> */}
        <Flex gap={3}>
          <Form.Check
            type="radio"
            id={`3`}
            className="form-label-nogutter"
            name="third_approval_option_value"
            checked={selectedApprovalOption === 3 ? true : false}
            disabled={true}
          />
          <Form.Group>
            <Form.Select
              value={
                selectedApprovalOption === 3 && props?.thirdApprovalOptionValue
              }
              style={{ fontSize: "13px" }}
              onChange={(e) => {
                // const userIsOnSuggestedPlan = workspaceIsOfAnyOfThePlans(
                //   [
                //     "Scheduler Premium",
                //     "Scheduler Growth",
                //     "Agency",
                //     "Agency Plus",
                //     "Scheduler Enterprise",
                //     "Scheduler Expansion",
                //     "Scheduler Scale",
                //     "LTD UPGRADE",
                //     "Premium",
                //     "Growth",
                //     "Enterprise",
                //     "Expansion",
                //     "Scale",
                //     "AgencyTwo",
                //     "Agency Plus",
                //   ],
                //   workSpace
                // );
                // if (!userIsOnSuggestedPlan) {
                //   let metricInitializer = {
                //     action: MetricsProps.APPROVAL_WORKFLOW,
                //     detailedAction: `Upgrade notice: User tried to change approval option to multi-level without being on a suggested plan`,
                //     timestamp: new Date().toISOString(),
                //     route: window.location.pathname
                //       .replace(workSpace?._id, "")
                //       .replace("//", "/"),
                //   };
                //   dispatch(setCollectMetric(metricInitializer));
                //   dispatch(setPopupUpgradeAlert(true));
                //   return;
                // }
                if (e.target.value === "" || !e.target.value) {
                  return;
                }
                handleApprovalOptionSelection(3, parseInt(e.target.value));
              }}
            >
              <option style={{ fontSize: "13px" }} value="">
                Select number of members approval needed for publishing
              </option>
              {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                <option style={{ fontSize: "13px" }} key={x} value={x}>
                  {`${x} members approvals needed for post publishing`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Flex>
        {editable && userRoleInWorkspace === "OWNER" && (
          <Flex className={"gap-2"}>
            <Form.Check
              type="switch"
              id="allow_weekend"
              checked={allowOwnersToGoThroughApproval}
              label="Should posts scheduled by owner roles go through approval?"
              onChange={() => {
                let newSwitch = !allowOwnersToGoThroughApproval;
                setAllowOwnersToGoThroughApproval(newSwitch);
                if (postsApprovalHandler) {
                  postsApprovalHandler(
                    WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL,
                    newSwitch
                  );
                }
              }}
            />
          </Flex>
        )}
        {editable &&
          (userRoleInWorkspace === "ADMIN" ||
            userRoleInWorkspace === "OWNER") && (
            <Form.Check
              type="switch"
              id="allow_weekend"
              checked={allowAdminToGoThroughApproval}
              label="Should posts scheduled by admin roles go through approval?"
              onChange={() => {
                let newSwitch = !allowAdminToGoThroughApproval;
                setAllowAdminToGoThroughApproval(newSwitch);
                if (postsApprovalHandler) {
                  postsApprovalHandler(
                    WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL,
                    newSwitch
                  );
                }
              }}
            />
            // <Flex className={"gap-2 mt-4 mb-4"}>
            //   <p>Should posts scheduled by Admin roles go through approval?</p>
            //   <Switch
            //     checked={allowAdminToGoThroughApproval}
            //     onChange={() => {
            //       let newSwitch = !allowAdminToGoThroughApproval;
            //       setAllowAdminToGoThroughApproval(newSwitch);
            //       if (postsApprovalHandler) {
            //         postsApprovalHandler(
            //           WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL,
            //           newSwitch
            //         );
            //       }
            //     }}
            //   />
            // </Flex>
          )}
      </Flex>
      {/* {editable && userRoleInWorkspace === "OWNER" && (
        <Flex className={"gap-2 mt-4 mb-4"}>
         
          <p>Should posts scheduled by Owner roles go through approval?</p>
          <Switch
            checked={allowOwnersToGoThroughApproval}
            onChange={() => {
              let newSwitch = !allowOwnersToGoThroughApproval;
              setAllowOwnersToGoThroughApproval(newSwitch);
              if (postsApprovalHandler) {
                postsApprovalHandler(
                  WorkSpaceContentApprovalWorkFlow.ALLOW_OWNERS_POST_GO_THROUGH_APPROVAL,
                  newSwitch
                );
              }
            }}
          />
        </Flex>
      )} */}
      {/* {editable &&
        (userRoleInWorkspace === "ADMIN" ||
          userRoleInWorkspace === "OWNER") && (
          <Flex className={"gap-2 mt-4 mb-4"}>
            <p>Should posts scheduled by Admin roles go through approval?</p>
            <Switch
              checked={allowAdminToGoThroughApproval}
              onChange={() => {
                let newSwitch = !allowAdminToGoThroughApproval;
                setAllowAdminToGoThroughApproval(newSwitch);
                if (postsApprovalHandler) {
                  postsApprovalHandler(
                    WorkSpaceContentApprovalWorkFlow.ALLOW_ADMINS_POST_GO_THROUGH_APPROVAL,
                    newSwitch
                  );
                }
              }}
            />
          </Flex>
        )} */}
      <ConfirmModal
        open={openUpgradeDialog}
        title={"Action requires Upgrade"}
        message={`Sorry, this requires a premium account. Upgrade your plan to use this feature. Thank you.`}
        cancelText="CANCEL"
        confirmText={"UPGRADE"}
        onCancel={() => {
          setOpenUpgradeDialog(false);
        }}
        onConfirm={() => {
          setOpenUpgradeDialog(false);
          navigate(RoutePaths.ALL_BILLING);
        }}
      />
    </div>
  );
}
