import Flex from "components/common/Flex";
import { RoutePaths } from "../../../constants";
import APIService from "http/api_service";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { attachOccupationToWorkspace } from "layouts/SplashLayout";
import { useDispatch, useSelector } from 'react-redux';
import { cachePosts } from "redux/slices/postslice";
import { freeUpLocallyCachedPlatforms } from "../social/feed/CreatePost";
import { getLoggedInUser } from "redux/slices/user_slice";
import { setActiveWorkspace, setCachedWorkspaces } from "redux/slices/workspaceslice";

export default function SwitchWorkspace() {

    const navigate = useNavigate();
    const loggedInUser = useSelector(getLoggedInUser);
    const dispatch = useDispatch();

    useEffect(() => {
        let params = (new URL(document.location)).searchParams;
        let to = params.get("to");
        if (to) {
            APIService.fetchWorkSpace(to, (response, error) => {
                if (error) {
                    toast.error(error, { theme: 'colored' });
                    navigate(RoutePaths.DEFAULT);
                    return;
                }
                let responseData = response['data'];
                let workSpace = responseData['workspace'];
                workSpace['role'] = responseData['role'];
                workSpace['value'] = workSpace['name'];
                workSpace['title'] = responseData['title'];
                workSpace['label'] = workSpace['name'];
                workSpace['workspace_owner'] = responseData['workspace_owner'];
                if (workSpace.archived) {
                    toast.error("Sorry, unable to switch to an archived workspace");
                    navigate(RoutePaths.POSTS);
                    return;
                }
                attachOccupationToWorkspace(loggedInUser, workSpace);
                freeUpLocallyCachedPlatforms();
                APIService.updateLastViewedWorkSpace(workSpace._id, (_res, _err) => {
                    dispatch(setActiveWorkspace({ ...workSpace }));
                    dispatch(setCachedWorkspaces([]))
                    dispatch(cachePosts([]));
                    navigate(RoutePaths.DEFAULT);
                    window.location.reload(); //Force Reload Page
                });
            });
        } else {
            navigate(RoutePaths.DEFAULT);
        }
    }, []);

    return (
        <Flex
            className="min-vh-100"
            alignItems={'center'}
            justifyContent={'center'}>
            <Flex
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}>
                <h5>Switching workspace...</h5>
                <span>May take a few seconds</span>
            </Flex>
        </Flex>
    );
}