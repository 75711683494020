import APIService from "http/api_service";
import { getAboutRepublishingPost, getManuallyEditedPlatforms, getMunchedPostTargetIds, getRepeatOption, getPostSpecificDataVisualRequest, getSelectedPlatforms, getTimeToPostContent, setAdvancedContentCreationOptions, setCanExpandPublisherActions, setEditedPostFlag, setGMBCouponCode, setGMBCTAActionType, setGMBCTAActionURL, setGMBEventSchedule, setGMBEventSummary, setGMBEventTitle, setGMBPostType, setGMBRedeemURL, setGMBTermsAndConditions, setIsFacebookPostCarousel, setManuallyEditedPlatforms, setMunchedPostTargetIds, setPinterestAltText, setPinterestDestinationLink, setPinterestTitle, setPinterestVideoThumbnail, setPostGroupIdentifier, setPostNow, setPostSpecificDataVisualRequest, setPublishIGImageAsStories, setPublishIGVideoAsReel, setPublishIGVideoAsStories, setRedditTitle, setRepeatOption, setRepeatOptionType, setSelectedPlatforms, setShouldNotifyYouTubeSubscribers, setStitchAllowTiktok, setTiktokAllowComments, setTiktokAllowDuet, setTiktokSelectedViewSetting, setTiktokVideoTitle, setTimeToPostContent, setTwitterMediaAltText, setYouTubeVideoComment, setYouTubeVideoPrivacy, setYouTubeVideoThumbnail, setYouTubeVideoTitle, setIsFacebookPostStory, setPublishIGImageAsNormalIGPost, setIsNormalFacebookPost, setPreviousPostObjectBeforeEdit } from "redux/slices/postslice";
import { store } from "redux/store";
import { prepareMediaArrangementsForPostEditOutFromPlatformSpecificData } from "./media";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { RoutePaths } from "constants";
import { PlatformIdentifier } from "constants";
import { munchTarget } from "components/app/social/feed/audience";
import { setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import StringUtils from "./string";
import { isVideo } from "./file_utils";
import { setPublishablePostMedia } from "redux/slices/post_media_slice";
import { platforms } from 'components/app/platforms';
import { setOverlayLoader } from "redux/slices/notification_slice";

let lastCallTime = 0;
let draftCommitHeartbeat = 10000;

export const commitDraft = (lastDraftId, currentDraftId, workSpace, draft) => {
    APIService.draftPostToWorkSpace(workSpace['_id'], draft, currentDraftId !== lastDraftId, currentDraftId, (response, error) => {
        localStorage.setItem("draftStatus", JSON.stringify({ draftInProgress: false, draftCompleted: true }));
        window.dispatchEvent(new Event("draftStatus"));
    });
}

export const saveDraftInTheBackground = (workSpace, draft) => {
    const currentTime = Date.now();
    const diff = currentTime - lastCallTime;
    if (diff < draftCommitHeartbeat) {
        return;
    }
    lastCallTime = currentTime;
    let lastDraftId = localStorage.getItem(`${workSpace["_id"]}_last_draft_id`);
    let currentDraftId = draft['draftId'];
    localStorage.setItem("draftStatus", JSON.stringify({ draftInProgress: true }));
    window.dispatchEvent(new Event("draftStatus"));
    localStorage.setItem(`${workSpace["_id"]}_last_draft_id`, currentDraftId);
    commitDraft(lastDraftId, currentDraftId, workSpace, draft);
}

export const dispatchHandler = (func) => {
    store.dispatch(func);
}

const constructRepeatOptionType = (option) => {
    if (option?.startsWith('monthly_on_the_')) {
        return "monthly_on_the_";
    }
    if (option?.startsWith('weekly_on_')) {
        return "weekly_on_";
    }
    if (option?.startsWith('annually_on_')) {
        return "annually_on_";
    }
    if (option?.startsWith('every_weekday_')) {
        return "every_weekday_";
    }
    return option;
}

const getWeekdayOrdinalPosition = (timeToPost) => {
    const today = new Date(timeToPost) ?? new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    const month = today.getMonth();
    const year = today.getFullYear();

    let occurrence = 0;

    for (let date = 1; date <= today.getDate(); date++) {
        const currentDate = new Date(year, month, date);
        if (currentDate.getDay() === dayOfWeek) {
            occurrence++;
        }
    }

    return occurrence;

}

export const rearrangeRepeatOptions = () => {
    const timeToPost = getTimeToPostContent(store.getState());
    const repeatOption = getRepeatOption(store.getState());
    const option = constructRepeatOptionType(repeatOption?.type);
    let options = {}
    if (option === "custom") {
        return;
    }
    if (option === "no_repeat") {
        options.type = "no_repeat"
        dispatchHandler(setRepeatOptionType(option));
        dispatchHandler(setRepeatOption(options))
    }
    if (option === "daily") {
        options.type = "daily"
        dispatchHandler(setRepeatOptionType(option));
        dispatchHandler(setRepeatOption(options))
    }
    if (option === "weekly_on_") {
        options.type = 'weekly_on_(weekday)'
        let data = {
            weekday: new Date(timeToPost).getDay() ?? new Date().getDay()
        };
        options.data = data
        dispatchHandler(setRepeatOptionType(option));
        dispatchHandler(setRepeatOption(options))
    }
    if (option === "monthly_on_the_") {
        options.type = 'monthly_on_the_(ordinal)_(weekday)'
        let data = {
            ordinal: getWeekdayOrdinalPosition(timeToPost),
            weekday: new Date(timeToPost).getDay()
        };
        options.data = data
        dispatchHandler(setRepeatOptionType(option));
        dispatchHandler(setRepeatOption(options))
    }
    if (option === "annually_on_") {
        options.type = 'annually_on_(month)_(date_of_month)'
        let data = {
            month: new Date(timeToPost).getMonth(),
            date_of_month: new Date(timeToPost).getDate()
        };
        options.data = data
        dispatchHandler(setRepeatOptionType(option));
        dispatchHandler(setRepeatOption(options))
    }
}

export const percentagePopulatingFunction = async (currentStep, totalSteps = 10, navigator = RoutePaths.POSTS, message = `Preparing post for editing mode, please wait...`) => {
    const stepRange = 6 * totalSteps;
    const minMilliseconds = (currentStep - 1) * stepRange;
    const maxMilliseconds = currentStep * stepRange;
    const randomMilliseconds = Math.floor(Math.random() * (maxMilliseconds - minMilliseconds + 1)) + minMilliseconds;
    const wait = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    };
    await wait(randomMilliseconds);

    // find current percentage (over 100);
    const currentPercentage = (currentStep / totalSteps) * 100;
    dispatchHandler(setOverlayLoader({
        message,
        percentage: currentPercentage,
        navigator,
        done: currentStep === totalSteps
    }))
};

export const preparePostToEdit = async (post, isUpdating = false) => {
    let message = `Preparing post, please wait...`;
    if (isUpdating) {
        dispatchHandler(setPostSpecificDataVisualRequest(null));
    }
    const munchedTargetIds = [...getMunchedPostTargetIds(store.getState())]
    const aboutRePublishingPost = getAboutRepublishingPost(store.getState());
    let data = post.data;
    // let updatablePostBody = data.body;
    dispatchHandler(setPublishablePostTextBody(data?.description || ""));
    let updatablePostMedia = data.mediaUrls;
    let targets = data.targets ?? [];
    let editableAdvancedOptions = data.advanced_options;
    // replicate media arrangements here...
    let _manuallyEditedPlatforms = data.manually_edited_platforms;
    dispatchHandler(setManuallyEditedPlatforms(_manuallyEditedPlatforms || {}));
    await percentagePopulatingFunction(1, 10, RoutePaths.POSTS, message);
    let platformSpecificData = data.platformSpecificData;
    const platformArrangementsData = await prepareMediaArrangementsForPostEditOutFromPlatformSpecificData(platformSpecificData);
    dispatchHandler(setMediaArrangement(platformArrangementsData));
    dispatchHandler(setIsFacebookPostCarousel(data?.isFacebookPostCarousel ?? false));
    await percentagePopulatingFunction(2, 10, RoutePaths.POSTS, message);
    if (targets.length > 0) {
        let cachedPlatforms = localStorage.getItem("cached_platforms");
        if (cachedPlatforms) {
            cachedPlatforms = JSON.parse(cachedPlatforms);
        }
        for (let target of targets) {
            let existingPlatform;
            if (target.selected) {
                if (cachedPlatforms) {
                    existingPlatform = cachedPlatforms.find(
                        (x) => x.identifier === target.identifier
                    );
                    if (existingPlatform) {
                        let indexOfExistingPlatform =
                            cachedPlatforms.indexOf(existingPlatform);
                        existingPlatform.selected = true;
                        cachedPlatforms[indexOfExistingPlatform] = existingPlatform;
                    }
                }
            }
            for (let account of target.accounts) {
                let accountMunch = munchTarget(account._id, account.identifier);
                if (account.connected_pages_and_groups) {
                    for (let page of account.connected_pages_and_groups) {
                        let pageMunch = munchTarget(page.id, page.target);
                        if (page.selected) {
                            if (!munchedTargetIds.includes(pageMunch)) {
                                munchedTargetIds.push(pageMunch);
                            }
                        }
                    }
                    let areAllPagesSelected =
                        account.connected_pages_and_groups.every((x) => x.selected);
                    if (areAllPagesSelected) {
                        if (!munchedTargetIds.includes(accountMunch)) {
                            munchedTargetIds.push(accountMunch);
                        }
                    }
                } else {
                    if (account.selected) {
                        if (!munchedTargetIds.includes(accountMunch)) {
                            munchedTargetIds.push(accountMunch);
                        }
                    }
                }
                if (existingPlatform) {
                    let domiciledAccount = existingPlatform.accounts.find(
                        (y) => y._id === account._id
                    );
                    if (domiciledAccount) {
                        let indexOfDomiciledAccount =
                            existingPlatform.accounts.indexOf(domiciledAccount);
                        existingPlatform.accounts[indexOfDomiciledAccount] =
                            account;
                    }
                }
            }
        }
        await percentagePopulatingFunction(3, 10, RoutePaths.POSTS, message);
        dispatchHandler(setMunchedPostTargetIds([...munchedTargetIds]));
        dispatchHandler(setSelectedPlatforms(targets));
        if (cachedPlatforms) {
            localStorage.setItem(
                "cached_platforms",
                JSON.stringify(cachedPlatforms)
            );
        }
    }
    await percentagePopulatingFunction(4, 10, RoutePaths.POSTS, message);

    let poll = data.poll_data;
    dispatchHandler(setPostNow(false));
    const now = new Date();
    let updatableTimeToPostContent = aboutRePublishingPost
        ? new Date(now.getTime() + 60 * 60 * 1000)
        : new Date(
            post.scheduled_for ??
            post?.data?.time_to_post ??
            new Date()
        );
    if (editableAdvancedOptions) {
        dispatchHandler(setAdvancedContentCreationOptions(editableAdvancedOptions));
    }
    if (data?.repeat_option) {
        dispatchHandler(setRepeatOption(data?.repeat_option))
        dispatchHandler(setRepeatOptionType(constructRepeatOptionType(data?.repeat_option?.type)))
    }
    dispatchHandler(
        setPublishIGImageAsStories(
            data?.publishIGImageAsStories || false
        )
    );
    dispatchHandler(
        setIsNormalFacebookPost(data?.isNormalFacebookPost || false)
    );
    dispatchHandler(
        setIsFacebookPostStory(data?.isFacebookPostStory || false)
    );
    dispatchHandler(
        setPublishIGImageAsNormalIGPost(data?.publishIGImageToFeed || data?.publishImageToFeed || false)
    );
    dispatchHandler(
        setPublishIGVideoAsStories(data?.publishIGVideoAsStories || false)
    );
    dispatchHandler(
        setPublishIGVideoAsReel(
            data?.publishIGVideoAsReel ?? false
            // data?.publishIGVideoAsReel === "Yes" ? true : false
        )
    );

    dispatchHandler(setTimeToPostContent(updatableTimeToPostContent));
    if (data.post_group_identifier) {
        !aboutRePublishingPost &&
            dispatchHandler(setPostGroupIdentifier(data.post_group_identifier));
    }
    await percentagePopulatingFunction(5, 10, RoutePaths.POSTS, message);
    if (poll) {
        // setOpenPollCreationDialog(true);
        return;
    }
    if (data?.tiktokTitle) {
        dispatchHandler(setTiktokVideoTitle(data?.tiktokTitle ?? ""));
        dispatchHandler(setTiktokSelectedViewSetting(data?.tiktokViewSetting));
        dispatchHandler(
            setTiktokAllowComments(
                data?.tiktokAllowComment === "Yes" ? true : false
            )
        );
        dispatchHandler(
            setTiktokAllowDuet(data?.tiktokAllowDuet === "Yes" ? true : false)
        );
        dispatchHandler(
            setStitchAllowTiktok(data?.tiktokAllowStitch === "Yes" ? true : false)
        );
    }
    if (data.youTubeVideoTitle) {
        // setYoutubeDetected(true);
        dispatchHandler(setYouTubeVideoTitle(data.youTubeVideoTitle));
        dispatchHandler(setYouTubeVideoPrivacy(data.youTubeVideoPrivacy));
        dispatchHandler(setYouTubeVideoThumbnail(data.youTubeVideoThumbnail));
        dispatchHandler(
            setShouldNotifyYouTubeSubscribers(data.notifyYouTubeSubscribers)
        );
        dispatchHandler(setYouTubeVideoComment(data.youTubeVideoComment));
    }
    if (data.reddit_title) {
        // setRedditDetected(true);
        dispatchHandler(setRedditTitle(data.reddit_title));
    }
    if (data.pinterestTitle) {
        // setPinterestDetected(true);
        dispatchHandler(setPinterestTitle(data.pinterestTitle));
        dispatchHandler(setPinterestAltText(data.pinterestAltText));
        dispatchHandler(setPinterestDestinationLink(data.pinterestDestinationLink));
        dispatchHandler(setPinterestVideoThumbnail(data.pinterestVideoThumbnail));
    }
    if (data.twitterMediaAltText) {
        dispatchHandler(setTwitterMediaAltText(data.twitterMediaAltText));
    }
    let gmb = targets.find(
        (x) => x.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS
    );
    await percentagePopulatingFunction(7, 10, RoutePaths.POSTS, message);
    if (gmb && data.gmb_data) {
        let gmbDataOpts = data.gmb_data;
        // setGMBDetected(true);
        dispatchHandler(setGMBPostType(gmbDataOpts.gmbPostType));
        dispatchHandler(setGMBEventTitle(gmbDataOpts.gmbEventTitle));
        dispatchHandler(setGMBEventSchedule(gmbDataOpts.gmbEventSchedule));
        dispatchHandler(setGMBEventSummary(gmbDataOpts.gmbSummary));
        dispatchHandler(setGMBCTAActionType(gmbDataOpts.gmbCTAActionType));
        dispatchHandler(setGMBCTAActionURL(gmbDataOpts.gmbCTAActionUrl));
        dispatchHandler(setGMBCouponCode(gmbDataOpts.gmbCouponCode));
        dispatchHandler(setGMBRedeemURL(gmbDataOpts.gmbCouponRedeemURL));
        dispatchHandler(setGMBTermsAndConditions(gmbDataOpts.gmbTermsAndConditions));
    }



    await percentagePopulatingFunction(8, 10, RoutePaths.POSTS, message);

    if (updatablePostMedia) {
        let updatablePostMediaPromise = updatablePostMedia.map((mediaEntry) => {
            let url;
            if (typeof mediaEntry === "string") {
                url = mediaEntry;
                return {
                    name: url,
                    url,
                    uploaded: true,
                    extension: `.${StringUtils.substringAfterLast(
                        StringUtils.substringBeforeLast(url, "?"),
                        "."
                    )}`,
                    is_video: isVideo(url),
                };
            } else {
                return mediaEntry;
            }
        });
        updatablePostMedia = await Promise.all(updatablePostMediaPromise);
        dispatchHandler(setPublishablePostMedia([...updatablePostMedia]));
        dispatchHandler(setPreviousPostObjectBeforeEdit(post?.data));
        await percentagePopulatingFunction(9, 10, RoutePaths.POSTS, message);
    }
    // if (textAreaRef.current) {
    //     textAreaRef.current.focus();
    // }
    dispatchHandler(setCanExpandPublisherActions(true));
    let editCount = data?.edited ?? 0;
    if (!aboutRePublishingPost) {
        dispatchHandler(setEditedPostFlag({
            edited: true,
            count: editCount
        }))
    }
    localStorage.setItem("updatable_post_defaults_set", true);
    await percentagePopulatingFunction(10, 10, RoutePaths.POSTS, message);

}

export const prepareSpecificPlatformPostVisuals = async (platform, post) => {
    await percentagePopulatingFunction(1, 5, false, `Preparing visuals for ${platform} platform, please wait...`);
    const postSpecificDataVisualRequest = getPostSpecificDataVisualRequest(store.getState());
    const manuallyEditedPlatforms = getManuallyEditedPlatforms(store.getState())
    let mediaArrangements = { ...getMediaArrangement(store.getState()) };
    if (!mediaArrangements) {
        mediaArrangements = {};
    }
    let platformSpecificData = post?.data?.platformSpecificData;
    const platformArrangementsData = await prepareMediaArrangementsForPostEditOutFromPlatformSpecificData(platformSpecificData);
    let platformData = platformArrangementsData[platform];

    await percentagePopulatingFunction(2, 5, false, `Environment set for ${platform} platform, please wait...`);
    await percentagePopulatingFunction(4, 5, false, `Visuals for ${platform} platform is ready, opening...`);
    let newMediaArrangement = {
        ...platformArrangementsData,
        [platform]: platformData
    }
    dispatchHandler(setMediaArrangement(newMediaArrangement));
    await percentagePopulatingFunction(5, 5, false, `Visuals for ${platform} platform is ready, opening...`);
    const platformFound = platforms?.find(x => x?.identifier === platform);
    let dataPreparation = {
        oldMediaArrangements: mediaArrangements[platform],
        newMediaArrangements: platformArrangementsData,
        oldManuallyEditedPlatforms: manuallyEditedPlatforms,
        platform,
        platformTemplate: platformFound,
        post,
        newTargetData: platformData,
        others: {}
    }
    dispatchHandler(setPostSpecificDataVisualRequest(dataPreparation));
}

export const abolishSpecificPlatformPostVisuals = async () => {
    const postSpecificDataVisualRequest = getPostSpecificDataVisualRequest(store.getState());
    dispatchHandler(setPostSpecificDataVisualRequest(null));
    const {
        oldMediaArrangements,
        oldManuallyEditedPlatforms,
    } = postSpecificDataVisualRequest;
    dispatchHandler(setMediaArrangement(oldMediaArrangements));
    dispatchHandler(setManuallyEditedPlatforms(oldManuallyEditedPlatforms));
}

export const checkIfPostWasPublishedWithError = (post) => {
    // check if post is published to some platforms but failed on some
    if (!post?.posted && !post?.data?.successfully_published_to) {
        return false;
    }
    let failedTargets = post?.data?.failed_targets;
    let postFailedStatus = post?.failed;

    // check if failed targets are not empty and is not empty object;
    if ((failedTargets && Object.keys(failedTargets).length > 0) || postFailedStatus) {
        return true;
    }
}

const compareObjects = (original, edited, changes = {}, path = '', visited = new WeakSet(), exclusions = []) => {
    if (visited.has(original) || visited.has(edited)) {
        return changes;  // Circular reference detected, skip comparison.
    }

    visited.add(original);
    visited.add(edited);

    const originalKeys = Object.keys(original || {});
    const editedKeys = Object.keys(edited || {});
    const allKeys = new Set([...originalKeys, ...editedKeys]);

    allKeys.forEach(key => {
        const fullPath = path ? `${path}.${key}` : key;

        // Check if the current path is in the exclusions list
        if (exclusions.some(exclusion => fullPath.startsWith(exclusion))) {
            return; // Skip this path and move to the next key
        }

        if (!(key in original)) {
            changes[fullPath] = { status: 'added', value: edited[key] };
        } else if (!(key in edited)) {
            changes[fullPath] = { status: 'removed', value: original[key] };
        } else if (typeof original[key] === 'object' && typeof edited[key] === 'object') {
            // Both are objects or arrays, recurse.
            compareObjects(original[key], edited[key], changes, fullPath, visited, exclusions);
        } else if (original[key] !== edited[key]) {
            changes[fullPath] = { status: 'modified', original: original[key], edited: edited[key] };
        }
    });

    return changes;
}



export const compareObjectsAsync = (obj1, obj2) => {
    const exclusions = ['createdBy', 'createdBy.avatar', 'updatable_post_id', 'created_by', 'edited', 'audit_trails'];
    return new Promise((resolve) => {
        const result = compareObjects(obj1, obj2, {}, '', new WeakSet(), exclusions);
        resolve(result);
    });
};