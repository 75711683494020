import { RoutePaths } from "../constants";
import {
  faListOl,
  faRss,
} from "@fortawesome/free-solid-svg-icons";

export const publisherRoutes = {
  label: "Publish",
  labelDisable: true,
  children: [
    {
      name: "Create a post ",
      icon: "list",
      // to: RoutePaths.POSTS,
      // iconifyName: false,
      active: true,
      children: [
        {
          name: "Create a post",
          to: RoutePaths.POSTS,
          exact: true,
          active: true,
          identifier: 'create-a-post'
        },
        {
          name: "Create from AI",
          to: RoutePaths.AI_CHAT,
          exact: true,
          active: true,
          // premium: true,
        },
        {
          name: "Create bulk post",
          to: RoutePaths.BULK_POSTS,
          exact: true,
          active: true,
          premium: true,
        },
        {
          name: "Create a poll",
          to: RoutePaths.SCHEDULE_A_POLL,
          exact: true,
          active: true,
        },
      ],
    },
    {
      name: "Manage posts",
      icon: "bars-staggered",
      // to: RoutePaths.POSTS,
      // iconifyName: false,
      active: true,
      children: [
        {
          name: "Draft posts",
          to: RoutePaths.DRAFTS_POSTS,
          exact: true,
          active: true,
        },
        {
          name: "Pending approval",
          to: RoutePaths.PENDING_APPROVAL,
          exact: true,
          active: true,
        },
        {
          name: "Published posts",
          to: RoutePaths.PUBLISHED_POSTS,
          exact: true,
          active: true,
        },
        {
          name: "Recurring posts",
          to: RoutePaths.RECURRING_POSTS,
          exact: true,
          active: true,
        },
        {
          name: "Scheduled posts",
          to: RoutePaths.SCHEDULED_POSTS,
          exact: true,
          active: true,
        },
        {
          name: "Failed posts",
          to: RoutePaths.FAILED_POSTS,
          exact: true,
          active: true,
        },
      ],
    },
    {
      name: "Media library",
      icon: "file-archive",
      // to: RoutePaths.POSTS,
      // iconifyName: false,
      // visibleToOnlyDemoAccount: true,
      active: true,
      children: [
        {
          isCustomDomain: true,
          name: "Cloud",
          to: RoutePaths.POSTLY_CLOUD,
          exact: true,
          active: true,
        },
        {
          name: "Pexels",
          to: RoutePaths.PEXELS_CONTENTS,
          exact: true,
          active: true,
        },
        {
          name: "Shutter stock",
          to: RoutePaths.SHUTTERSTOCK_CONTENTS,
          exact: true,
          active: true,
        },
        {
          name: "Google drive",
          to: RoutePaths.GDRIVE_CONTENTS,
          exact: true,
          active: true,
          premium: true,
        },
      ],
    },
    {
      name: "Content planner",
      icon: "calendar-alt",
      to: RoutePaths.PLANNER,
      active: true,
      children: [
        {
          name: "Content calendar",
          to: RoutePaths.PLANNER,
          exact: true,
          active: true,
        },
        {
          name: "Events calendar",
          to: RoutePaths.EVENT_PLANNER,
          exact: true,
          active: true,
        },
      ],
    },
    // {
    //   name: "Content planner",
    //   icon: "calendar-alt",
    //   to: RoutePaths.PLANNER,
    //   active: true,
    // },
    {
      name: "Automations",
      icon: "robot",
      active: true,
      children: [
        {
          name: "RSS feed",
          // icon: faRss,
          to: RoutePaths.RSS_FEED,
          active: true,
          premium: true,
        },

      ],
    },
  ],
};

// export const mediaLibrariesRoutes = {
//   label: 'Media Libraries',
//   children: [
//     {
//       name: 'Pexels',
//       icon: 'cubes',
//       to: RoutePaths.PEXELs_CONTENTS,
//       active: true
//     },
//   ]
// };
export const settingsRoutes = {
  label: "Settings",
  children: [
    {
      name: "Platforms",
      icon: "cubes",
      to: RoutePaths.PLATFORMS,
      active: true,
      identifier: 'platforms'
    },
    {
      name: "Team",
      icon: "users",
      to: RoutePaths.TEAM,
      active: true,
      identifier: 'team'
    },
    {
      name: "Workspace",
      icon: "cog",
      to: RoutePaths.WORKSPACE_SETTINGS,
      active: true,
    },
  ],
};

export const aiRoutes = {
  label: " AI tools",
  children: [
    {
      name: " AI",
      active: true,
      icon: "robot",
      identifier: 'postly-ai',
      children: [
        {
          name: "Settings",
          icon: "home",
          to: RoutePaths.AI_DASHBOARD,
          active: true,
        },
        {
          name: "Chat",
          icon: "comments",
          to: RoutePaths.AI_CHAT,
          active: true,
        },
        // {
        //   name: "Language Translator",
        //   icon: "earth-americas",
        //   to: RoutePaths.AI_LANGUAGE_TRANSLATOR,
        //   active: true,
        // },
        {
          name: "AI writer",
          icon: "edit",
          // to: RoutePaths.AI_WRITER,
          active: true,
          children: [
            {
              name: "Product description",
              to: RoutePaths.AI_WRITER_PRODUCTION_DESCRIPTION,
              exact: true,
              // premium: true,
              active: true,
            },
            {
              name: "Post captions",
              to: RoutePaths.AI_WRITER_POST_CAPTIONS,
              exact: true,
              active: true,
            },
            {
              name: "Post ideas",
              to: RoutePaths.AI_WRITER_POST_IDEAS,
              exact: true,
              active: true,
            },
            {
              name: "Instagram captions",
              to: RoutePaths.AI_WRITER_SOCIAL_MEDIA_INSTAGRAM_CAPTIONS,
              exact: true,
              active: true,
            },
            {
              name: "Post call to action",
              to: RoutePaths.AI_WRITER_POST_CALL_TO_ACTION,
              exact: true,
              active: true,
            },
            {
              name: "Video ideas",
              to: RoutePaths.AI_WRITER_VIDEO_IDEAS,
              exact: true,
              active: true,
            },
            {
              name: "Video captions",
              to: RoutePaths.AI_WRITER_VIDEO_CAPTIONS,
              exact: true,
              active: true,
            },
            {
              name: "Video call to action",
              to: RoutePaths.AI_WRITER_VIDEO_CALL_TO_ACTION,
              exact: true,
              active: true,
            },

            // {
            //   name: 'Content Planner',
            //   icon: 'calendar-alt',
            //   to: RoutePaths.PLANNER,
            //   active: true
            // }
          ],
        },
        {
          name: "AI image",
          icon: "image",
          // to: RoutePaths.AI_WRITER,
          active: true,
          children: [
            {
              // visibleToOnlyDemoAccount: true,
              name: "AI art",
              icon: "image",
              to: RoutePaths.AI_ART,
              active: true,
              badge: {
                text: "new",
                type: "success",
              },
            },
            {
              name: "Image editor",
              icon: "droplet",
              to: RoutePaths.IMAGE_EDITOR,
              active: true,
            },
          ],
        },
        {
          name: "Blog",
          icon: "file",
          active: true,
          children: [
            {
              name: "Blog ideas",
              to: RoutePaths.AI_WRITER_BLOG_IDEAS,
              exact: true,
              active: true,
            },
            {
              name: "Blog captions",
              to: RoutePaths.AI_WRITER_BLOG_CAPTIONS,
              exact: true,
              active: true,
            },
            {
              name: "Blog section",
              to: RoutePaths.AI_WRITER_BLOG_SECTION,
              exact: true,
              active: true,
            },
            {
              name: "Blog title",
              to: RoutePaths.AI_WRITER_SEO_META_TITLE,
              exact: true,
              active: true,
            },
            {
              name: "SEO meta description",
              to: RoutePaths.AI_WRITER_SEO_META_DESCRIPTION,
              exact: true,
              active: true,
            },
          ],
        },
        {
          name: "Digital Ad",
          icon: faListOl,
          active: true,
          children: [
            {
              name: "Google ads",
              to: RoutePaths.AI_WRITER_GOOGLE_ADS,
              exact: true,
              active: true,
            },
            {
              name: "Facebook ads",
              to: RoutePaths.AI_WRITER_FACEBOOK_ADS,
              exact: true,
              active: true,
            },
            {
              name: "LinkedIn ads",
              to: RoutePaths.AI_WRITER_LINKEDIN_AD,
              exact: true,
              active: true,
            },
            {
              name: "Twitter ads",
              to: RoutePaths.AI_WRITER_TWITTER_AD,
              exact: true,
              active: true,
            },
            {
              name: "Instagram ads",
              to: RoutePaths.AI_WRITER_INSTAGRAM_AD,
              exact: true,
              active: true,
            },
          ],
        },
        // {
        //   name: "Sales",
        //   icon: "dollar-sign",
        //   active: true,
        //   children: [
        //     {
        //       name: "Attention-Interest-Desire-Action",
        //       to: RoutePaths.AI_WRITER_AttentionInterestDesireAction,
        //       exact: true,
        //       active: true,
        //     },
        //     {
        //       name: "Pain-Agitate-Solution",
        //       to: RoutePaths.AI_WRITER_PainAgitateSolution,
        //       exact: true,
        //       active: true,
        //     },
        //     {
        //       name: "Feature-To-Benefits",
        //       to: RoutePaths.AI_WRITER_FeatureToBenefits,
        //       exact: true,
        //       active: true,
        //     },
        //     {
        //       name: "Feature-Advantage-Benefits",
        //       to: RoutePaths.AI_WRITER_FeatureAdvantageBenefits,
        //       exact: true,
        //       active: true,
        //     },
        //   ],
        // },
        // {
        //   name: "Hashtag",
        //   icon: "tag",
        //   active: true,
        //   children: [
        //     {
        //       name: "Hashtag generator",
        //       to: RoutePaths.AI_WRITER_HASHTAG_GENERATOR,
        //       exact: true,
        //       active: true,
        //     },
        //     // {
        //     //   name: 'Hashtag Library',
        //     //   to: RoutePaths.PUBLISHED_POSTS,
        //     //   exact: true,
        //     //   active: true
        //     // },
        //   ],
        // },
      ],
    },
  ],
};
// export const socialInboxRoutes = {
//   label: 'Social inbox',
//   labelDisable: false,
//   visibleToOnlyDemoAccount: true,
//   children: [
//     {
//       name: 'Direct messages',
//       to: RoutePaths.INBOX,
//       icon: 'comments',
//       exact: true,
//       active: true
//     },
//     {
//       name: 'Comments',
//       to: RoutePaths.POSTS,
//       icon: 'droplet',
//       exact: true,
//       active: true
//     },
//     {
//       name: 'Social listening',
//       icon: 'calendar-alt',
//       to: RoutePaths.PLANNER,
//       active: true
//     },
//     {
//       name: 'Mentions',
//       icon: 'calendar-alt',
//       to: RoutePaths.PLANNER,
//       active: true
//     },
//     {
//       name: 'Reviews',
//       icon: 'calendar-alt',
//       to: RoutePaths.PLANNER,
//       active: true
//     }
//   ]
// }
export const toolsRoute = {
  label: "tools",
  children: [
    // {
    //   // visibleToOnlyDemoAccount: false,
    //   name: "Social inbox",
    //   icon: "comments",
    //   active: true,
    //   badge: {
    //     text: "new",
    //     type: "success",
    //   },
    //   children: [
    //     {
    //       name: "Direct messages",
    //       // icon: "envelope",
    //       to: RoutePaths.INBOX_MESSAGES,
    //       exact: true,
    //       active: true,
    //     },
    //     // {
    //     //   name: "Direct messages",
    //     //   icon: "envelope",

    //     //   to: RoutePaths.INBOX,
    //     //   exact: true,
    //     //   active: true,
    //     // },
    //     // {
    //     //   name: "Instagram DM",
    //     //   // icon: "envelope",

    //     //   to: RoutePaths.INSTAGRAM,
    //     //   exact: true,
    //     //   active: true,
    //     // },
    //   ],
    // },
    {
      // visibleToOnlyDemoAccount: true,
      name: "Analytics",
      icon: "chart-pie",
      active: true,
      // badge: {
      //   text: 'new',
      //   type: 'success'
      // },
      // premium: true,
      children: [
        {
          name: "Facebook",
          to: RoutePaths.FACEBOOK_ANALYTICS,
          exact: true,
          active: true,
        },
        {
          name: "LinkedIn",
          to: RoutePaths.LINKEDIN_ANALYTICS,
          exact: true,
          active: true,
        },
        {
          name: "Instagram",
          to: RoutePaths.INSTAGRAM_ANALYTICS,
          exact: true,
          active: true,
        },
        {
          name: "YouTube",
          to: RoutePaths.YOUTUBE_ANALYTICS,
          exact: true,
          active: true,
        },
      ],
    },
    {
      // visibleToOnlyDemoAccount: true,
      name: "Link tools",
      icon: "link",
      active: true,
      children: [
        {
          name: "Link shortener",
          icon: "",
          active: true,
          children: [
            {
              name: "Shorten link",
              to: RoutePaths.LINK_SHORTENER,
              active: true,
              premium: true,
            },
            {
              name: "Integration",
              to: RoutePaths.INTEGRATION,
              active: true,
              premium: true,
            },
            {
              name: "Custom domain",
              to: RoutePaths.CUSTOM_DOMAIN,
              active: true,
              premium: true,
            },
            {
              name: "QR Code",
              to: RoutePaths.QR_CODE,
              active: true,
              premium: true,
            },
          ],
        },
        {
          name: "Campaign URL builder",
          to: RoutePaths.CAMPAIGN_BUILDER,
          active: true,
        },
        // {
        //   name: "Google LinkPro",
        //   to: RoutePaths.REVIEW_LINK_GENERATOR,
        //   active: true,
        // },
      ],
    },
  ],
};

export const accountRoutes = {
  label: "Manage my account ",
  children: [
    {
      name: "My profile",
      icon: "user",
      to: RoutePaths.ACCOUNT,
      active: true,
    },

    // {
    //   name: "My plans & billing",
    //   to: RoutePaths.ALL_BILLING,
    //   exact: true,
    //   active: true,
    //   icon: "dollar",
    //   identifier: 'billing'
    // },
    {
      // visibleToOnlyDemoAccount: true,
      isCustomDomain: true,
      name: "My plans & billing",
      to: RoutePaths.BILLING_STRIPE,
      exact: true,
      active: true,
      icon: "dollar",
      identifier: 'billing'
    },
    {
      isCustomDomain: true,
      name: "My vouchers",
      to: RoutePaths.REDEEM_CODES,
      exact: true,
      active: true,
      icon: "gift",
    },
    {
      isCustomDomain: true,
      name: "My invoices",
      to: RoutePaths.INVOICE_HISTORY,
      exact: true,
      active: true,
      icon: "file",
    },
    {
      isCustomDomain: true,
      name: 'My white label',
      icon: 'palette',
      to: RoutePaths.WHITELABEL_SETTINGS,
      active: true,
      // badge: {
      //   text: "beta",
      //   type: "warning",
      // },
    }
  ],
};

export const adminRoutes = {
  label: "Administration",
  visibleToOnlyDemoAccount: true,
  children: [
    {
      name: "Metrics",
      to: RoutePaths.METRICS,
      exact: true,
      active: true,
      // identifier: 'create-a-post'
    },
  ],
};

export const resourceRoutes = {
  label: "resources",
  children: [
    // {
    //   name: 'Deals',
    //   icon: 'tag',
    //   to: '/deals',
    //   active: true,
    // },
    {
      name: "Help center",
      icon: "question-circle",
      to: "/help",
      active: true,
    },
  ],
};

export default [
  publisherRoutes,
  settingsRoutes,
  aiRoutes,
  toolsRoute,
  accountRoutes,
  adminRoutes,
  // resourceRoutes,
];
