import FalconCardHeader from "components/common/FalconCardHeader";
import React, { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import {
  getTimeZoneToPostContent,
  setTimeZoneToPostContent,
} from "redux/slices/postslice";
import { useDispatch, useSelector } from "react-redux";
import TimeZoneSelector from "components/common/TimeZoneSelector";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import Divider from "components/common/Divider";

const TimeZoneSettings = () => {
  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const selectedTimezone = useSelector(getTimeZoneToPostContent);

  const [savingChanges, setSavingChanges] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);

  useEffect(() => {
    if (workSpace) {
      if (workSpace?.default_time_zone_for_publishing) {
        dispatch(
          setTimeZoneToPostContent(
            workSpace?.default_time_zone_for_publishing?.value
          )
        );
      }
    }
  }, []);

  const applyToAllWorkspaceSettingsHandler = () => {
    setSavingChanges(true);
    setChangesSaved(false);
    APIService.applyToAllWorkspaces(
      workSpace?._id,
      {selectedTimezone: workSpace.default_time_zone_for_publishing},
      "selected_default_timezone_settings",
      async (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          setSavingChanges(false);
          return;
        }
        const { message, data } = response;
        APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
          if (error) {
            return;
          }
          if (response) {
            let responseData = response["data"];
            let workSpace = responseData["workspace"];
            workSpace["role"] = responseData["role"];
            workSpace["value"] = workSpace["name"];
            workSpace["title"] = responseData["title"];
            workSpace["label"] = workSpace["name"];
            workSpace["workspace_owner"] = responseData["workspace_owner"];
            dispatch(setActiveWorkspace({ ...workSpace }));
            setSavingChanges(false);
            setChangesSaved(true);
            toast.success(message, { theme: "colored" });
          }
        });
      }
    );
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={<h5>Time zone</h5>}
        endEl={
          <>
            {savingChanges && (
              <Flex className={"gap-2"}>
                <Spinner animation="border" size={"sm"} variant={"primary"} />
                <p
                  style={{
                    fontSize: 12,
                    color: "#B0BEC5",
                  }}
                >
                  Saving changes...
                </p>
              </Flex>
            )}
            {changesSaved && (
              <p
                style={{
                  fontSize: 12,
                  color: "#9CCC65",
                }}
              >
                Changes Saved
              </p>
            )}
          </>
        }
      />
      <Card.Body className="bg-light">
        <Flex direction={"column"}>
          <span>Primary time zone</span>
          <TimeZoneSelector
            defaultTimeZone={selectedTimezone}
            disabled={savingChanges}
            onChange={(result) => {
              dispatch(setTimeZoneToPostContent(result));
              setSavingChanges(true);
              setChangesSaved(false);
              APIService.setDefaultTimeZoneForPublishing(
                workSpace._id,
                result,
                (response, error) => {
                  if (error) {
                    toast.error(error, { theme: "colored" });
                    setSavingChanges(false);
                    return;
                  }
                  let { message, data } = response;
                  toast.success(message, { theme: "colored" });
                  dispatch(
                    setActiveWorkspace({
                      ...workSpace,
                      default_time_zone_for_publishing:
                        data.workspace.default_time_zone_for_publishing,
                    })
                  );
                  setSavingChanges(false);
                  setChangesSaved(true);
                }
              );
            }}
          />
        </Flex>
        <Divider />
        <Flex
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Apply this to all workspaces</p>
          <Button
            onClick={applyToAllWorkspaceSettingsHandler}
            disabled={savingChanges}
          >
            {"Apply"}
          </Button>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default TimeZoneSettings;
