import SubmitButton from "components/common/SubmitButton";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import WorkspaceLogoPicker from "./workspace_logo_picker";
import Flex from "components/common/Flex";
import StringUtils from "utils/string";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useState } from "react";
import useOrganizationStore from "state/organization_store";

export default function NewWorkspaceInOrganization({ attributes = {}, handleOnClose }) {

    const { t } = useTranslation();
    const workspaces = useOrganizationStore(state => state.allWorkspaces);
    const workspace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const setWorkspaceForOrganizationBeingCreated = useOrganizationStore(state => state.setWorkspaceForOrganizationBeingCreated);
    const [form, setForm] = useState({ ...(workspace ?? {}) });

    const authenticatedUser = useSelector(getLoggedInUser);
    const [openWorkspaceCreationPanel, setOpenWorkspaceCreationPanel] = useState(workspace);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.entries(form).length === 0) {
            return;
        }
        form.owner = authenticatedUser._id;
        form._id = StringUtils.generateRandomString();
        form.attributes = attributes;
        form.avatar = workspace?.avatar
        setWorkspaceForOrganizationBeingCreated(form);
        if (handleOnClose) {
            handleOnClose();
        }
    }

    const workspaceCreationPrompt = () => {
        if ((workspaces ?? []).length === 0) {
            return `${t('you_have_no_workspace_set_up', { organization_name: `<b>${organization?.name}</b>` })}`;
        }
        let organizationWorkspaces = (workspaces ?? []).filter(w => w.organization?._id === organization?._id);
        if ((organizationWorkspaces ?? []).length === 0) {
            return `${t('you_have_no_workspace_set_up', { organization_name: `<b>${organization?.name}</b>` })}`;
        }
        return `${t('create_workspace_within_org', { organization_name: `<b>${organization?.name}</b>` })}`;
    }

    return (
        <>
            {
                !openWorkspaceCreationPanel &&
                <Flex direction={'column'} className={'mb-2'}>
                    <span
                        className="text-center py-3"
                        dangerouslySetInnerHTML={{
                            __html: workspaceCreationPrompt()
                        }}
                    />
                    <Button
                        onClick={() => setOpenWorkspaceCreationPanel(true)}
                        size="sm"
                        className="rounded-pill">{t('create')}
                    </Button>
                </Flex>
            }
            {
                openWorkspaceCreationPanel &&
                <Form onSubmit={handleSubmit}>
                    <Flex direction={'column'} className={'gap-3'}>
                        <Form.Group controlId="workspace_name">
                            <Form.Label className='text-capitalize'>{t('name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('enter_name_of_new_workspace')}
                                name="workspace_name"
                                value={form.workspace_name}
                                onChange={(e) => {
                                    let name = e.target.value;
                                    let wkSpace = { ...(workspace ?? {}) };
                                    wkSpace.name = name;
                                    setForm({
                                        ...form,
                                        workspace_name: name
                                    });
                                    setWorkspaceForOrganizationBeingCreated({ ...wkSpace, workspace_name: name });
                                }}
                            />
                        </Form.Group>
                        <WorkspaceLogoPicker />
                        <SubmitButton className={'rounded-pill'} label={t('next')} processing={false} />
                    </Flex>
                </Form>
            }
        </>
    );
}