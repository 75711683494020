import {
    GearSix, Plus,
    MagnifyingGlass, CheckCircle
} from '@phosphor-icons/react';
import { BuildingOffice } from '@phosphor-icons/react/dist/ssr';
import Flex from 'components/common/Flex';
import { RoutePaths } from '../../../../constants';
import React, { useContext, useState } from 'react';
import { Dropdown, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import './no_chevron.css';
import { getActiveWorkSpace, setActiveWorkspace, switchingWorkspace } from 'redux/slices/workspaceslice';
import useOrganizationStore, { defaultOrganization } from 'state/organization_store';
import { getLoggedInUser } from 'redux/slices/user_slice';
import useWorkspaceSwitcher from '../hooks/useWorkspaceSwitcher';
import { toast } from "react-toastify";
import OrganizationSettingsPanel from './settings/organization_settings_panel';
import AppContext from 'context/Context';

const ActiveOrganizationsDropdown = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { config: { isDark } } = useContext(AppContext);
    const organizationsFromStore = useOrganizationStore(state => state.organizationList);

    const organizationsMap = new Map();
    organizationsMap.set(defaultOrganization._id, defaultOrganization);
    organizationsFromStore.forEach(org => organizationsMap.set(org._id, org));
    const organizations = Array.from(organizationsMap.values()).filter(org => !org.new);

    const activeWorkspace = useSelector(getActiveWorkSpace);
    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const activeOrganizationFromStore = useOrganizationStore(state => state.activeOrganization);
    const activeOrganization = (activeOrganizationFromStore ?? activeWorkspace?.organization ? activeWorkspace.organization : defaultOrganization) ?? defaultOrganization;

    const setActiveOrganization = useOrganizationStore(state => state.setActiveOrganization);
    const setWorkspacesManagedByActiveOrganization = useOrganizationStore(state => state.setWorkspacesManagedByActiveOrganization);

    const [searchTerm, setSearchTerm] = useState('');
    const [viewAll, setViewAll] = useState(false);

    const authenticatedUser = useSelector(getLoggedInUser);
    const switchWorkspace = useWorkspaceSwitcher(authenticatedUser);
    const workspaceSwitchHappening = useSelector(switchingWorkspace);

    const [openOrganizationSettingsPanel, setOpenOrganizationSettingsPanel] = useState(false);

    const handleSelect = (organizationId) => {
        if (organizationId === 'createNew') {
            navigate(RoutePaths.ORGS_AND_WORKSPACES);
            return;
        }
        const organization = organizations.find(org => org._id === organizationId);
        if (organization) {
            let workspaces;
            if (organization._id === defaultOrganization._id) {
                workspaces = allWorkspaces.filter(w => !w.organization);
            } else {
                workspaces = allWorkspaces.filter(w => w.organization?._id === organization?._id);
            }
            if (workspaces.length === 0) {
                toast.error(t('organization_has_no_more_workspaces_attached_to_it'), { theme: 'colored' });
                return;
            }
            setActiveOrganization({ ...organization });
            if (organization._id !== defaultOrganization._id) {
                dispatch(setActiveWorkspace({ ...workspaces[0], organization }));
                setWorkspacesManagedByActiveOrganization(workspaces);
                switchWorkspace(workspaces[0]._id);
            } else {
                dispatch(setActiveWorkspace({ ...activeWorkspace, organization }));
                setWorkspacesManagedByActiveOrganization(allWorkspaces);
            }
        }
    };

    const truncatedOrganizationName = () => {
        let name = activeOrganization?.name ?? "";
        let validLength = 11;
        if (name.length > validLength) {
            return name.substring(0, validLength).concat('...');
        }
        return name;
    };

    const filteredOrganizations = organizations.filter(org =>
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (activeOrganization) {
        filteredOrganizations.sort((a, b) => {
            if (a._id === activeOrganization._id) return -1;
            if (b._id === activeOrganization._id) return 1;
            return 0;
        });
    }

    const displayedOrganizations = viewAll ? filteredOrganizations : filteredOrganizations.slice(0, 5);

    const renderDropdownItem = (organization) => {
        const item = (
            <Dropdown.Item
                key={organization._id}
                eventKey={organization._id}
                className={`px-1 d-flex my-1 align-items-center ${organization._id === activeOrganization?._id ? 'text-primary' : ''}`}
            >
                {
                    organization.avatar ?
                        <img
                            className='rounded-circle'
                            src={organization?.avatar}
                            alt={`${organization?.name} logo`}
                            style={{ width: 22, height: 22, marginRight: '8px' }}
                        /> :
                        <BuildingOffice
                            weight='fill'
                            size={20}
                            style={{ marginRight: '8px' }}
                        />
                }
                <span className='flex-grow-1 fw-semi-bold'>{organization.name}</span>
                {
                    organization._id === activeOrganization?._id &&
                    <CheckCircle size={15} weight='fill' />
                }
            </Dropdown.Item>
        );
        if (activeOrganization?._id === defaultOrganization._id) {
            return (
                <Tooltip
                    key={organization._id}
                    title={<Tooltip id={`tooltip-${organization._id}`}>{t(organization.tooltip)}</Tooltip>}
                >
                    {item}
                </Tooltip>
            );
        }
        return item;
    }

    return (
        <Flex
            alignItems={'center'}
            className={'m-0 border border-300 rounded-pill'}>
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle disabled={workspaceSwitchHappening} className='shadow-none bg-transparent border-0' variant="falcon-default" id="dropdown-basic">
                    <>
                        {
                            activeOrganization?.avatar ?
                                <img
                                    className='rounded-circle'
                                    src={activeOrganization?.avatar}
                                    alt={`${activeOrganization?.name} logo`}
                                    style={{ width: 22, height: 22, marginRight: '8px' }}
                                /> :
                                <BuildingOffice
                                    weight='fill'
                                    size={18}
                                    style={{ marginRight: '8px' }}
                                />
                        }
                        <span>{truncatedOrganizationName()}</span>
                    </>
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-3' style={{ minWidth: '300px' }}>
                    <InputGroup className="my-2 w-100">
                        <FormControl
                            size='sm'
                            className='rounded-2'
                            autoFocus
                            placeholder={t('search_for_an_organization')}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                paddingLeft: 30
                            }}
                        />
                        <MagnifyingGlass
                            size={18}
                            className="position-absolute text-500"
                            style={{
                                left: '10px',
                                top: '50%',
                                zIndex: 100,
                                transform: 'translateY(-50%)',
                                pointerEvents: 'none'
                            }}
                        />
                    </InputGroup>
                    {displayedOrganizations.map((organization) => renderDropdownItem(organization))}
                    {filteredOrganizations.length > 5 && !viewAll && (
                        <Dropdown.Item
                            as={'span'}
                            className={'cursor-pointer'}
                            onClick={(e) => {
                                e.stopPropagation();
                                setViewAll(true);
                            }}>
                            {t('view_all_organizations')}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="createNew" className='px-1'>
                        <Plus className='me-2' size={18} />
                        {t('create_new_organization')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div className='bg-200 m-0 p-0' style={{ height: 35, width: 1 }} />
            <Dropdown>
                <Dropdown.Toggle
                    className='p-0 m-0 shadow-none bg-transparent border-0 no-chevron'
                    variant="falcon-default"
                    id="gear-dropdown-basic">
                    <GearSix
                        className='cursor-pointer'
                        size={18}
                        style={{
                            marginLeft: 9,
                            marginRight: 10
                        }}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu className='p-3 mt-2 px-2' style={{ minWidth: '200px' }}>
                    <Dropdown.Item
                        className='mx-0 px-2'
                        onClick={() => {
                            setOpenOrganizationSettingsPanel(true);
                        }}>
                        <GearSix className='m-0 me-2' size={18} />
                        {t('organization_settings')}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className='mx-0 px-2'
                        onClick={() => {
                            navigate(RoutePaths.ORGS_AND_WORKSPACES);
                        }}>
                        <Plus className='m-0 me-2' size={18} />
                        {t('create_a_new_organization')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Modal
                centered
                size='lg'
                show={openOrganizationSettingsPanel}
                onHide={() => setOpenOrganizationSettingsPanel(false)}
                onExit={() => setOpenOrganizationSettingsPanel(false)}
            >
                <Modal.Header closeVariant={isDark ? 'white' : 'dark'} closeButton>
                    <Modal.Title>
                        <h5>
                            {t('organization_settings')}
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrganizationSettingsPanel />
                </Modal.Body>
            </Modal>
        </Flex>
    );
};

export default ActiveOrganizationsDropdown;
