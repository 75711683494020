import React, { useState } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Row, Modal, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClose } from '@fortawesome/free-solid-svg-icons';

import APIService from 'http/api_service';
import ConfirmModal from 'components/common/ConfirmModal';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { toast } from 'react-toastify';
import FalconCardHeader from 'components/common/FalconCardHeader';
import WhiteLabelBrandLogo from './WhiteLabelBrandLogo';
import coverSrc from 'assets/img/icons/spot-illustrations/authentication-corner.png';
import IconButton from "components/common/IconButton";
import MonthlyYearlySwitch from "components/app/account/pricing/MonthlyYearlySwitch";
import { getBilledYearly } from "redux/slices/workspaceslice";

import { whiteLabelPricing } from "components/app/account/pricing/pricingDataNew";
import WhiteLabelSubscriptionCard from './WhiteLabelSubscriptionCard'
import CustomEmailManagement from './CustomEmailManagement'
import WhiteLabelNotifyCard from "./WhiteLabelInfoAlertCard"

const WhiteLabelSettingsPage = ({ register, errors }) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const navigate = useNavigate();
    const [customDomains, setCustomDomains] = useState([]);
    const [newDomainName, setNewDomainName] = useState();
    const [addingNewDomain, setAddingNewDomain] = useState(false);
    const [openRemoveDomainPrompt, setOpenRemoveDomainPrompt] = useState(false);
    const [removableDomain, setRemovableDomain] = useState();
    const [openMakePrimary, setOpenMakePrimary] = useState();
    const [removingDomain, setRemovingDomain] = useState(false);
    const [primaryCustomDomain, setPrimaryCustomDomain] = useState(activeWorkspace.primary_custom_domain);
    const [verifiableDomain, setVerifiableDomain] = useState();
    const [verifyingDomain, setVerifyingDomain] = useState();
    const [verificationNeeded, setVerificationNeeded] = useState(false); // [true, false, 'pending'
    const [responseFromDomainPreparation, setResponseFromDomainPreparation] = useState(); // [true, false, 'pending'
    const [domainVerificationError, setDomainVerificationError] = useState();
    const [error, setError] = useState();
    const [openDomainVerificationInfo, setOpenDomainVerificationInfo] = useState(false);
    const [currentViewingVerificationDomain, setCurrentViewingVerificationDomain] = useState();
    const [openWhiteLabelSetup, setOpenWhiteLabelSetup] = useState(false);
    const billedYearly = useSelector(getBilledYearly);
    const [updating, setUpdating] = useState(false);
    const [endElementMessage, setEndElementMessage] = useState();

    const [upgradingIndex, setupgradingIndex] = useState(-1);
    const [paymentForNewWorkspace] = useState(false);

    const [formData, setFormData] = useState({
        brandName: loggedInUser?.brand_name,
        subdomain: loggedInUser?.subdomain,
        brandTermOfService: loggedInUser?.brand_term_of_service,
        brandPrivacyPolicy: loggedInUser?.brand_privacy_policy,
        whiteLabelBrandPrimaryColor: loggedInUser?.white_label_brand_primary_color,
        whiteLabelBrandSecondaryColor: loggedInUser?.white_label_brand_secondary_color
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const userIsOnActiveWhiteLabelSubscriptionOrBenefit = () => {
        if (loggedInUser.active_plan.white_label && loggedInUser.active_plan.white_label !== false) {
            return true;
        }
        if (loggedInUser?.plans?.active?.postly_white_label && loggedInUser?.plans?.active?.postly_white_label?.Key?.toLowerCase() !== 'free') {
            return true;
        }
        return false;
    }

    const handleSubmit = e => {
        e.preventDefault();
        let requestBody = {
            brand_name: formData.brandName,
            subdomain: formData.subdomain,
            brand_term_of_service: formData.brandTermOfService,
            brand_privacy_policy: formData.brandPrivacyPolicy,
            white_label_brand_primary_color: formData.whiteLabelBrandPrimaryColor,
            white_label_brand_secondary_color: formData.whiteLabelBrandSecondaryColor

        }
        setUpdating(true);
        APIService.updatePersonalInfo(requestBody, (response, error) => {
            setUpdating(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            setEndElementMessage(message);
            dispatch(setLoggedInUser({
                ...loggedInUser,
                brand_name: formData.brandName,
                subdomain: formData.subdomain,
                brand_term_of_service: formData.brandTermOfService,
                brand_privacy_policy: formData.brandPrivacyPolicy,
                white_label_brand_primary_color: formData.whiteLabelBrandPrimaryColor,
                white_label_brand_secondary_color: formData.whiteLabelBrandSecondaryColor,
            }));
            toast.success(message, { theme: 'colored' });
            setOpenWhiteLabelSetup(false)
        });
    };

    const getAllCustomDomainForWorkSpace = (justUpdatedDomain) => {
        APIService.fetchCustomDomainsInWorkSpace(activeWorkspace._id, (response, error) => {
            if (error) {
                console.log("🚀 ~ file: CustomDomains.js:34 ~ APIService.fetchCustomDomainsInWorkSpace ~ error:", error)
                if (justUpdatedDomain) {
                    // set all pending domain flags to false
                    setVerificationNeeded(false);
                    setVerifyingDomain(false);
                    setRemovingDomain(false);
                    setAddingNewDomain(false);
                }
                return;
            }
            const { data } = response;
            setCustomDomains([...data]);
            if (justUpdatedDomain) {
                // set all pending domain flags to false
                setVerificationNeeded(false);
                setVerifyingDomain(false);
                setRemovingDomain(false);
                setAddingNewDomain(false);
            }
        });
    }

    useEffect(() => {
        getAllCustomDomainForWorkSpace();
    }, [])


    const prepareCustomDomainForWorkspace = (domain) => {
        APIService.prepareCustomDomainForWorkspace(activeWorkspace._id, domain, (response, error) => {
            if (error) {
                console.log(error);
                toast.error(error, {
                    theme: 'colored'
                })
                setAddingNewDomain(false);
                return;
            }
            setAddingNewDomain(false);
            setRemovingDomain(false);
            const { data, message } = response;
            console.log("🚀 ~ file: CustomDomains.js:92 ~ APIService.prepareCustomDomainForWorkspace ~ data:", data)
            setResponseFromDomainPreparation(data)
            if (data.verificationNeeded) {
                setVerificationNeeded(true);
            } else {
                setVerificationNeeded(false);
            }
        });
    }

    const updateWorkspace = (customDomainsCopy) => {
        APIService.updateWorkSpaceCustomDomains(activeWorkspace._id, customDomainsCopy, (response, error) => {
            if (error) {
                console.log(error);
                setAddingNewDomain(false);
                return;
            }
            prepareCustomDomainForWorkspace(newDomainName);
        });
    }

    const handleNewDomainAddition = (e) => {
        console.log('about processing....')
        e.preventDefault();
        if (!newDomainName) {
            console.log('stopped here.... 1')
            return;
        }
        if (!newDomainName.includes(".")) {
            setError("Invalid domain entered");
            return;
        }
        if (newDomainName.includes("postlyai.com") || newDomainName.includes("postlyai.com")) {
            setError("Oops! Not acceptable");
            return;
        }
        try {
            console.log('stopped here.... 2')
            setAddingNewDomain(true);
            let customDomainsCopy = [...customDomains];
            if (!customDomainsCopy.includes(newDomainName.toLowerCase())) {
                customDomainsCopy.push(newDomainName.toLowerCase());
                updateWorkspace(customDomainsCopy);
            }
        } catch (error) {
            console.log("🚀 ~ handleNewDomainAddition ~ error:", error)

        }
    }

    const removeDomain = async () => {
        setRemovingDomain(true);
        // remove domain with id: removableDomain
        APIService.removeDomainFromWorkSpaceForWhiteLabel(activeWorkspace._id, removableDomain?._id, (response, error) => {
            if (error) {
                console.log(error);
                setRemovingDomain(false);
                return;
            }
            // get all custom domains again
            getAllCustomDomainForWorkSpace(true);
        });
    }

    function getSubDomainFromCustomDomain(customDomain, apexName) {
        const parts = customDomain.split(`.${apexName}`);
        const subdomain = parts[0];
        return subdomain;
    }

    const getDomainVerificationStatements = (domainInfo) => {
        let verificationStatement = [];
        let subCounter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']
        let info = domainInfo || responseFromDomainPreparation
        const verification = domainInfo?.verification ?? responseFromDomainPreparation?.verification;
        if (verification?.aValues?.length > 0 || verification?.TXTs?.length || verification?.cnames?.length) {
            let arr1 = { statement: `Go to your domain provider (where you have hosted or bought your domain from)` }
            let arr2 = { statement: `Go to the DNS settings` }
            verificationStatement.push(arr1)
            verificationStatement.push(arr2)
        }
        if (verification?.TXTs?.length) {
            let arr3 = {
                statement: `Add a TXT record with the following details; { This is mandatory }`,
                children: [
                    `Host: <b>${verification?.TXTs[0]?.name}</b>`,
                    `Points to: <b>${verification?.TXTs[0]?.value}</b>`,
                    `Save the record`
                ]
            }
            verificationStatement.push(arr3)
        }
        let arr4 = {
            statement: `If your domain is an APEX or ROOT DOMAIN, Add an A record with the following details;`,
            children: [
                `Domain: <b>@</b>`,
                `value: <b>76.76.21.21</b>`,
                `Save the record`,
            ]
        }
        verificationStatement.push(arr4)
        let arr5 = {
            statement: `If your domain is a SUBDOMAIN, Add a CNAME record with the following details;`,
            children: [
                `Host: <b>Your subdomain, e.g: ${getSubDomainFromCustomDomain(info?.name, info?.apexName)}</b>`,
                `Points to: <b>cname.vercel-dns.com.</b>`,
                `NOTE: The full stop at the end of the <b>Points to</b> value above is important`,
                `Save the record`,
            ]
        }
        verificationStatement.push(arr5)

        if (verificationStatement?.length) {
            let arr6 = {
                statement: `We will verify and activate your custom domain within 24 hours if all is properly set up.`
            }
            verificationStatement.push(arr6);
            let items = verificationStatement.map((x, i) => {
                let childrenList = null;
                if (x.children) {
                    childrenList = (
                        <div>
                            {x.children.map((child, index) => (
                                <p
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        marginLeft: 10
                                    }}
                                >
                                    {subCounter[index]} <span style={{
                                        marginLeft: 10
                                    }} dangerouslySetInnerHTML={{ __html: child }} key={index}></span>
                                </p>
                            ))}
                        </div>
                    );
                }
                return (
                    <li style={{
                        marginBottom: 10
                    }} key={i}>
                        <div dangerouslySetInnerHTML={{ __html: x.statement }}></div>
                        {childrenList}
                    </li>
                );
            });
            return items
        }
    }

    const handleOpenCloseDomainVerificationInfo = (x) => {
        setOpenDomainVerificationInfo(!openDomainVerificationInfo);
        setCurrentViewingVerificationDomain(x);
    }

    return (
        <div>
            {
                !loggedInUser.subdomain && !userIsOnActiveWhiteLabelSubscriptionOrBenefit() ?
                    <Card>
                        <Card.Body className="overflow-hidden p-lg-6">
                            <Row className="align-items-center justify-content-between">
                                <Col lg={6}>
                                    {/* <img src={WhiteLabelHero} className="img-fluid" alt="WhiteLabel" /> */}
                                </Col>
                                <Col lg={6} className=" text-lg-left">
                                    <Card>
                                        <Card.Body className="p-4">
                                            <>
                                                <h4 className="text-800 mb-3">
                                                    White Label Plan
                                                </h4>
                                                <MonthlyYearlySwitch />
                                                <Alert variant="success" className="mt-3">
                                                    <h3 className="mb-0 text-800">
                                                        {billedYearly ? "$960" : "$100"}
                                                        <span className="fs-0 fw-medium font-sans-serif text-600">
                                                            {billedYearly ? "/year" : "/month"}
                                                        </span>
                                                    </h3>
                                                </Alert>
                                            </>
                                            <ul className="fa-ul ms-2 ps-card mb-2">
                                                {whiteLabelPricing.features.map((item) => (
                                                    <li key={item.id} className="py-1">
                                                        <h6 className="text-700">
                                                            <FontAwesomeIcon
                                                                icon="check"
                                                                className="fa-li text-success"
                                                            />
                                                            {item.title}
                                                        </h6>
                                                    </li>
                                                ))}
                                            </ul>
                                            <IconButton
                                                icon="palette"
                                                variant="success"
                                                iconClassName="me-2"
                                                className="w-100"
                                                onClick={() => {
                                                    setOpenWhiteLabelSetup(true);
                                                }}
                                            >
                                                Start trial and launch
                                            </IconButton>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    :
                    <>
                        <Row className="g-3">
                            <WhiteLabelNotifyCard />
                            <Col lg={8}>
                                <Card className='mb-3'>
                                    <FalconCardHeader title="White label brand settings" endEl={<span style={{ color: '#7CB342' }}>{endElementMessage}</span>} />
                                    <Card.Body className="bg-light" id='postly-white-label-brand-logo'>
                                        <b>Brand logo</b>
                                        <WhiteLabelBrandLogo>
                                            <WhiteLabelBrandLogo.Header
                                                coverSrc={coverSrc}
                                                brandLogo={loggedInUser?.brand_logo}
                                                className="mb-8"
                                            />
                                        </WhiteLabelBrandLogo>
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="mb-3 g-3">
                                                <Form.Group as={Col} lg={12} controlId="brandName">
                                                    <Form.Label>Brand Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Brand Name"
                                                        value={formData.brandName}
                                                        name="brandName"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                                <span style={{ marginBottom: '-8px' }}>
                                                    Your Postly subdomain is{" "}
                                                    <a href={`https://${loggedInUser?.subdomain}.postlyai.com`} target='_blank' rel="noreferrer">
                                                        {loggedInUser?.subdomain}.postlyai.com.
                                                    </a> {" "}You can change it below
                                                </span>
                                                <InputGroup>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Subdomain"
                                                        aria-label="Subdomain"
                                                        aria-describedby="subdomain"
                                                        value={formData.subdomain}
                                                        name="subdomain"
                                                        onChange={handleChange}
                                                    />
                                                    <InputGroup.Text id="subdomain">.postlyai.com</InputGroup.Text>
                                                </InputGroup>

                                                <Form.Group as={Col} lg={12} controlId="brandPrivacyPolicy">
                                                    <Form.Label>Privacy policy link</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="https://brand_privacy_policy.com"
                                                        value={formData.brandPrivacyPolicy}
                                                        name="brandPrivacyPolicy"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} lg={12} controlId="brandTermOfService">
                                                    <Form.Label>Terms of service link</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="https://brand_term_of_service.com"
                                                        value={formData.brandTermOfService}
                                                        name="brandTermOfService"
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            {/* <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Brand Primary Color</Form.Label>
                                                        <Form.Control
                                                            type="color"
                                                            value={formData.whiteLabelBrandPrimaryColor}
                                                            name="whiteLabelBrandPrimaryColor"
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>

                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Brand Secondary Color</Form.Label>
                                                        <Form.Control
                                                            type="color"
                                                            value={formData.whiteLabelBrandSecondaryColor}
                                                            name="whiteLabelBrandSecondaryColor"
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}
                                            <div className="text-end">
                                                <Button
                                                    disabled={updating
                                                        || !formData.brandName
                                                        || !formData.subdomain
                                                    }
                                                    variant="primary"
                                                    type="submit">
                                                    {updating ? "Saving..." : "Save changes"}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>

                                <Card className='mb-3' id='postly-white-label-custom-domain'>
                                    <FalconCardHeader title="Set your primary domain below" />
                                    <Card.Body className="bg-light" id='postly-white-label-brand-logo'>
                                        <Flex direction={'column'} className={'gap-2'}>
                                            {
                                                customDomains.map(x => {
                                                    return (
                                                        <Flex
                                                            direction={'column'}
                                                            onMouseEnter={() => setOpenMakePrimary(x)}
                                                            onMouseLeave={() => setOpenMakePrimary(undefined)}>
                                                            <Flex
                                                                alignItems={'center'}
                                                                style={{
                                                                    marginRight: 10,
                                                                    gap: 6
                                                                }}>
                                                                <span><strong>{x?.name}</strong></span> <span>
                                                                    {x?.verified ? <FontAwesomeIcon
                                                                        icon={faCheckCircle}
                                                                        style={{
                                                                            color: 'green'
                                                                        }}
                                                                    />
                                                                        : <span
                                                                            onClick={() => handleOpenCloseDomainVerificationInfo(x)}
                                                                            style={{ color: 'red', cursor: 'pointer' }}>
                                                                            (Pending verification: {!openDomainVerificationInfo ? 'Click to see details' : 'Click to hide details'})
                                                                        </span>
                                                                    }
                                                                </span>

                                                                <div style={{ flex: 1 }}></div>
                                                                {
                                                                    (removingDomain && removableDomain?._id === x?._id && verifiableDomain !== x) ?
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        /> : <FontAwesomeIcon
                                                                            style={{ cursor: 'pointer' }}
                                                                            icon={faClose}
                                                                            onClick={() => {
                                                                                setRemovableDomain(x);
                                                                                setOpenRemoveDomainPrompt(true);
                                                                            }}
                                                                        />
                                                                }
                                                            </Flex>
                                                            {
                                                                openDomainVerificationInfo && currentViewingVerificationDomain?._id === x?._id &&
                                                                <Flex
                                                                    direction={'column'} className={'gap-2'}
                                                                    style={{
                                                                        marginTop: 20
                                                                    }}
                                                                >
                                                                    <p><b>Follow these steps to verify your domain {currentViewingVerificationDomain?.name}</b></p>
                                                                    <ol>
                                                                        {getDomainVerificationStatements(x)}
                                                                    </ol>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() => {
                                                                            window.location.reload()
                                                                        }}
                                                                    >
                                                                        Verify
                                                                    </Button>
                                                                </Flex>
                                                            }
                                                            <Divider />
                                                        </Flex>
                                                    )
                                                })
                                            }

                                            <form
                                                onSubmit={handleNewDomainAddition}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                <Flex
                                                    direction={'column'}
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Form.Group as={Col} lg={12} controlId="brandName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder='www.yoursite.com'
                                                            disabled={addingNewDomain}
                                                            value={newDomainName}
                                                            name="newDomainName"
                                                            onChange={(e) => {
                                                                let enteredValue = e.target.value;
                                                                setNewDomainName(enteredValue);
                                                                if (!enteredValue) {
                                                                    setError(null);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    {/* <input
                                                    disabled={addingNewDomain}
                                                    value={newDomainName}
                                                    onChange={(e) => {
                                                        let enteredValue = e.target.value;
                                                        setNewDomainName(enteredValue);
                                                        if (!enteredValue) {
                                                            setError(null);
                                                        }
                                                    }}
                                                    placeholder='www.yoursite.com'
                                                    style={{
                                                        display: 'block',
                                                        border: '1px solid #F8FAFD',
                                                        outline: 'none'
                                                    }}
                                                /> */}
                                                    <span className='fs--1 py-3' style={{ color: 'red' }}>{error}</span>
                                                </Flex>
                                                {
                                                    newDomainName &&
                                                    <Button
                                                        className='mb-4 ms-1'
                                                        type='submit'
                                                        disabled={addingNewDomain}>
                                                        {
                                                            addingNewDomain ? <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                                : "Add Domain"
                                                        }
                                                    </Button>
                                                }
                                            </form>
                                        </Flex>
                                        {
                                            verificationNeeded && !openDomainVerificationInfo &&
                                            <Flex
                                                direction={'column'} className={'gap-2'}
                                            >
                                                <p><b>To proceed into using {responseFromDomainPreparation?.name}, follow the steps below;</b></p>
                                                <ol>
                                                    {getDomainVerificationStatements()}
                                                </ol>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        window.location.reload()
                                                    }}
                                                >
                                                    Verify
                                                </Button>
                                            </Flex>
                                        }
                                        <br />
                                    </Card.Body>
                                </Card>
                                {/* < WhiteLabelStripeConnect /> */}
                                <CustomEmailManagement
                                    customDomains={customDomains}
                                />
                            </Col>
                            <Col lg={4}>
                                <div className="sticky-sidebar">
                                    <WhiteLabelSubscriptionCard
                                        // cancellingAIWriterSubscription={
                                        //    cancellingAIWriterSubscription
                                        // }
                                        pricing={whiteLabelPricing}
                                        userIsOnWhitelabel={userIsOnActiveWhiteLabelSubscriptionOrBenefit()}
                                        handleBilling={() => {

                                        }}
                                        handleAIWriterSubscriptionCancel={() => {
                                            // setOpenAIWriterSubscriptionCancelPrompt(true);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>
            }
            <ConfirmModal
                open={openRemoveDomainPrompt}
                message={"Are you sure about removing this domain?"}
                title={`Remove ${removableDomain?.name}?`}
                onConfirm={() => {
                    setOpenRemoveDomainPrompt(false);
                    removeDomain();
                }}
                onCancel={() => {
                    setOpenRemoveDomainPrompt(false);
                }}
                cancelText={"CANCEL"}
                confirmText={"REMOVE"}
            />

            <Modal show={openWhiteLabelSetup}
                onHide={() => setOpenWhiteLabelSetup(false)}
                onExit={() => setOpenWhiteLabelSetup(false)}
                centered
                size='md'>
                <Modal.Body>
                    <FalconCardHeader title="White label settings" endEl={<span style={{ color: '#7CB342' }}>{endElementMessage}</span>} />
                    <Divider />
                    <b>Brand logo</b>
                    <WhiteLabelBrandLogo>
                        <WhiteLabelBrandLogo.Header
                            coverSrc={coverSrc}
                            brandLogo={loggedInUser?.brand_logo}
                            className="mb-8"
                        />
                    </WhiteLabelBrandLogo>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={12} controlId="brandName">
                                <Form.Label>Brand Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Brand Name"
                                    value={formData.brandName}
                                    name="brandName"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <span style={{ marginBottom: '-8px' }}> <b>Add Postly subdomain.</b> You will add your primary domain on the next page </span>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Subdomain"
                                    aria-label="Subdomain"
                                    aria-describedby="subdomain"
                                    value={formData.subdomain}
                                    name="subdomain"
                                    onChange={handleChange}
                                />
                                <InputGroup.Text id="subdomain">.postlyai.com</InputGroup.Text>
                            </InputGroup>
                        </Row>
                        {/* <Row>
                            <Col  >
                                <Form.Group
                                    className="mb-3"
                                >
                                    <Form.Label>Brand Primary Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={formData.whiteLabelBrandPrimaryColor}
                                        name="whiteLabelBrandPrimaryColor"
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    className="mb-3"
                                >
                                    <Form.Label>Brand Secondary Color</Form.Label>
                                    <Form.Control
                                        type="color"
                                        value={formData.whiteLabelBrandSecondaryColor}
                                        name="whiteLabelBrandSecondaryColor"
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <div className="text-end">
                            <Button
                                disabled={updating
                                    || !formData.brandName
                                    || !formData.subdomain
                                }
                                variant="primary"
                                type="submit">
                                {updating ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* <SettingsToggle />
            <SettingsPanel /> */}



        </div >
    );

};

export default WhiteLabelSettingsPage;