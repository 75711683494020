import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveWorkSpace, searchWorkSpace, switchingWorkspace } from 'redux/slices/workspaceslice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FolderSimple } from '@phosphor-icons/react/dist/ssr';
import { Spinner } from 'react-bootstrap';

const ActiveWorkspace = ({ at, width, height, className, textClass, ...rest }) => {

  const dispatch = useDispatch();
  const activeWorkSpace = useSelector(getActiveWorkSpace);
  const { config } = useContext(AppContext);
  const workspaceSwitchHappening = useSelector(switchingWorkspace);

  const truncatedWorkSpaceName = () => {
    let name = activeWorkSpace?.name ?? "";
    let validLength = 14;
    if (name.length > validLength) {
      return name.substring(0, validLength).concat('...');
    }
    return name;
  }

  if (workspaceSwitchHappening) {
    return (<Spinner animation='border' size='sm' />)
  }

  return (
    <div
      className={classNames(
        'text-decoration-none',
        'mx-0',
        'px-0',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          'mx-0',
          'px-0',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <span
          style={{ fontSize: '16px' }}
          size="sm"
          onClick={() => {
            dispatch(searchWorkSpace(true));
          }}
        >
          <div
            className="cursor-pointer mx-0 px-0"
            style={{
              display: 'flex',
              gap: 1, alignItems: 'center',
              fontWeight: 'normal',
              color: !config.isDark && '#060606',
            }}>
            <FolderSimple weight='fill' size={20} className='mx-0 px-0' />
            <span className="d-sm-inline-block ms-1 me-1">{truncatedWorkSpaceName()}</span>
            <FontAwesomeIcon icon={'caret-down'} />
          </div>
        </span>
      </div>
    </div>
  );
}

ActiveWorkspace.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

ActiveWorkspace.defaultProps = { at: 'auth', width: 30 };
export default ActiveWorkspace;
