import { toast } from "react-toastify";
import { forcefullyLogout } from "./auth_utils";

export default class HttpErrorHandler {
    static spitHttpErrorMsg(e) {
        if (e !== undefined && e !== null) {
            if (e.hasOwnProperty('response')) {
                let response = e.response;
                if (response !== undefined && response !== null) {
                    if (response.hasOwnProperty('data')) {
                        let responseData = response['data'];
                        if (responseData && responseData.hasOwnProperty('message')) {
                            let error = responseData['message'];
                            if (error?.toLowerCase() === `no such account`
                                || error?.toLowerCase() === `apiKey is missing`
                                || error?.toLowerCase()?.includes("invalid session")
                                || error?.toLowerCase()?.includes("api_key missing") || error?.toLowerCase()?.includes("invalid api_key provided")) {
                                console.log('about to forcefully logout');
                                forcefullyLogout()
                            } else {
                                return responseData['message'];
                            }
                        }
                    }
                }
            }
        }
        if (navigator.onLine) {
            console.log('error on key: navigator.onLine. Reporting to console.')
        }
        return navigator.onLine ? '' : "Oops! An error occurred!.\nEnsure your data connection is still right.";
    }
}