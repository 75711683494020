import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivePlanCard from './ActivePlanCard';
import ConfirmModal from "components/common/ConfirmModal";
import { pricingDataNew, postlyCloudPricing, postlyExtraUsers, extraSocialAccounts } from "./PricingData";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useDispatch, useSelector } from "react-redux";
import APIService from "http/api_service";
import { toast } from 'react-toastify';

const ActivePlans = (props) => {
    const { activePlans } = props;
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const stripeCustomerBillingPortal = `https://billing.stripe.com/p/login/3cs5lX8oidsOdLqcMM?prefilled_email=${loggedInUser?.email}`;

    const [addOnsPlan, setLoggedInUser] = useState([
        ...postlyExtraUsers,
        ...postlyCloudPricing,
        ...extraSocialAccounts
    ])
    const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
        useState(false);
    const [
        openAIWriterSubscriptionCancelPrompt,
        setOpenAIWriterSubscriptionCancelPrompt,
    ] = useState(false);

    const [
        openJoinedAIWriterCancellationPrompt,
        setOpenJoinedAIWriterCancellationPrompt,
    ] = useState(false);
    const cancelAISubscription = (e) => {
        let activeAISubscription = loggedInUser.active_ai_writer_subscription;
        if (activeAISubscription.tied_to_plan) {
            setOpenJoinedAIWriterCancellationPrompt(true);
            return;
        }
        if (activeAISubscription.cancel_url) {
            window.location = activeAISubscription.cancel_url;
        } else {
            APIService.cancelAISubscription((response, error) => {
                if (error) {
                    toast.error(error, { theme: "colored" });
                    return;
                }
                let { message, data } = response;
                dispatch(setLoggedInUser({ ...data }));
                toast.success(message, { theme: "colored" });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            });
        }
    };

    const cancelUrl = (plan) => {
        if (plan?.Key === "Unlimited AI") {
            return plan?.receipt_url // This ensures that a valid value is returned instead of null. It is not used for any other purpose. Do not remove.
        }
        let url = plan?.cancel_url
        return url
    }

    const updateUrl = (plan) => {
        let url = plan?.update_url
        return url
    }

    const nextBillingDate = (plan) => {
        let nextBillingDate = plan?.next_bill_date
        return nextBillingDate
    }

    const displayActivePlanTabs = () => {
        return activePlans?.map((x, i) => {
            const thisIsActivePlan = addOnsPlan?.find((p) => p?.plan === x?.Key)
            return <ActivePlanCard
                key={i}
                data={thisIsActivePlan ? thisIsActivePlan : x?.plan}
                pricing={thisIsActivePlan ? thisIsActivePlan : x?.plan}
                // billedYearly={billedYearly}
                activePlanFromDB={thisIsActivePlan ? thisIsActivePlan : x?.plan}
                updatableUrl={updateUrl(x?.plan)}
                nextBillingDate={nextBillingDate(x?.plan)}
                stripeCustomerBillingPortal={stripeCustomerBillingPortal}
                // handleSubscriptionCancellation={(url) => {
                //     setTempCancellationUrl(url)
                //     setOpenSubscriptionCancelPrompt(true);
                // }}
                cancelUrl={cancelUrl(x?.plan)}
                cancelFunction={(p) => {
                    if (x?.type === 'active_unlimited_ai') {
                        setOpenAIWriterSubscriptionCancelPrompt(true);
                    } else {
                        window.open(p, '_blank');
                    }
                }}
                hasActiveSubscription={thisIsActivePlan ? thisIsActivePlan : (x?.plan || null)}
                handleBilling={(data) => {
                    // if (!isFreePlan(loggedInUser.active_plan)) {
                    //     setOpenPreCancellationDialog(true);
                    //     return;
                    // }
                    // handleSubscription(data, {
                    //     ...loggedInUser,
                    //     workSpaceId: workspace._id,
                    // });
                }}
                setLoggedInUser={setLoggedInUser}
                billingTag={'scheduler'}
            />
        })
    }


    return (
        <>
            <Card className="mb-3">
                <Card.Body
                    className="bg-light"
                    style={{
                        width: '100%'
                    }}
                > <Flex
                    justifyContent={'start'}
                    gap={5}
                    style={{
                        width: '100%',
                    }}
                >
                        <Flex
                            gap={2}
                            alignItems={'center'}
                            style={{
                                // width: '100%',
                                fontWeight: 700
                            }}
                            id="schedulerSection001"
                        >

                            MY ACTIVE PLANS  <FontAwesomeIcon
                                icon="crown"
                                className="text-success"
                            />
                        </Flex>
                    </Flex>
                </Card.Body>
            </Card>

            <Flex
                gap={2}
                wrap={'wrap'}
                justifyContent={'left'}
                className={'mb-3'}
            >
                {displayActivePlanTabs()}
            </Flex>

            <ConfirmModal
                open={openSubscriptionCancelPrompt}
                onCancel={() => {
                    setOpenSubscriptionCancelPrompt(false);
                }}
                title="Cancel your subscription?"
                message="This will cancel your current subscription and downgrade your account to the free plan."
                cancelText="NO"
                confirmText="CONTINUE"
                onConfirm={() => {
                    setOpenSubscriptionCancelPrompt(false);
                    // cancelSubscription();
                    // cancelSubscriptionForScheduler()
                }}
            />
            <ConfirmModal
                open={openAIWriterSubscriptionCancelPrompt}
                onCancel={() => {
                    setOpenAIWriterSubscriptionCancelPrompt(false);
                }}
                title="Cancel your Unlimited AI subscription"
                message="This will cancel your current Unlimited AI subscription and downgrade your Unlimited AI quota to the free plan."
                cancelText="NO"
                confirmText="CONTINUE"
                onConfirm={() => {
                    setOpenAIWriterSubscriptionCancelPrompt(false);
                    cancelAISubscription();
                }}
            />
            <ConfirmModal
                open={openJoinedAIWriterCancellationPrompt}
                onCancel={() => {
                    setOpenJoinedAIWriterCancellationPrompt(false);
                }}
                title="Cancel your subscription?"
                message="Your AI Writer Unlimited Subscription is currently tied to your active plan. Kindly use the Cancel Subscription option on your active plan instead"
                cancelText="CLOSE"
                confirmText="OKAY"
                onConfirm={() => {
                    setOpenJoinedAIWriterCancellationPrompt(false);
                }}
            />
        </>
    )
}

export default ActivePlans