import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex'
import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';

const ManageSubscriptionCard = (props) => {
    const { loggedInUser } = props
    const [currentSocialAccountNumber, setCurrentSocialAccountNumber] = useState(0);
    const [planSocialAccountNumberLimit, setPlanSocialAccountNumberLimit] = useState(0);

    return (
        <Flex
            gap={2}
        >
            {/* <Flex
                alignItems="center"
                className="bg-light rounded-3 border position-relative"
            >
                <div className="p-3">

                    <Form.Group className="mt-3">
                        <Form.Label> <h5 className="fs-0 fw-semi-bold mb-2">
                            Manage your social accounts
                        </h5></Form.Label>
                        <Row className="align-items-center gx-0">
                            <Col
                                xs
                                md="auto"
                                className="d-flex justify-content-start"
                            >
                                <Button
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    variant="falcon-primary"
                                    onClick={() => {
                                        // if (formData.numberOfUsers <= 0) return;
                                        // setFormData({
                                        //     ...formData,
                                        //     numberOfUsers: formData.numberOfUsers - 1,
                                        // });
                                    }}
                                >
                                    <FontAwesomeIcon icon="minus" />
                                </Button>
                            </Col>
                            <Col
                                xs
                                md="auto"
                                className="d-flex justify-content-start"
                            >
                                <Form.Group controlId="numberOfUsers">
                                    <Form.Control
                                        type="number"
                                        value={currentSocialAccountNumber}
                                        name="numberOfUsers"
                                        onChange={() => { }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                xs
                                md="auto"
                                className="d-flex justify-content-start"
                            >
                                <Button
                                    variant="falcon-primary"
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                    onClick={() => {
                                        // setFormData({
                                        //     ...formData,
                                        //     numberOfUsers: formData.numberOfUsers + 1,
                                        // });
                                    }}
                                >
                                    <FontAwesomeIcon icon="plus" />
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </div>
            </Flex> */}
            <Flex
                alignItems="center"
                className="bg-light rounded-3 border position-relative"
            >
                <div className="p-3">
                    <h5 className="fs-0 fw-semi-bold mb-2">
                        <a href={`https://billing.stripe.com/p/login/3cs5lX8oidsOdLqcMM?prefilled_email=${loggedInUser.email}`} className="text-900 hover-primary stretched-link" target='_blank' rel="noreferrer">
                            Manage your subscription
                        </a>
                    </h5>
                    <h6 className="mb-0 text-600">
                        Update your social account quantity, review terms or manage your subscription from your Stripe account.
                    </h6>
                </div>
            </Flex>
        </Flex>
    )
}

export default ManageSubscriptionCard