import APIService from "http/api_service";

/**
 * Gets the current host domain from the URL.
 * @returns {string} - The host domain.
 */
export const getCurrentHostnameDomain = () => {
    return window.location.hostname;
};

/**
 * Gets the current URL from the window location.
 * @returns {string} - The current URL.
 */
export const getCurrentURL = () => {
    return window.location.href;
};

export const isCustomDomain = () => {
    const hostname = window.location.hostname;
    const customDomain = hostname !== "app.postly.ai" && hostname !== "localhost";
    return customDomain;
};
  
export const validateSubdomain = (apiKey, cb) => {
    let parts = window.location.hostname.split('.');
    let subdomain = parts.shift();
    if (subdomain === 'www') {
        subdomain = parts.shift();
    }

    // Don't enforce domain validation yet
    
    // if (subdomain === 'localhost' || subdomain === 'app' || subdomain === 'dashboard' || subdomain === 'dev') {
    if ( 1+1 === 2 ) {
        cb(null, {});
        return
    }
    // TODO: check if custom domain exist and if it does, return true;
    
    APIService.validateUserAgainstSubdomain(apiKey, (response, error) => {
        if (error) {
            cb(error, null);
            return;
        }
        let { data = [] } = response;
        console.log(response)
        let workspaceWithSubdomain = data.find(x => x?.workspace?.subdomain === subdomain);
        if (workspaceWithSubdomain) {
            cb(null, workspaceWithSubdomain);
        } else {
            cb("You are not a member of this team. Check with the account admin. Thank you", null);
        }
    })
}

export const checkSubDomain = (cb) => {
    let parts = window.location.hostname.split('.');
    let subdomain = parts.shift();
    if (subdomain === 'www') {
        subdomain = parts.shift();
    }
    // check if custom domain exist;
    // TODO: check if custom domain exist;


    if (subdomain === 'localhost' || subdomain === 'app' || subdomain === 'auth' || subdomain === 'trustable') {
        cb({}, null);
        return
    }
    //Check if domain exists
    APIService.fetchWorkspaceBySubdomain(subdomain, (response, error) => {
        if (error) {
            cb(null, `Sorry, the subdomain ${subdomain} is not available. Create your own account`);
            return;
        }
        cb({}, null);
    });
}

// export const checkSubcriptionPlan = (cb) => {
//     APIService.fetchWorkspaceBySubdomain(subdomain, (response, error) => {
//         if (error) {
//             cb(null, `Sorry, the subdomain ${subdomain} is not available. Create your own account`);
//             return;
//         }
//         cb({}, null);
//     });
// }