import recursiveAspectFinder from 'utils/recursiveAspectFinder';
import { platformSpecs } from "utils/platform_utils";
import { PlatformIdentifier } from 'constants';

import { store } from 'redux/store';
import { getManuallyEditedPlatforms, getSelectedPlatforms } from 'redux/slices/postslice';
import { getSideNotifications, setSideNotifications } from 'redux/slices/notification_slice';
import { dispatchSideNotification } from './notification';
import { isVideo } from './file_utils';
import StringUtils from './string';
import { getMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import { platforms } from 'components/app/platforms';

const rescaleImage = async (source, width, height, expectedWidth, expectedHeight, skipAspectFinder = false) => {
    if (skipAspectFinder) {
        try {
            const image = new Image();
            image.src = source;
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let scale = Math.max(
                canvas.width / image.naturalWidth,
                canvas.height / image.naturalHeight
            );
            let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
            let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
            canvas
                .getContext("2d")
                .drawImage(
                    image,
                    x,
                    y,
                    image.naturalWidth * scale,
                    image.naturalHeight * scale
                );
            const dataURL = await canvas.toDataURL('image/png');
            return dataURL;
        } catch (error) {
        }
    }
    const { new_width, new_height } = await recursiveAspectFinder(expectedWidth, expectedHeight, width, height, 1);
    const image = new Image();
    image.src = source;
    const canvas = document.createElement("canvas");
    canvas.width = new_width;
    canvas.height = new_height;
    let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
    );
    let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
    let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
    canvas
        .getContext("2d")
        .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
        );
    const dataURL = await canvas.toDataURL();
    return dataURL;
}

const processAutoFixMedia = async (url, platform, currentWidth, currentHeight, expectedWidth, expectedHeight, skipAspectFinder = false) => {
    const result = await rescaleImage(url, currentWidth, currentHeight, expectedWidth, expectedHeight, skipAspectFinder)
    return result;
}

const getImageDimension = (url) => {
    return new Promise(resolve => {
        const image = document.createElement('img');
        image.addEventListener("load", function () {
            const height = this.height;
            const width = this.width;
            resolve({ height, width });
        }, false);
        image.src = url;
    });

}

const dataURLToFile = (dataUrl, fileName) => {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], fileName, {
        lastModified: Date.now(),
        type: mimeString
    });
    return file;
}

const convertImageFile = (file, format) => {
    return new Promise((resolve, reject) => {
        if (!file || !format) {
            return reject(new Error('File and format are required'));
        }

        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                canvas.toBlob((blob) => {
                    if (!blob) {
                        return reject(new Error('Conversion failed'));
                    }
                    const convertedFile = new File([blob], file?.name ?? `converted.${format === 'image/jpeg' ? 'jpg' : 'png'}`, { type: format });
                    resolve(convertedFile);
                }, format);
            };
            img.src = e.target.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}


function getImageDimensionsFromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const dimensions = {
                    width: img.naturalWidth,
                    height: img.naturalHeight
                };
                resolve(dimensions);
            };
            img.onerror = function () {
                reject(new Error("Failed to load image."));
            };
            img.src = event.target.result;
        };

        reader.onerror = function () {
            reject(new Error("Failed to read file."));
        };

        reader.readAsDataURL(file);
    });
}

const runMediasViaPlatformSpecAndReturnEligibles = async (medias, platform) => {
    const sideNotificationsFromRedux = getSideNotifications(store.getState());
    let _medias = [...medias];
    let sideNotifications = [];
    // check if platform supports video;
    const platformSpec = platformSpecs[platform];
    if (platformSpec?.video?.disallowed) {
        const videoFound = _medias.find(media => media.is_video);
        if (videoFound) {
            _medias = _medias.filter(media => !media.is_video);
            let newNotification = {
                type: 'info',
                postMetadata: true,
                header: `Videos not allowed by platform`,
                message: `Videos are not supported on ${platformSpec.name} and was removed.`,
                platform,
                read: false,
            }
            sideNotifications.push(newNotification);
        }
    }

    // check if platform has image limit and remove excess images from the medias;
    if (platformSpec?.image?.maxNumber) {
        let images = _medias.filter(media => !media.is_video);
        if (images.length > platformSpec.image.maxNumber) {
            images = images.slice(0, platformSpec.image.maxNumber);
            let newNotification = {
                type: 'info',
                postMetadata: true,
                header: `Images Removed`,
                message: `Only ${platformSpec.image.maxNumber} images are allowed on ${platformSpec.name}. So the excess images was removed.`,
                platform,
                read: false,
            }
            sideNotifications.push(newNotification);
        }
        _medias = _medias.filter(media => media.is_video || images.includes(media));
    }

    // check if platform has video limit and remove excess videos from the medias;
    if (platformSpec?.video?.maxNumber) {
        let videos = _medias.filter(media => media.is_video);
        if (videos.length > platformSpec.video.maxNumber) {
            videos = videos.slice(0, platformSpec.video.maxNumber);
            let newNotification = {
                type: 'info',
                postMetadata: true,
                header: `Videos Removed`,
                message: `Only ${platformSpec.video.maxNumber} videos are allowed on ${platformSpec.name}. So the excess videos was removed.`,
                platform,
                read: false,
            }
            sideNotifications.push(newNotification);
        }
        _medias = _medias.filter(media => !media.is_video || videos.includes(media));
    }

    //check if platform has media limit and remove excess media from the medias;
    if (platformSpec?.media?.maxNumber) {
        if (_medias.length > platformSpec.media.maxNumber) {
            _medias = _medias.slice(0, platformSpec.media.maxNumber);
            let newNotification = {
                type: 'info',
                postMetadata: true,
                header: `Media Removed`,
                message: `Only ${platformSpec.media.maxNumber} media are allowed on ${platformSpec.name}. So the excess media was removed.`,
                platform,
                read: false,
            }
            sideNotifications.push(newNotification);
        }
    }

    if (sideNotifications.length) {
        dispatchSideNotification(sideNotifications)
    }

    return {
        _medias,
        platform
    }
}

const scanMediaArrangementsForPlatformSpecs = async (mediaArrangements) => {
    const manuallyEditedPlatforms = getManuallyEditedPlatforms(store.getState());
    const selectedPlatforms = getSelectedPlatforms(store.getState());
    const platformPromises = Object.keys(mediaArrangements).map(async key => {
        if (key === PlatformIdentifier.THREADS || key === PlatformIdentifier.TWITTER) {
            return { [key]: mediaArrangements[key] };
        }
        if (manuallyEditedPlatforms[key]) {
            return { [key]: mediaArrangements[key] }; // do not touch manually edited platforms
        }
        const platformIsSelected = selectedPlatforms.find(
            (x) => x.identifier === key && x.selected
        );
        if (!platformIsSelected) {
            return { [key]: mediaArrangements[key] };
        }
        let mediaProps = mediaArrangements[key]?.media;
        if (mediaProps?.length) {
            const { _medias } = await runMediasViaPlatformSpecAndReturnEligibles(mediaProps, key);
            return { [key]: { ...mediaArrangements[key], media: _medias } };
        } else {
            return { [key]: mediaArrangements[key] };
        }
    });
    const resolvedPlatforms = await Promise.all(platformPromises);
    const transformedPlatforms = resolvedPlatforms.reduce((acc, platform) => {
        return { ...acc, ...platform };
    }, {});
    return transformedPlatforms;
}

const prepareMediaArrangementsForPostEditOutFromPlatformSpecificData = async (data) => {
    let newData = {}
    for (const key in data) {
        let media = data[key]?.media;
        // check if data[key] is an array
        if (Array.isArray(data[key])) {
            let arr = data[key];
            let newArr = [];
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];
                if (item?.media?.length) {
                    let newMedia = item.media.map(m => {
                        // is m a string;
                        let mediaUrl = typeof m === "string" ? m : m.url;
                        return {
                            extension: `.${StringUtils.substringAfterLast(
                                StringUtils.substringBeforeLast(mediaUrl, "?"),
                                "."
                            )}`,
                            url: mediaUrl,
                            uploaded: true,
                            external: true,
                            name: mediaUrl,
                            thumbnail: false,
                            is_video: isVideo(mediaUrl),
                        }
                    });
                    newArr.push({ ...item, media: newMedia, });
                } else {
                    newArr.push(item);
                }
            }
            newData[key] = newArr;
        } else {
            if (media?.length) {
                let newMedia = media.map(m => {
                    let mediaUrl = typeof m === "string" ? m : m.url;
                    return {
                        extension: `.${StringUtils.substringAfterLast(
                            StringUtils.substringBeforeLast(mediaUrl, "?"),
                            "."
                        )}`,
                        url: mediaUrl,
                        uploaded: true,
                        external: true,
                        name: mediaUrl,
                        thumbnail: false,
                        is_video: isVideo(mediaUrl),
                    }
                });
                newData[key] = { ...data[key], media: newMedia };
            } else {
                newData[key] = data[key];
            }
        }
    }
    const platformsWithLimits = [...platforms];
    const _newData = platformsWithLimits.map(platform => {
        if (platform.identifier === PlatformIdentifier.THREADS || platform.identifier === PlatformIdentifier.TWITTER) {
            if (newData[platform.identifier]) {
                return { [platform.identifier]: newData[platform.identifier] };
            } else {
                return { [platform.identifier]: [{ media: [], text: "" }] };
            }
        }
        if (newData[platform.identifier]) {
            return { [platform.identifier]: newData[platform.identifier] };
        } else {
            return { [platform.identifier]: { media: [], text: "" } };
        }
    });

    let newArrangement = {};
    for (let newDataEntry of _newData) {
      let newEntryArr = Object.entries(newDataEntry).flat();
      newArrangement[newEntryArr[0]] = newEntryArr[1];
    }
    return newArrangement;
}

export {
    rescaleImage,
    getImageDimension,
    processAutoFixMedia,
    convertImageFile,
    dataURLToFile,
    getImageDimensionsFromFile,
    runMediasViaPlatformSpecAndReturnEligibles,
    scanMediaArrangementsForPlatformSpecs,
    prepareMediaArrangementsForPostEditOutFromPlatformSpecificData,
}