import APIService from 'http/api_service';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    getDatePeriod,
    getOpenExportModal,
    getPageId,
    setDatePeriod,
    setInsightData,
    setOpenExportModal,
    setOverviewData
} from 'redux/slices/analytics_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { convertToTitleFormat } from 'utils/platform_utils';
import DateSelection from '../../DateSelection/DateSelection';
import LinkedinOverview from '../../Overview';
import PlatformHeader from '../../PlatformHeader';
import ExportReportHeader from '../../ReportExportHeader';
import ReportExportModal from "../../ReportExportPreview";
import ActiveUsers from '../../active-users/ActiveUsers';
import Audience from '../../audience/Audience';
import TopPosts from '../../top-posts/TopPosts';
import UsersByCountry from '../../users-by-country/UsersByCountry';

const LinkedInAnalyticsPage = (props) => {
    const { platform, account } = props;
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);
    const workSpace = useSelector(getActiveWorkSpace);
    const datePeriod = useSelector(getDatePeriod);
    const openExportModal = useSelector(getOpenExportModal);
    const [postLoading, setPostLoading] = useState(false);
    const [topPosts, setTopPosts] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [pageStatsData, setPageStatsData] = useState([]);
    const [followsLoading, setFollowsLoading] = useState(false);
    const [regionLoading, setRegionLoading] = useState(false);
    const [pageInsightLoading, setPageInsightLoading] = useState(false);
    const [audienceData, setAudienceReportData] = useState([]);
    const [pages, setPages] = useState([]);
    const [audienceValue, setAudienceValue] = useState({});
    const [pageProfileStats, setPageProfileStats] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [audienceReport, setAudienceReport] = useState([]);
    const [fansFollowersTitle] = useState("followers_by_country");

    const demographicErrorMessage = `No audience found on your linkedin page`;

    useEffect(() => {
        APIService.fetchPagesAndGroups(
            workSpace,
            account.identifier,
            account.user_id, (response, error) => {
                if (error) {
                    toast.error(error, { theme: "colored" });
                    return;
                }
                const { data } = response;
                let linkedInPages = [];
                for (let page of data) {
                    if (!page.self) {
                        linkedInPages.push(page);
                    }
                }
                setPages(linkedInPages);
            });
    }, [account]);

    useEffect(() => {
        let { since, until } = datePeriod;
        if (!(since && until)) {
            since = moment().utc().subtract(28, "days").startOf("day").format();
            until = moment().utc().endOf("day").format();
            dispatch(setDatePeriod({ since, until }));
        }
    }, [datePeriod]);

    const fetchTopPosts = () => {
        setPostLoading(true);
        let requestBody = {
            page_id: pageId
        };
        APIService.fetchTopPosts(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
            if (error) {
                setPostLoading(false);
                toast.error(error);
            }
            setPostLoading(false);
            const { data } = response ?? [];
            setTopPosts(data);
        })
    };

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchTopPosts();
    }, [pageId]);

    const fetchPageStatistics = () => {
        setFollowsLoading(true);
        const { since, until } = datePeriod;
        const requestBody = {
            page_id: pageId,
            since, until
        };
        APIService.fetchPageStatistics(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
            if (error) {
                setFollowsLoading(false);
                toast.error(error, { theme: 'colored' });
            }
            setFollowsLoading(false);
            const { data } = response ?? [];
            setPageStatsData(data);
        })
    };

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchPageStatistics();
    }, [datePeriod, pageId]);

    const fetchUsersByRegion = () => {
        setRegionLoading(true);
        let reportData = [];
        const requestBody = { page_id: pageId, }
        APIService.fetchUsersByCountry(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
            if (error) {
                setRegionLoading(false);
                return;
            }
            setRegionLoading(false);
            const { data } = response ?? {};
            if (!data) {
                return;
            }
            const userRegion = data['usersByCountry'] ?? [];
            const userCountry = userRegion.map(({ country }) => country);
            const userFollowersCount = userRegion.map(({ followersCount }) => followersCount);
            const newData = [userCountry, userFollowersCount];
            const userRegionData = userRegion.reduce((obj, item) => {
                obj[item?.country] = item?.followersCount;
                return obj;
            }, {});
            reportData.push({ title: fansFollowersTitle, value: { ...userRegionData } || [] });
            setAudienceReport(reportData);
            setRegionData(newData);
        })
    }

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchUsersByRegion();
    }, [pageId]);

    const fetchAudienceInsights = async () => {
        dispatch(setInsightData([]));
        const { since, until } = datePeriod;
        setPageInsightLoading(true);
        if (pageId) {
            const requestBody = {
                page_id: pageId,
                since: since,
                until: until
            };
            APIService.fetchPageInsights(workSpace._id,
                account.user_id,
                requestBody,
                platform.identifier, (response, error) => {
                    if (error) {
                        setPageInsightLoading(false);
                        return;
                    }
                    setPageInsightLoading(false);
                    const responseData = response["data"][0] ?? [];
                    if (responseData) {
                        setReportData([responseData]);
                        const insightKeys = [
                            "followers",
                            "impressions",
                            "comments",
                            "likes",
                            "shares",
                        ];
                        let totalValues = {};
                        let insightValue = {};
                        const pageInsightValues = insightKeys.map((key) => {
                            if (responseData?.hasOwnProperty(key)) {
                                insightValue[key] = responseData[key];
                                let values = insightValue[key]?.length ? insightValue[key] : [];
                                values = values?.map(obj => obj?.value);
                                let totalValue = values?.reduce((acc, curr) => acc + curr, 0);
                                if (totalValue < 0) {
                                    totalValue = Math.max(...values);
                                }
                                totalValues[key] = totalValue;
                            }
                            else {
                                insightValue[key] = [];
                            };

                            return {
                                title: convertToTitleFormat(key),
                                value: insightValue[key]
                            };
                        });
                        setAudienceValue({ ...totalValues });
                        setAudienceReportData(pageInsightValues);
                        dispatch(setInsightData(pageInsightValues));
                    }
                });
        }
    }
    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchAudienceInsights();
    }, [pageId, datePeriod]);

    const fetchPageProfileStats = () => {
        dispatch(setOverviewData([]));
        APIService.fetchPageProfileOverview(
            workSpace._id,
            account.user_id,
            { page_id: pageId },
            platform.identifier, (response, error) => {
                if (error) {
                    return;
                }
                const responseData = response?.data ?? {};
                let profileFields = ['impressions', 'comments', 'likes', 'shares', 'followers'];
                let profileData = profileFields.map(key => ({ title: convertToTitleFormat(key), value: responseData[key] }));
                setPageProfileStats(profileData);
                dispatch(setOverviewData(profileData));
            });
    }

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchPageProfileStats();
    }, [pageId]);

    return (
        <>
            <PlatformHeader pages={pages} platform={platform} account={account} />
            <Row className="g-3 mb-3">
                <Col xxl={8}>
                    <DateSelection />
                </Col>

                <Col lg={12} xxl={12}>
                    <ExportReportHeader identifier={platform.identifier} data={reportData} />
                </Col>

                {pageProfileStats?.length > 0 &&
                    <Row>
                        <h5 className="mb-3 mt-2">Overview</h5>
                        {pageProfileStats?.map(({ title, value }) => (
                            <Col lg={6} xxl={4} md={6}>
                                <LinkedinOverview title={title} value={value} />
                            </Col>
                        ))}
                    </Row>
                }

                <Col xxl={8} className="mt-4">
                    <Audience
                        values={audienceValue}
                        chartData={audienceData}
                        loading={pageInsightLoading}
                        identifier={platform.identifier}
                        className="mb-3"
                    />
                </Col>

                <Col xs={12} className="mb-3">
                    <ActiveUsers
                        className="mb-3 h-100"
                        loading={followsLoading}
                        followersData={pageStatsData} />
                </Col>

                <Col md={12} xxl={4}>
                    <UsersByCountry
                        title={convertToTitleFormat(fansFollowersTitle)}
                        chartData={regionData}
                        regionLoading={regionLoading}
                        emptyDataMessage={demographicErrorMessage}
                        reportData={audienceReport} />
                </Col>

                <Col lg={12} xxl={8}>
                    <TopPosts
                        tableData={topPosts}
                        loading={postLoading}
                        identifier={platform.identifier} />
                </Col>
            </Row>

            {openExportModal &&
                <ReportExportModal
                    show={openExportModal}
                    onHide={() => dispatch(setOpenExportModal(false))}
                />}
        </>
    )
}

export default LinkedInAnalyticsPage;