import React from "react";
import { Placeholder } from "react-bootstrap";
import Flex from "components/common/Flex";

export default function OrganizationMemberSettings() {
    return (
        <Flex direction={'column'} alignItems="center" justifyContent="center" className="w-100 h-100 gap-2">
            <Placeholder as="div" animation="glow" className="w-75">
                <Placeholder xs={6} />
                <Placeholder className="w-75" />
                <Placeholder style={{ width: '25%' }} />
            </Placeholder>
            <Placeholder as="div" animation="glow" className="w-75">
                <Placeholder xs={6} />
                <Placeholder className="w-75" />
                <Placeholder style={{ width: '25%' }} />
            </Placeholder>
        </Flex>
    );
}
