import FalconCardHeader from "components/common/FalconCardHeader";
import { WorkSpaceContentApprovalWorkFlow } from "../../../../constants";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import Flex from "components/common/Flex";
import Switch from "react-switch";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { cachePosts } from "redux/slices/postslice";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveWorkSpace,
  getPostEmailNotificationChoices,
  setActiveWorkspace,
  setPostEmailNotificationChoices,
} from "redux/slices/workspaceslice";
import { getLoggedInUser } from "redux/slices/user_slice";
import Divider from "components/common/Divider";

const PostsSettings = () => {
  const workSpace = useSelector(getActiveWorkSpace);
  let workSpaceSettings = workSpace.settings ?? {};
  const loggedInUser = useSelector(getLoggedInUser);
  const postEmailNotificationChoices = useSelector(
    getPostEmailNotificationChoices
  );

  const publishedPostChoice = () => {
    let vCopy = { ...workSpace.post_email_notification_choices };
    let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { PUBLISHED: true };
    if (loggedInUserChoices.hasOwnProperty("PUBLISHED")) {
      return loggedInUserChoices["PUBLISHED"];
    }
    return true;
  };

  const failedPostChoice = () => {
    let vCopy = { ...workSpace.post_email_notification_choices };
    let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { FAILED: true };
    if (loggedInUserChoices.hasOwnProperty("FAILED")) {
      return loggedInUserChoices["FAILED"];
    }
    return true;
  };

  const pausedPostChoice = () => {
    let vCopy = { ...workSpace.post_email_notification_choices };
    let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { PAUSED: true };
    if (loggedInUserChoices.hasOwnProperty("PAUSED")) {
      return loggedInUserChoices["PAUSED"];
    }
    return true;
  };

  const approvalWorkflowPostChoice = () => {
    let vCopy = { ...workSpace.post_email_notification_choices };
    let loggedInUserChoices = vCopy[loggedInUser?._id] ?? {
      APPROVAL_WORKFLOW: true,
    };
    if (loggedInUserChoices.hasOwnProperty("APPROVAL_WORKFLOW")) {
      return loggedInUserChoices["APPROVAL_WORKFLOW"];
    }
    return true;
  };

  const [turnOffAllPostsAndCampaigns, setTurnOffAllPostsAndCampaigns] =
    useState(
      workSpaceSettings[
        WorkSpaceContentApprovalWorkFlow.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS
      ] ?? false
    );
  const [
    receiveEmailNotificationsOfFailedPosts,
    setReceiveEmailNotificationsOfFailedPosts,
  ] = useState(failedPostChoice());
  const [
    receiveEmailNotificationsOfPublishedPosts,
    setReceiveEmailNotificationsOfPublishedPosts,
  ] = useState(publishedPostChoice());

  const [
    receiveEmailNotificationsOfPausedPosts,
    setReceiveEmailNotificationsOfPausedPosts,
  ] = useState(pausedPostChoice());
  const [
    receiveEmailNotificationsOfApprovalWorkflowPosts,
    setReceiveEmailNotificationsOfApprovalWorkflowPosts,
  ] = useState(approvalWorkflowPostChoice());

  const [savingChanges, setSavingChanges] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPostEmailNotificationChoices(workSpace.post_email_notification_choices)
    );
  }, []);

  const commitTurnOff = (newValue) => {
    setSavingChanges(true);
    setChangesSaved(false);
    APIService.turnOffAllWorkSpacePostsAndCampaigns(
      workSpace._id,
      newValue ? "yes" : "no",
      (response, error) => {
        if (error) {
          if (error.includes("turn off yet")) {
            toast.info(error, { theme: "colored" });
          } else {
            toast.error(error, { theme: "colored" });
          }
          setSavingChanges(false);
          return;
        }
        let { message } = response;
        toast.success(message, { theme: "colored" });
        dispatch(cachePosts([]));
        let workSpaceSetings = workSpace.settings ?? {};
        workSpaceSetings[
          WorkSpaceContentApprovalWorkFlow.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS
        ] = newValue;
        dispatch(
          setActiveWorkspace({
            ...workSpace,
            settings: workSpaceSetings,
          })
        );
        setSavingChanges(false);
        setChangesSaved(true);
      }
    );
  };

  const applyToAllWorkspaceSettingsHandler = () => {
    setSavingChanges(true);
    setChangesSaved(false);
    let requestBody = {
      PUBLISHED: receiveEmailNotificationsOfPublishedPosts,
      FAILED: receiveEmailNotificationsOfFailedPosts,
      PAUSED: receiveEmailNotificationsOfPausedPosts,
      APPROVAL_WORKFLOW: receiveEmailNotificationsOfApprovalWorkflowPosts,
    };
    APIService.applyToAllWorkspaces(
      workSpace?._id,
      {
        turnOff: turnOffAllPostsAndCampaigns ? "yes" : "no",
        postSettings: requestBody,
      },
      "posts_settings",
      async (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          setSavingChanges(false);
          return;
        }
        const { message, data } = response;
        APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
          if (error) {
            setSavingChanges(false);
            return;
          }
          if (response) {
            let responseData = response["data"];
            let workSpace = responseData["workspace"];
            workSpace["role"] = responseData["role"];
            workSpace["value"] = workSpace["name"];
            workSpace["title"] = responseData["title"];
            workSpace["label"] = workSpace["name"];
            workSpace["workspace_owner"] = responseData["workspace_owner"];
            dispatch(setActiveWorkspace({ ...workSpace }));
            setSavingChanges(false);
            setChangesSaved(true);
            toast.success(message, { theme: "colored" });
          }
        });
      }
    );
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={<h5>Posts settings</h5>}
        endEl={
          <>
            {savingChanges && (
              <Flex className={"gap-2"}>
                <Spinner animation="border" size={"sm"} variant={"primary"} />
                <p
                  style={{
                    fontSize: 12,
                    color: "#B0BEC5",
                  }}
                >
                  Saving changes...
                </p>
              </Flex>
            )}
            {changesSaved && (
              <p
                style={{
                  fontSize: 12,
                  color: "#9CCC65",
                }}
              >
                Changes Saved
              </p>
            )}
          </>
        }
      />
      <Card.Body className="bg-light">
        <Flex direction={"column"}>
          <Flex alignItems={"center"}>
            <Form.Check
              type="switch"
              id="allow_weekend"
              checked={turnOffAllPostsAndCampaigns}
              disabled={savingChanges}
              label="Turn off all scheduled posts and campaigns"
              onChange={() => {
                let newValue = !turnOffAllPostsAndCampaigns;
                setTurnOffAllPostsAndCampaigns(newValue);
                commitTurnOff(newValue);
              }}
            />
          </Flex>
          <Form.Group>
            <Form.Check
              type="switch"
              id="posts_email_notifications"
              checked={receiveEmailNotificationsOfPublishedPosts}
              label="Receive published post notification email"
              disabled={savingChanges}
              onChange={(e) => {
                let newValue = e.target.checked;
                setReceiveEmailNotificationsOfPublishedPosts(newValue);
                setSavingChanges(true);
                setChangesSaved(false);
                APIService.updatePostEmailNotificationSettings(
                  workSpace._id,
                  { PUBLISHED: newValue },
                  (response, error) => {
                    if (error) {
                      setReceiveEmailNotificationsOfPublishedPosts(!newValue);
                      toast.error(error, { theme: "colored" });
                      setSavingChanges(false);
                      return;
                    }
                    let { message, data } = response;
                    toast.success(message, { theme: "colored" });
                    dispatch(
                      setPostEmailNotificationChoices(
                        data.post_email_notification_choices
                      )
                    );
                    dispatch(
                      setActiveWorkspace({
                        ...workSpace,
                        post_email_notification_choices:
                          data.post_email_notification_choices,
                      })
                    );
                    setSavingChanges(false);
                    setChangesSaved(true);
                  }
                );
              }}
            />
            <Form.Check
              type="switch"
              id="posts_email_notifications"
              checked={receiveEmailNotificationsOfPausedPosts}
              label="Receive paused post notification email"
              disabled={savingChanges}
              onChange={(e) => {
                let newValue = e.target.checked;
                setReceiveEmailNotificationsOfPausedPosts(newValue);
                setSavingChanges(true);
                setChangesSaved(false);
                APIService.updatePostEmailNotificationSettings(
                  workSpace._id,
                  { PAUSED: newValue },
                  (response, error) => {
                    if (error) {
                      setReceiveEmailNotificationsOfPausedPosts(!newValue);
                      toast.error(error, { theme: "colored" });
                      setSavingChanges(false);
                      return;
                    }
                    let { message, data } = response;
                    toast.success(message, { theme: "colored" });
                    dispatch(
                      setPostEmailNotificationChoices(
                        data.post_email_notification_choices
                      )
                    );
                    dispatch(
                      setActiveWorkspace({
                        ...workSpace,
                        post_email_notification_choices:
                          data.post_email_notification_choices,
                      })
                    );
                    setSavingChanges(false);
                    setChangesSaved(true);
                  }
                );
              }}
            />
            <Form.Check
              type="switch"
              id="posts_email_notifications"
              checked={receiveEmailNotificationsOfFailedPosts}
              disabled={savingChanges}
              label="Receive failed post notification email"
              onChange={(e) => {
                let newValue = e.target.checked;
                setReceiveEmailNotificationsOfFailedPosts(newValue);
                setSavingChanges(true);
                setChangesSaved(false);
                APIService.updatePostEmailNotificationSettings(
                  workSpace._id,
                  { FAILED: newValue },
                  (response, error) => {
                    if (error) {
                      setReceiveEmailNotificationsOfFailedPosts(!newValue);
                      toast.error(error, { theme: "colored" });
                      setSavingChanges(false);
                      return;
                    }
                    let { message, data } = response;
                    toast.success(message, { theme: "colored" });
                    dispatch(
                      setPostEmailNotificationChoices(
                        data.post_email_notification_choices
                      )
                    );
                    dispatch(
                      setActiveWorkspace({
                        ...workSpace,
                        post_email_notification_choices:
                          data.post_email_notification_choices,
                      })
                    );
                    setSavingChanges(false);
                    setChangesSaved(true);
                  }
                );
              }}
            />

            <Form.Check
              type="switch"
              id="posts_email_notifications"
              checked={receiveEmailNotificationsOfApprovalWorkflowPosts}
              label="Receive approval workflow notification emails"
              disabled={savingChanges}
              onChange={(e) => {
                let newValue = e.target.checked;
                setReceiveEmailNotificationsOfApprovalWorkflowPosts(newValue);
                setSavingChanges(true);
                setChangesSaved(false);
                APIService.updatePostEmailNotificationSettings(
                  workSpace._id,
                  { APPROVAL_WORKFLOW: newValue },
                  (response, error) => {
                    if (error) {
                      setReceiveEmailNotificationsOfApprovalWorkflowPosts(
                        !newValue
                      );
                      toast.error(error, { theme: "colored" });
                      setSavingChanges(false);
                      return;
                    }
                    let { message, data } = response;
                    toast.success(message, { theme: "colored" });
                    dispatch(
                      setPostEmailNotificationChoices(
                        data.post_email_notification_choices
                      )
                    );
                    dispatch(
                      setActiveWorkspace({
                        ...workSpace,
                        post_email_notification_choices:
                          data.post_email_notification_choices,
                      })
                    );
                    setSavingChanges(false);
                    setChangesSaved(true);
                  }
                );
              }}
            />
          </Form.Group>
          <Divider />
          <Flex
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Apply this to all workspaces</p>
            <Button
              onClick={applyToAllWorkspaceSettingsHandler}
              disabled={savingChanges}
            >
              {"Apply"}
            </Button>
          </Flex>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default PostsSettings;
