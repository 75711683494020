import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { PlatformIdentifier } from "../../../../constants";
import RSSConnectedAccount from "../connected_accounts/rss_connected_accounts";
import { NoteContainer } from "../styles";
import { toast } from "react-toastify";

const RSSConnectionPage = ({ props }) => {
  const {
    processing,
    connectAccount,
    connectedAccounts,
    NewAccountConnectionView,
  } = props;

  const workSpace = useSelector(getActiveWorkSpace);
  const [openRSSFeedInput, setOpenRSSFeedInput] = useState(false);
  const [feedName, setFeedName] = useState("");
  const [includeKeywords, setIncludeKeywords] = useState([]);
  const [excludeKeywords, setExcludeKeywords] = useState([]);
  const [feedUrl, setFeedUrl] = useState("");

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleAddKeyword = (keyword, type) => {
    if (type === "include") {
      setIncludeKeywords([...includeKeywords, keyword]);
    }
    if (type === "exclude") {
      setExcludeKeywords([...excludeKeywords, keyword]);
    }
  };

  const handleDeleteKeyword = (i, type) => {
    let newKeyword;
    if (type === "include") {
      newKeyword = includeKeywords.filter((keyword, index) => index !== i);
      setIncludeKeywords(newKeyword);
    }
    if (type === "exclude") {
      newKeyword = excludeKeywords.filter((keyword, index) => index !== i);
      setExcludeKeywords(newKeyword);
    }
  };

  // process account connection 
  const processAccountConnection = () => { 
    if(!feedName && !feedUrl) { 
      return; 
    }
    const invalid_names = ['https://', 'http://']; 
    for(let i = 0; i < invalid_names.length; i++) { 
        let feedNameInvalid = feedName.startsWith(invalid_names[i]); 
        if(feedNameInvalid) { 
          toast.error("Links can't be used as feed name", { theme: 'colored '});
          return; 
        }
    }
    connectAccount({
      [PlatformIdentifier.RSS_FEED]: {
        displayName: feedName,
        url: feedUrl,
        keyword: {
          includes: includeKeywords,
          excludes: excludeKeywords,
        },
      },
    });
  }

  const howToConnectRSSFeedCard = (
    <Card
      canHover={false}
      style={{
        width: "100%",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <NoteContainer>
        Please Note:
        <ListGroup as="ul" style={{ border: "none" }}>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />
            Connect multiple RSS feeds.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />
            An example feed format is like:{" "}
            <a
              href="https://techcrunch.com/feed"
              target="_blank"
              rel="noreferrer"
            >
              https://techcrunch.com/feed
            </a>
            .
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
            Proceed to the RSS feed page to schedule and manage your feeds.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />
            Need more help? Check out the guide page:{" "}
            <a
              href="https://postly.ai/rss-feed"
              target="_blank"
              rel="noreferrer"
            >
              https://postly.ai/rss-feed
            </a>
          </ListGroup.Item>
        </ListGroup>
      </NoteContainer>
    </Card>
  );

  const connectView = (
    <Card>
      <Card.Body className="bg">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Button
              variant={"primary"}
              disabled={processing}
              isWorking={processing}
              onClick={() => setOpenRSSFeedInput(!openRSSFeedInput)}
              style={{
                color: "white",
                minWidth: "100%",
                display: "flex",
                fontSize: 16,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {processing ? "Please wait..." : "Connect"}
            </Button>
          </div>
          {howToConnectRSSFeedCard}
          <Card
            canHover={false}
            style={{
              width: "100%",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          ></Card>
        </div>
      </Card.Body>
    </Card>
  );

  const updateFieldSection = (
    <>
      <Card>
        <Card.Body className="bg-light">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 20,
                  fontWeight: 900,
                  textAlign: "left",
                }}
              >
                Add RSS Feed
              </span>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12}>
                  <Form.Label>{"Enter Feed Name"}</Form.Label>
                  <Form.Control
                    type={"text"}
                    value={feedName}
                    placeholder={"Feed name"}
                    onChange={(e) => {
                      setFeedName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={12}>
                  <Form.Label>{"Enter Feed URL"}</Form.Label>
                  <Form.Control
                    type={"text"}
                    value={feedUrl}
                    placeholder={"Feed URL"}
                    onChange={(e) => {
                      setFeedUrl(e.target.value);
                    }}
                  />
                </Form.Group>
              </Row>
              <Button
                variant={"primary"}
                disabled={processing}
                isWorking={processing}
                onClick={processAccountConnection}
                style={{
                  color: "white",
                  minWidth: "100%",
                  display: "flex",
                  fontSize: 16,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {processing ? "Adding feed..." : "Add"}
              </Button>
            </div>
            {howToConnectRSSFeedCard}
          </div>
        </Card.Body>
      </Card>
    </>
  );

  const atLeastOneAccountIsConnectedView = () => {
    return (
      <Flex direction={"column"} justifyContent={"center"}>
        <Flex
          alignItems={"center"}
          className={"gap-3 pt-2"}
          justifyContent={"center"}
          wrap={"wrap"}
        >
          {connectedAccounts?.length > 0 &&
            connectedAccounts?.map((account, index) => {
              return (
                <div key={account._id} id={account._id}>
                  <RSSConnectedAccount
                    workSpace={workSpace}
                    account={account}
                  />
                </div>
              );
            })}
          <NewAccountConnectionView setOpenRSSFeedInput={setOpenRSSFeedInput} />
          {howToConnectRSSFeedCard}
        </Flex>
      </Flex>
    );
  };

  if (openRSSFeedInput) {
    return updateFieldSection;
  }

  if (connectedAccounts?.length > 0) {
    return atLeastOneAccountIsConnectedView();
  }
  return connectView;
};

export default RSSConnectionPage;
